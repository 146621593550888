import { useState, useEffect, useCallback, useContext } from "react";
import { useParams, Navigate } from "react-router-dom";
import axios from "axios";
import { ToastContext } from "../../Contexts/toastContext";
import ChangePassword from "../../Components/ChangePassword";
import "./style.scss";

function ResetPassword() {
  const params = useParams();
  const { userId, resetToken } = params;
  const [updated, setUpdated] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const { addToast } = useContext(ToastContext);

  const handleSubmit = useCallback(
    (password) => {
      setIsSending(true);
      axios
        .put(`/api/v1/auth/reset-password/${userId}/${resetToken}`, { password })
        .then((resp) => {
          console.log(resp);
          setUpdated(true);
          addToast({ type: "success", message: "Password changed" });
        })
        .catch((err) => {
          console.log(err);
          setUpdated(false);
          addToast({ type: "error", message: "Failed to change password" });
          setIsSending(false);
        });
    },
    [userId, resetToken, addToast]
  );

  useEffect(() => {
    axios.get(`/api/v1/auth/reset-password/${userId}/${resetToken}`).catch((err) => setIsInvalid(true));
  }, [userId, resetToken]);

  if (isInvalid) return <Navigate to="/" replace />;

  if (updated) return <Navigate to="/login" replace />;
  return (
    <div className="change-password">
      <ChangePassword onSubmit={handleSubmit} disabled={isSending} />
    </div>
  );
}

export default ResetPassword;
