import { useCallback, useRef } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

function useParams() {
  const { state } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const stateRef = useRef(state);

  const setParams = useCallback(
    (obj) => {
      setSearchParams(obj, { replace: true, state: stateRef.current });
    },
    [setSearchParams]
  );

  const updateParams = useCallback(
    (obj) => {
      for (let key in obj) {
        if (obj[key] === undefined) {
          searchParams.delete(key);
          continue;
        }
        if (Array.isArray(obj[key])) {
          searchParams.delete(key);
          for (let item of obj[key]) {
            searchParams.append(key, item);
          }
        } else {
          searchParams.set(key, obj[key]);
        }
      }
      setSearchParams(searchParams, { replace: true, state: stateRef.current });
    },
    [searchParams, setSearchParams]
  );

  let initialState = {};
  for (let [key, value] of searchParams.entries()) {
    if (initialState[key]) {
      if (!Array.isArray(initialState[key])) initialState[key] = [initialState[key]];
      initialState[key].push(value);
      continue;
    }
    initialState[key] = value;
  }

  return { searchParams: initialState, setParams, updateParams };
}

export default useParams;
