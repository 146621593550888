import { useContext } from "react";
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import { ToastContext } from "../../Contexts/toastContext";
import "./style.scss";

function CheckoutForm({ onCancel, isBusy, setIsBusy }) {
  const stripe = useStripe();
  const elements = useElements();
  const { addToast } = useContext(ToastContext);

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }
    setIsBusy(true);
    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: window.location.href,
      },
    });
    setIsBusy(false);
    if (result.error) {
      console.log(result.error.message);
      addToast({ type: "error", message: "Failed to purchase subscription" });
    }
  };

  return (
    <div className="paymentForm">
      <PaymentElement />
      <div className="payment-submission-buttons">
        <button className="payment-cancel-button" onClick={onCancel} disabled={isBusy}>
          Cancel
        </button>
        <button className="payment-submit-button" onClick={handleSubmit} disabled={!stripe || isBusy}>
          Submit
        </button>
      </div>
    </div>
  );
}

export default CheckoutForm;
