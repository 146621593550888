import { useEffect, useRef, useState, useCallback } from "react";
import "./style.scss";

const legendPadding = {
  left: 12,
  top: 12,
  right: 12,
  bottom: 12,
};
const fontSize = 12;
const shapeOffset = (fontSize / 12) * 17;

function Legend({ className, data, initX, initY, initQuadrant, margin, svg, svgWidth, svgHeight, categories, onToggle }) {
  const legend = useRef(null);
  const [offset, setOffset] = useState({ x: 0, y: 0, isMoving: false });
  const [{ x, y, quadrant }, setPosition] = useState({
    x: initX || 15,
    y: initY || 15,
    quadrant: initQuadrant || 1,
  });
  const [{ width, height }, setDimensions] = useState({
    width: 0,
    height: categories.length * shapeOffset + legendPadding.top + legendPadding.bottom,
  });

  const handleDown = useCallback(
    (e) => {
      let oX = quadrant % 2 ? svgWidth - x - width : x;
      let oY = Math.floor(quadrant / 2) ? svgHeight - y - height : y;
      const offsetX = e.nativeEvent.offsetX - oX;
      const offsetY = e.nativeEvent.offsetY - oY;

      setOffset({ x: offsetX, y: offsetY, isMoving: true });
    },
    [x, y, quadrant, svgWidth, svgHeight, width, height]
  );

  useEffect(() => {
    const svgRef = svg.current;

    function handleRelease() {
      setOffset((prev) => ({ ...prev, isMoving: false }));
    }

    svgRef.addEventListener("mouseup", handleRelease);
    return () => svgRef.removeEventListener("mouseup", handleRelease);
  }, [svg]);

  useEffect(() => {
    const svgRef = svg.current;

    function handleMove(e) {
      if (!offset.isMoving) return;
      let x = e.offsetX - offset.x;
      let y = e.offsetY - offset.y;

      if (x + width > svgWidth) {
        x = svgWidth - width;
      } else if (x < 0) {
        x = 0;
      }

      if (y + height > svgHeight) {
        y = svgHeight - height;
      } else if (y < 0) {
        y = 0;
      }

      let quadrant = (x > svgWidth / 2 ? 1 : 0) + (y > svgHeight / 2 ? 2 : 0);

      x = quadrant % 2 ? svgWidth - x - width : x;
      y = Math.floor(quadrant / 2) ? svgHeight - y - height : y;

      setPosition({ x, y, quadrant });
    }

    svgRef.addEventListener("mousemove", handleMove);
    return () => svgRef.removeEventListener("mousemove", handleMove);
  }, [svg, offset.x, offset.y, offset.isMoving, svgWidth, svgHeight, width, height]);

  useEffect(() => {
    let width = 0;
    [...legend.current.querySelectorAll(".key-label")].forEach((elem) => {
      let tempWidth = elem.getBoundingClientRect().width;
      if (tempWidth > width) {
        width = tempWidth;
      }
    });
    setDimensions({
      width: width + shapeOffset + legendPadding.left + legendPadding.right,
      height: categories.length * shapeOffset + legendPadding.top + legendPadding.bottom,
    });
  }, [categories]);

  return (
    <g
      className="legend"
      key={`${className}-legend`}
      ref={legend}
      transform={`translate(${(quadrant % 2 ? svgWidth - x - width : x) - margin.left}, ${
        (Math.floor(quadrant / 2) ? svgHeight - y - height : y) - margin.top
      })`}
    >
      <rect className="legend-background" fill="#ffffffaa" stroke="#000000" width={width} height={height + fontSize / 2} onMouseDown={handleDown} />
      {categories.map((category, i) => (
        <g
          className="key"
          key={`${className}-${category.label}-key`}
          transform={`translate(${legendPadding.left}, ${legendPadding.top + i * ((fontSize / 2) * 3)})`}
          onClick={(e) => onToggle({ ...category, isVisible: !category.isVisible })}
        >
          <rect
            width={width ? width - legendPadding.left - legendPadding.right + 8 : 0}
            height={(fontSize / 3) * 4}
            transform={`translate(-4, ${-fontSize / 3})`}
            fill="#00000000"
          />
          {typeof category.shape !== "string" ? (
            category.shape
          ) : (
            <use
              className="key-color"
              href={`#${category.shape || "square"}`}
              fill={category.isVisible ? category.color : "#aaaaaa"}
              transform={"translate(3, 5)"}
            />
          )}
          <text
            className="key-label"
            x={shapeOffset}
            dy={fontSize / 2}
            dominantBaseline="middle"
            fontSize={fontSize}
            fill={category.isVisible ? "#000000" : "#aaaaaa"}
          >
            {category.label}
          </text>
        </g>
      ))}
    </g>
  );
}

export default Legend;
