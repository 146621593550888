import { useState, useEffect, useContext, useCallback } from "react";
import axios from "axios";
import { AuthContext } from "../../../../Contexts/authContext";
import InitialCustomer from "../../../../Components/InitialCustomer";
import ReturningCustomer from "../../../../Components/ReturningCustomer";
import "./style.scss";

function PaymentInfo() {
  const { user } = useContext(AuthContext);
  const [customer, setCustomer] = useState();
  const [products, setProducts] = useState([]);

  const getCustomer = useCallback(() => {
    const CancelToken = axios.CancelToken;
    let cancel = {};

    axios
      .get("/api/v1/admin/customer", {
        cancelToken: new CancelToken((c) => (cancel.func = c)),
      })
      .then((response) => {
        console.log(response);
        if (response.data) {
          console.log("Has customer");
          return response;
        } else {
          console.log("Does not have customer");
          return axios.post(
            "/api/v1/admin/customer",
            {
              name: user.companyName,
              description: `Customer for ${user.companyName} with company id of ${user.companyId} created by: ${user.firstName} ${user.lastName}`,
              metadata: {
                companyId: user.companyId,
                companyName: user.companyName,
                createdBy: `${user.firstName} ${user.lastName}`,
              },
            },
            {
              cancelToken: new CancelToken((c) => (cancel.func = c)),
            }
          );
        }
      })
      .then((response) => {
        setCustomer(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
    return cancel;
  }, [user.companyName, user.companyId, user.firstName, user.lastName]);

  useEffect(() => {
    if (customer) return;
    let cancel = getCustomer();
    return cancel.func;
  }, [getCustomer, customer, user.companyName, user.companyId, user.firstName, user.lastName]);

  useEffect(() => {
    axios
      .get("/api/v1/admin/products")
      .then((response) => {
        setProducts(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  if (!customer) return null;

  if (!customer.subscriptions.filter((subscription) => subscription.status === "active").length)
    return (
      <div className="payment-info-wrapper">
        <InitialCustomer customer={customer} products={products} />
      </div>
    );

  return (
    <div className="payment-info-wrapper">
      <ReturningCustomer customer={customer} products={products} getCustomer={getCustomer} />
    </div>
  );
}

export default PaymentInfo;
