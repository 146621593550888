import { useState, useCallback } from "react";
import axios from "axios";
import { Elements } from "@stripe/react-stripe-js";
import stripePromise from "../../Utilities/stripe";
import PaymentMethodList from "../PaymentMethodList";
import AddPaymentMethod from "../AddPaymentMethod";
import CurrentSubscription from "../CurrentSubscription";
import "./style.scss";

function ReturningCustomer({ customer, products, getCustomer }) {
  const [options, setOptions] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [isBusy, setIsBusy] = useState(false);

  const handleAdd = useCallback(() => {
    setIsOpen(true);
    if (options.clientSecret) return;
    axios
      .post("/api/v1/admin/setup-intent")
      .then((res) => setOptions({ clientSecret: res.data }))
      .catch((err) => console.log(err));
  }, [options]);

  return (
    <>
      <div className="returning-customer-page">
        <PaymentMethodList customer={customer} handleAdd={handleAdd} getCustomer={getCustomer} isBusy={isBusy} setIsBusy={setIsBusy} />
        <CurrentSubscription customer={customer} products={products} getCustomer={getCustomer} isBusy={isBusy} setIsBusy={setIsBusy} />
      </div>
      {options.clientSecret && isOpen ? (
        <div className="add-payment-method-wrapper">
          <button className="add-payment-method-close" onClick={() => setIsOpen(false)}></button>
          <Elements stripe={stripePromise} options={options}>
            <AddPaymentMethod getCustomer={getCustomer} />
          </Elements>
        </div>
      ) : null}
    </>
  );
}

export default ReturningCustomer;
