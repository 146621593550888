import { useState, useEffect, useCallback } from "react";
import ManageTagsModal from "../../../../Components/ManageTagsModal";
import EmployeeTagCard from "../../../../Components/EmployeeTagCard";
import axios from "axios";
import "./style.scss";

function EmployeeTags() {
  const [employeeData, setEmployeeData] = useState({});
  const [tagsData, setTagsData] = useState([]);

  const handleSetData = useCallback(() => {
    axios
      .get(`/api/v1/admin/filters/employee-tags`)
      .then((resp) => {
        if (resp.data) {
          setEmployeeData(
            Object.values(resp.data.resEmployees).reduce((acc, cur) => {
              if (cur.tags) {
                acc[cur.id] = { id: cur.id, name: cur.name, bullhorn_id: cur.bullhorn_id, tags: cur.tags };
                return acc;
              } else {
                acc[cur.id] = { id: cur.id, name: cur.name, bullhorn_id: cur.bullhorn_id, tags: [] };
                return acc;
              }
            }, {})
          );
          setTagsData(resp.data.resTags);
        }
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }, []);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    let cancel;

    axios
      .get(`/api/v1/admin/filters/employee-tags`, {
        cancelToken: new CancelToken((c) => (cancel = c)),
      })
      .then((resp) => {
        if (resp.data) {
          setEmployeeData(
            Object.values(resp.data.resEmployees).reduce((acc, cur) => {
              if (cur.tags) {
                acc[cur.id] = { id: cur.id, name: cur.name, bullhorn_id: cur.bullhorn_id, tags: cur.tags };
                return acc;
              } else {
                acc[cur.id] = { id: cur.id, name: cur.name, bullhorn_id: cur.bullhorn_id, tags: [] };
                return acc;
              }
            }, {})
          );
          setTagsData(resp.data.resTags);
        }
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });

    return () => cancel();
  }, []);

  // const resultsNum = [10, 25, 50, 100, 250, 500];
  const [tagsModalOpen, setTagsModalOpen] = useState(false);
  const [employeeSelected, setEmployeeSelected] = useState();
  const [employeeSearch, setEmployeeSearch] = useState("");
  const [newTag, setNewTag] = useState("");

  const handleEmployeeSearch = (e) => {
    setEmployeeSearch(e.target.value);
  };

  const handleChange = (e) => {
    setNewTag(e.target.value);
  };

  function assignTag(userId, tagId) {
    console.log(userId + ", " + tagId);
    let assignment = { user_id: userId, tag_id: tagId };
    axios
      .post("/api/v1/admin/filters/assign-tag", assignment)
      .then((res) => {
        handleSetData();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function removeTag(userId, tagName) {
    let tagId;
    for (let i = 0; i < tagsData.length; i++) {
      if (tagsData[i].tag_name === tagName) {
        tagId = tagsData[i].id;
      }
    }
    let removal = { userId, tagId };
    axios
      .post("/api/v1/admin/filters/remove-tag", removal)
      .then((res) => {
        handleSetData();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div className="employee-tags-wrapper">
      <h1 className="page-heading filter-page-heading">Categorize your Employees with identifying tags.</h1>
      <span className="filter-page-description">Apply tags to filter Employees on fields supporting Employee-based filtering.</span>
      <button
        className="manage-tags-button"
        onClick={() => {
          setTagsModalOpen(true);
        }}
      >
        Manage Tags
      </button>
      {tagsModalOpen ? <ManageTagsModal closeModal={() => setTagsModalOpen(false)} tagsData={tagsData} handleSetData={handleSetData} /> : ""}
      <h3 className="filter-page-subheading">Active Employees</h3>
      <input className="employee-search-input" type="search" value={employeeSearch} onChange={handleEmployeeSearch}></input>
      {/* <div className="results-per-page">
        <span className="results-per-page-label">Results per page</span>
        <select
          className="results-per-page-count"
          value={numOfResults}
          onChange={(e) => {
            setNumOfResults(e.target.value);
            console.log(e.target.value);
          }}
        >
          {resultsNum.map((item) => {
            return (
              <option key={item} value={item}>
                {item}
              </option>
            );
          })}
        </select>
      </div> */}
      <table className="employee-tag-table">
        <thead>
          <tr className="employee-tag-table-header">
            <th className="employee-tag-table-heading">Name</th>
            <th className="employee-tag-table-heading">Ext ID</th>
            <th className="employee-tag-table-heading-tags">Active Employee Tags</th>
          </tr>
        </thead>
        <tbody>
          {employeeData
            ? Object.values(employeeData)
                .filter((employee) => {
                  if (
                    employee.name.toLowerCase().includes(employeeSearch.toLowerCase()) ||
                    employee.bullhorn_id.toString().toLowerCase().includes(employeeSearch.toLowerCase())
                  ) {
                    return employee;
                  }
                  if (employee.tags[0] !== null) {
                    for (let i = 0; i < employee.tags.length; i++) {
                      if (employee.tags[i].toLowerCase().includes(employeeSearch.toLowerCase())) {
                        return employee;
                      }
                    }
                  }
                  return null;
                })
                .map((item) => {
                  return (
                    <EmployeeTagCard
                      key={item.id}
                      id={item.id}
                      name={item.name}
                      extId={item.bullhorn_id}
                      assignedTags={item.tags}
                      employeeSelected={employeeSelected}
                      setEmployeeSelected={setEmployeeSelected}
                      handleChange={handleChange}
                      newTag={newTag}
                      tagsData={tagsData}
                      assignTag={assignTag}
                      removeTag={removeTag}
                    />
                  );
                })
            : null}
        </tbody>
      </table>
    </div>
  );
}

export default EmployeeTags;
