import { useState, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useAuth from "../../Hooks/useAuth";
import { isValidPassword, isValidEmail } from "../../Utilities/validateInput";
import "./style.scss";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({ email: "", password: "" });
  const [isSending, setIsSending] = useState(false);
  const { login } = useAuth();

  let navigate = useNavigate();
  let location = useLocation();
  let from = location.state?.from?.pathname || "/";

  const handleLogin = useCallback(
    ({ email, password }) => {
      let shouldExit = false;
      let missing = {};
      if (!email || !isValidEmail(email)) {
        shouldExit = true;
        missing.email = "true";
      }
      if (!password || !isValidPassword(password)) {
        shouldExit = true;
        missing.password = "true";
      }
      if (shouldExit) {
        setErrors((prevErrors) => {
          return { ...missing };
        });
        return;
      }
      setIsSending(true);
      login(
        { email, password },
        () => {
          navigate(from, { replace: true });
        },
        () => setIsSending(false)
      );
    },
    [login, from, navigate]
  );

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === "Enter") handleLogin({ email, password });
    },
    [handleLogin, email, password]
  );

  return (
    <div className="login-box" onKeyDown={handleKeyDown}>
      <label htmlFor="email-input">Email:</label>
      <input
        id="email-input"
        className={errors.email ? "error" : ""}
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        disabled={isSending}
      />
      <label htmlFor="password-input">Password:</label>
      <input
        id="password-input"
        className={errors.password ? "error" : ""}
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        disabled={isSending}
      />
      <button className="submit-button" onClick={() => handleLogin({ email, password })} disabled={isSending}>
        Login
      </button>
    </div>
  );
}

export default Login;
