import { createContext, useState, useCallback, useEffect, useContext } from "react";
import axios from "axios";
import { ToastContext } from "./toastContext";

const AuthContext = createContext();

let isRequestingNewToken = false;
let requestQueue = [];
function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [hasChecked, setHasChecked] = useState(false);
  const [userDropdownOpen, setUserDropdownOpen] = useState(false);
  const { addToast } = useContext(ToastContext);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    let cancel;

    let interceptor = axios.interceptors.response.use(
      (response) => response,
      async (err) => {
        if (axios.isCancel(err)) return Promise.reject(err);
        if (err.response.status === 401 && err.response.config.url.includes("refresh")) {
          setUser(null);
          return Promise.resolve();
        }
        if (
          err.response.status !== 401 ||
          err.response.data !== "Unauthorized" ||
          (err.response.config.url.includes("auth") &&
            !err.response.config.url.includes("get-user") &&
            !err.response.config.url.includes("change-company") &&
            !err.response.config.url.includes("companies"))
        ) {
          return Promise.reject(err);
        }

        let resolve, reject;
        let promise = new Promise((res, rej) => {
          resolve = res;
          reject = rej;
        });
        requestQueue.push({ resolve, reject, config: err.response.config });
        //If the client is requesting a new token queue up request for when it is finished;
        if (isRequestingNewToken) return promise;

        isRequestingNewToken = true;
        let refreshResp = await axios.post("/api/v1/auth/refresh");
        isRequestingNewToken = false;
        //Return all of the responses once the refresh is finished
        for (let request of requestQueue) {
          if (refreshResp.status !== 200) {
            request.reject(err);
            continue;
          }
          let resp = await axios(request.config);
          request.resolve(resp);
        }

        if (refreshResp.status !== 200) setUser(null);

        requestQueue = [];
        return promise;
      }
    );

    axios
      .get("/api/v1/auth/get-user", {
        cancelToken: new CancelToken((c) => (cancel = c)),
      })
      .then((res) => {
        setUser(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setHasChecked(true);
      });

    return () => {
      axios.interceptors.response.eject(interceptor);
      cancel();
    };
  }, []);

  useEffect(() => {
    if (!user || user.role !== "dev") return;
    const CancelToken = axios.CancelToken;
    let cancel;

    axios
      .get("/api/v1/auth/companies", {
        cancelToken: new CancelToken((c) => (cancel = c)),
      })
      .then((resp) => setCompanies(resp.data))
      .catch((err) => console.log(err));

    return cancel;
  }, [user]);

  //This is for testing purposes.
  //To be replaced with a request.
  const numOfNotifications = 0;

  const login = useCallback(
    ({ email, password }, successCB, failureCB) => {
      const CancelToken = axios.CancelToken;
      let cancel;

      axios
        .post(
          "/api/v1/auth/login",
          { email, password },
          {
            cancelToken: new CancelToken((c) => (cancel = c)),
          }
        )
        .then((res) => {
          setUser(res.data);
          if (successCB) successCB();
        })
        .catch((err) => {
          console.log(err);
          addToast({ type: "error", message: "Failed to login" });
          if (failureCB) failureCB();
        });

      return () => cancel();
    },
    [addToast]
  );

  const logout = useCallback((successCB, failureCB) => {
    const CancelToken = axios.CancelToken;
    let cancel;

    axios
      .post(
        "/api/v1/auth/logout",
        {},
        {
          cancelToken: new CancelToken((c) => (cancel = c)),
        }
      )
      .then((res) => {
        setUser(null);
        if (successCB) successCB();
      })
      .catch((err) => {
        console.log(err);
        if (failureCB) failureCB();
      });

    return () => cancel();
  }, []);

  const register = useCallback(
    (params, successCB, failureCB) => {
      const CancelToken = axios.CancelToken;
      let cancel;

      axios
        .post("/api/v1/auth/register", params, {
          cancelToken: new CancelToken((c) => (cancel = c)),
        })
        .then((res) => {
          addToast({ type: "success", message: "User Registered" });
          setUser(null);
          if (successCB) successCB();
        })
        .catch((err) => {
          console.log(err);
          addToast({ type: "error", message: err.response.data });
          if (failureCB) failureCB();
        });

      return () => cancel();
    },
    [addToast]
  );

  const changeCompany = useCallback(
    (params, successCB, failureCB) => {
      const CancelToken = axios.CancelToken;
      let cancel;

      axios
        .put("/api/v1/auth/change-company", params, {
          cancelToken: new CancelToken((c) => (cancel = c)),
        })
        .then((res) => {
          console.log(res);
          setUser(res.data);
          addToast({ type: "success", message: "Company changed" });
          if (successCB) successCB();
        })
        .catch((err) => {
          console.log(err);
          addToast({ type: "error", message: err.response.data });
          if (failureCB) failureCB();
        });

      return cancel;
    },
    [addToast]
  );

  const openUserDropdown = useCallback(() => {
    setUserDropdownOpen(true);
  }, []);

  const closeUserDropdown = useCallback(() => {
    setUserDropdownOpen(false);
  }, []);

  return (
    <AuthContext.Provider
      value={{ user, companies, numOfNotifications, hasChecked, login, logout, register, changeCompany, userDropdownOpen, openUserDropdown, closeUserDropdown }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
