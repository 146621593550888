function symbols() {
  return (
    <defs>
      <g className="symbols">
        <ellipse id="ellipse-left" rx={5} ry={2.5} transform="rotate(-45)" />
        <ellipse id="ellipse-right" rx={5} ry={2.5} transform="rotate(45)" />
        <rect id="diamond" x={-4} y={-4} width={8} height={8} transform="rotate(45)" />
        <rect id="square" x={-4} y={-4} width={8} height={8} />
        <circle id="circle" r={4} />
        <polygon
          id="triangle-down"
          points={`-5, -${Math.sqrt(10 ** 2 - 5 ** 2) / 2} 5, -${Math.sqrt(10 ** 2 - 5 ** 2) / 2} 0, ${Math.sqrt(10 ** 2 - 5 ** 2) / 2}`}
        />
        <polygon
          id="triangle-up"
          points={`-5, -${Math.sqrt(10 ** 2 - 5 ** 2) / 2} 5, -${Math.sqrt(10 ** 2 - 5 ** 2) / 2} 0, ${Math.sqrt(10 ** 2 - 5 ** 2) / 2}`}
          transform="rotate(180)"
        />
      </g>
    </defs>
  );
}

export default symbols;
