import { useCallback } from "react";
import "./style.scss";

function SubscriptionCard({ subscription, quantity = 0, setQuantity, disabled }) {
  const handleBlur = useCallback(() => {
    if (quantity === "") setQuantity({ value: subscription.quantity, productId: subscription.product.id });
  }, [quantity, subscription, setQuantity]);

  return (
    <div className="subscription-card">
      <div className="subscription-card-line-item">
        <p className="subscription-card-name">{subscription.product.name}</p>
        <p className="subscription-card-description">{subscription.product.description}</p>
        <p className="subscription-card-price">${(subscription.price.unit_amount / 100).toFixed(2)}/user</p>
      </div>
      <input
        className="subscription-card-quantity"
        type="number"
        value={quantity}
        onBlur={handleBlur}
        onChange={(e) => setQuantity({ value: e.target.value, productId: subscription.product.id })}
        min={0}
        disabled={disabled}
      />
    </div>
  );
}

export default SubscriptionCard;
