import "./style.scss";

function NoMatch() {
  return (
    <div className="wrapper">
      <h1>404: Page Not Found</h1>
    </div>
  );
}

export default NoMatch;
