import { useState, useEffect, useContext } from "react";
import { useParams, Navigate } from "react-router-dom";
import axios from "axios";
import { ToastContext } from "../../Contexts/toastContext";

function VerifyEmail() {
  const { userId, emailToken } = useParams();
  const [isVerified, setIsVerified] = useState();
  const { addToast } = useContext(ToastContext);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    let cancel;

    axios
      .put(
        `/api/v1/auth/verify-email/${userId}/${emailToken}`,
        {},
        {
          cancelToken: new CancelToken((c) => (cancel = c)),
        }
      )
      .then((resp) => {
        console.log(resp);
        setIsVerified(true);
        addToast({ type: "success", message: "Email Verified" });
      })
      .catch((err) => {
        console.log(err);
        addToast({ type: "error", message: "Email Not Verified" });
      });

    return () => cancel();
  }, [addToast, emailToken, userId]);

  if (isVerified === undefined) return <p>Verifying email address...</p>;
  if (isVerified) return <Navigate to="/" replace />;
  return <p>Email could not be verified</p>;
}

export default VerifyEmail;
