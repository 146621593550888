import { Link, useLocation } from "react-router-dom";
import "./style.scss";

function SubMenuItem({ label, path, defaultPage }) {
  let location = useLocation();
  let isActive = location.pathname.includes(path) && (!location.pathname.includes("/dashboards/") || location.pathname === path);

  return (
    <Link className={`sub-menu-item ${isActive ? "active" : ""}`} to={`${path}${defaultPage || ""}`}>
      {label}
    </Link>
  );
}

export default SubMenuItem;
