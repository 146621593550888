import "./style.scss";

function Spinner({ isCard, isVisible, hasNoData }) {
  return isVisible ? (
    <div className={`loading-cover ${isCard ? "loading-card" : ""}`}>
      {hasNoData ? (
        <h1 className="spinner-no-data">No Data Available</h1>
      ) : (
        <svg className="spinner-wrapper">
          <circle className="spinner-back" strokeWidth="10" r="20" cx="30" cy="30" />
          <path className="spinner" strokeWidth="10" d="M30,10 a20,20 0 0,1 20,20" />
        </svg>
      )}
    </div>
  ) : null;
}

export default Spinner;
