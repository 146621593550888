import useNewDataFetcher from "../../../../Hooks/newUseDataFetcher";
import GraphTable from "../../../../Components/GraphTable";

function ClientCorporations() {
    const dataURL = "/api/v1/companies/corporations/"
    const { data, dateRange, filterTypes, filters, isReady } = useNewDataFetcher({
        endPoint: dataURL,
    });

    console.log(data)

    return (
        <GraphTable data={data} dateRange={dateRange} filterTypes={filterTypes} filters={filters} isReady={isReady} interval={dateRange.interval} dataURL={dataURL}>
            <h1>Client Corporations</h1>

        </GraphTable>
    );
}

export default ClientCorporations;