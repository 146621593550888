import { createContext, useReducer, useCallback } from "react";
import Toast from "../Components/Toast";

const ToastContext = createContext();

const toastActions = {
  addToast: "ADD_TOAST",
  removeToast: "REMOVE_TOAST",
};

Object.freeze(toastActions);

function ToastProvider(props) {
  const [toasts, dispatch] = useReducer(toastReducer, []);

  const addToast = useCallback((data) => {
    dispatch({ type: toastActions.addToast, data });
  }, []);

  return (
    <ToastContext.Provider
      value={{
        addToast,
      }}
    >
      {props.children}
      <div className="toast-container">
        {toasts
          .map((toast) => {
            return <Toast key={toast.uid} {...toast} close={() => dispatch({ type: toastActions.removeToast, data: toast.uid })} />;
          })
          .reverse()}
      </div>
    </ToastContext.Provider>
  );
}

function toastReducer(state, action) {
  switch (action.type) {
    case toastActions.addToast:
      const random1 = Math.floor(Math.random() * 100000);
      const random2 = Math.floor(Math.random() * 100000);
      const random3 = Math.floor(Math.random() * 100000);
      const uid = `${random1}-${random2}-${random3}`;
      let newState = [...state, { ...action.data, uid }];
      return newState;
    case toastActions.removeToast:
      const remaining = state.filter((item) => item.uid !== action.data);
      return remaining;
    default:
      return state;
  }
}

export { ToastContext, ToastProvider };
