import { useMemo } from "react";
import useNewDataFetcher from "../../../../Hooks/newUseDataFetcher";
import GraphTable from "../../../../Components/GraphTable";
import SubheaderTimePeriod from "../../../../Components/SubheaderTimePeriod";
import { formatNumber } from "../../../../Utilities/formatting";

function ByEmployee() {
  const dataURL = "/api/v1/result/bookings/by-employee"
  const { data, dateRange, filterTypes, filters, isReady } = useNewDataFetcher({
    endPoint: dataURL,
  });

  const booked = useMemo(() => {
    if (!data) return 0;
    let total = 0;
    for (let i = 0; i < data.data.length; i++) {
      total += parseInt(data.data[i].fee);
    }
    return total;
  }, [data]);

  const goal = useMemo(() => {
    if (!data) return 0;
    let total = 0;
    for (let i = 0; i < data.data.length; i++) {
      total += parseInt(data.data[i].goal);
    }
    return total;
  }, [data]);

  const goalMet = useMemo(() => {
    if (!booked || !goal) {
      return false;
    } else if (booked >= goal) {
      return true;
    } else {
      return false;
    }
  }, [booked, goal]);

  return (
    <GraphTable data={data} dateRange={dateRange} interval={dateRange.interval} filterTypes={filterTypes} filters={filters} isReady={isReady} goalMet={goalMet} dataURL={dataURL}>
      <h1 className="page-heading">How much have my employees booked?</h1>
      <h2 className="page-subheading">
        {goalMet ? <span className="page-data goalMet">${formatNumber(booked)}</span> : <span className="page-data goalNotMet">${formatNumber(booked)}</span>}
        <SubheaderTimePeriod data={dateRange} />
      </h2>
    </GraphTable>
  );
}

export default ByEmployee;
