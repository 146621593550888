import useNewDataFetcher from "../../../../Hooks/newUseDataFetcher";
import GraphTable from "../../../../Components/GraphTable";

function ByClient() {
  const dataURL = "/api/v1/activity/recruiting-ratios/client"
  const { data, dateRange, filterTypes, filters, isReady } = useNewDataFetcher({
    endPoint: dataURL,
  });



  return (
    <div>
      <GraphTable data={data} dateRange={dateRange} filterTypes={filterTypes} filters={filters} isReady={isReady} dataURL={dataURL}>
        <h1 className="page-heading">How efficiently are we working job orders for each client?</h1>
        <h2 className="page-subheading">Activity ratios by client</h2>
      </GraphTable>
    </div>
  );
}

export default ByClient;
