import { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import { ToastContext } from "../Contexts/toastContext";
import calculateDateRange from "../Utilities/calculateDateRange";
import { getDateRangeFromParams, getDateRangeFromCurrent } from "../Components/DateRange";
import useParams from "./useParams";

//@TODO: Fix issue with not making request if current is set in the url, but startDate and endDate aren't
function useDataFetcher({ endPoint }) {
  const { addToast } = useContext(ToastContext);
  let { searchParams } = useParams();
  let [paramString, setParamString] = useState();
  let [data, setData] = useState({
    dateRange: searchParams.startDate && searchParams.endDate ? getDateRangeFromParams(searchParams) : {},
    interval: searchParams.interval || "",
    filterTypes: [],
    filters: searchParams.filters || [],
    isReady: false,
  });
  let prevParams = useRef();

  //Check if the query string needs to be updated, and if it does update it
  useEffect(() => {
    let params = [];
    let differences = [];

    for (let key in { ...searchParams, ...(prevParams.current || {}) }) {
      if (["sort", "checked", "page", "columnFilters", "categories"].includes(key)) continue;
      if (!prevParams.current || searchParams[key] !== prevParams.current[key]) {
        differences.push(key);
      }
      if (searchParams[key] && !["startDate", "endDate", "current", "where"].includes(key)) {
        if (Array.isArray(searchParams[key])) {
          params.push(...searchParams[key].map((value) => `${key}=${value}`));
        } else {
          params.push(`${key}=${searchParams[key]}`);
        }
      }
    }

    let startDate, endDate;
    if (searchParams.current) {
      let dateRange = getDateRangeFromCurrent(searchParams.current);
      startDate = dateRange.startDate().toMillis();
      endDate = dateRange.endDate().toMillis();
    } else {
      startDate = searchParams.startDate;
      endDate = searchParams.endDate;
    }

    if (searchParams.where) {
      let whereParam = searchParams.where;

      //If where has a date and startDate and endDate where exist, replace the dates in the where
      if ((searchParams.where.includes("dateAdded") || searchParams.where.includes("dateEffective")) && startDate && endDate) {
        let wherePieces = searchParams.where.split(" AND ");
        whereParam = wherePieces
          .map((piece) => {
            if (piece.includes("dateAdded") || piece.includes("dateEffective")) {
              let key = piece.split(":")[0];
              return `${key}:[${startDate} TO ${endDate}]`;
            } else {
              return piece;
            }
          })
          .join(" AND ");
      }

      params.push(`where=${whereParam}`);
    } else if (startDate && endDate) {
      params.push(`startDate=${startDate}`);
      params.push(`endDate=${endDate}`);
    }

    //Don't make a new request if ONLY the current param changed
    if (differences.length === 1 && differences[0] === "current") return;

    //Don't make a new request if startDate and endDate are changed to match current
    if (differences.length === 2 && searchParams.current && differences.includes("startDate") && differences.includes("endDate"))
      return (prevParams.current = searchParams);

    //If the url has changed in a meaningful way update the param string
    if (differences.length || !prevParams.current) {
      let string = "";
      if (params.length) string = `?${params.join("&")}`;
      prevParams.current = searchParams;
      setParamString(string);
    }
  }, [searchParams]);

  //If the query string is updated, make a new request
  useEffect(() => {
    if (paramString === undefined) return;
    const CancelToken = axios.CancelToken;
    let cancel;

    setData((prevData) => {
      return { ...prevData, isReady: false };
    });

    axios
      .get(`${endPoint}${paramString}`, {
        cancelToken: new CancelToken((c) => (cancel = c)),
      })
      .then((resp) => {
        let {
          data: { dateRange = {}, filters = [], filterTypes, ...data },
        } = resp;

        let actualDateRange = {};
        if (dateRange && dateRange.startDate && dateRange.endDate) {
          actualDateRange = calculateDateRange({ startDate: dateRange.startDate, endDate: dateRange.endDate });
        }

        setData({
          data,
          dateRange: { interval: dateRange.interval, ...actualDateRange },
          filterTypes,
          filters: Array.isArray(filters) ? filters : [filters],
          isReady: true,
        });
      })
      .catch((err) => {
        if (axios.isCancel(err)) console.log("Request canceled");
        else {
          console.log(err);
          addToast({ type: "error", message: "Something went wrong" });
        }
      });

    return () => cancel();
  }, [endPoint, paramString, addToast]);

  return data;
}

export default useDataFetcher;
