import { useState, useEffect, useMemo, useCallback } from "react";
import axios from "axios";
import { Elements } from "@stripe/react-stripe-js";
import stripePromise from "../../Utilities/stripe";
import CheckoutForm from "../CheckoutForm";
import ProductCard from "../ProductCard";

function InitialCustomer({ customer, products }) {
  const [quantities, setQuantities] = useState({});
  const [isSelected, setIsSelected] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [options, setOptions] = useState({});

  useEffect(() => {
    let quantityMap = products.reduce((acc, product) => {
      acc[product.id] = 0;
      return acc;
    }, {});
    setQuantities(quantityMap);
  }, [products]);

  useEffect(() => {
    if (!customer.subscriptions.length) return;
    const CancelToken = axios.CancelToken;
    let cancel;
    let incompleteSubscription = customer.subscriptions.filter((subscription) => subscription.status === "incomplete")[0];
    if (!incompleteSubscription) return;
    axios
      .get(`/api/v1/admin/payment-intent/${incompleteSubscription.id}`, {
        cancelToken: new CancelToken((c) => (cancel = c)),
      })
      .then((resp) => {
        setIsSelected(true);
        setQuantities((prevQuantities) => ({ ...prevQuantities, [incompleteSubscription.plan.product]: incompleteSubscription.quantity }));
        setOptions({ clientSecret: resp.data });
      })
      .catch((err) => {
        console.log(err);
        setIsSelected(false);
      });

    return cancel;
  }, [customer.subscriptions]);

  const handleQuantityChange = useCallback(({ value, productId }) => {
    setQuantities((prevQuantities) => ({ ...prevQuantities, [productId]: value }));
  }, []);

  const handleSelect = useCallback(
    ({ price, quantity }) => {
      if (quantity < 1) return;
      setIsSelected(true);
      axios
        .post("/api/v1/admin/subscription", {
          priceId: price,
          customerId: customer.id,
          quantity,
        })
        .then((res) => {
          setOptions({ clientSecret: res.data.clientSecret });
        })
        .catch((err) => {
          console.log(err);
          setIsSelected(false);
        });
    },
    [customer.id]
  );

  const handleCancel = useCallback(() => {
    let incompleteSubscription = customer.subscriptions.filter((subscription) => subscription.status === "incomplete")[0];
    if (!incompleteSubscription) return;
    setIsBusy(true);
    axios
      .delete(`/api/v1/admin/subscription/${incompleteSubscription.id}`)
      .then(() => {
        setOptions({});
        setIsSelected(false);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsBusy(false);
      });
  }, [customer.subscriptions]);

  const mappedProducts = useMemo(() => {
    return products.map((product) => ({ ...product, quantity: quantities[product.id] || 0 }));
  }, [products, quantities]);

  return (
    <>
      {mappedProducts.map((product) => (
        <ProductCard key={product.id} product={product} setQuantity={handleQuantityChange} onSelect={handleSelect} disabled={isSelected} />
      ))}
      {options.clientSecret ? (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm onCancel={handleCancel} isBusy={isBusy} setIsBusy={setIsBusy} />
        </Elements>
      ) : null}
    </>
  );
}

export default InitialCustomer;
