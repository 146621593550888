import { useMemo } from "react";
import useNewDataFetcher from "../../../../Hooks/newUseDataFetcher";
import GraphTable from "../../../../Components/GraphTable";
import SubheaderTimePeriod from "../../../../Components/SubheaderTimePeriod";

function ByJobOwner() {
  const dataURL = "/api/v1/candidate/status/by-job-owner"
  const { data, dateRange, filterTypes, filters, isReady } = useNewDataFetcher({
    endPoint: dataURL,
  });

  const candidates = useMemo(() => {
    if (!data) return 0;
    let { candidates, jobOrders } = data.data.reduce(
      ({ candidates, jobOrders }, cur) => {
        candidates += cur.candidates;
        jobOrders += cur.jobOrders;
        return { candidates, jobOrders };
      },
      { candidates: 0, jobOrders: 0 }
    );
    return Math.round(candidates / jobOrders);
  }, [data]);

  return (
    <GraphTable data={data} dateRange={dateRange} filterTypes={filterTypes} filters={filters} isReady={isReady} dataURL={dataURL}>
      <h1 className="page-heading">How many candidates are in each stage for our employees?</h1>
      <h2 className="page-subheading">
        <span className="page-data">{candidates}</span> candidates per opening for jobs created <SubheaderTimePeriod data={dateRange} />
      </h2>
    </GraphTable>
  );
}

export default ByJobOwner;
