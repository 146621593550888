import FieldnameList from "../../FieldnameList";

function GraphForm({ showLegend, handleShowLegend, includedColumnsGraph, excludedColumnsGraph, handleColumnSelectGraph, disabled }) {
  return (
    <>
      <div className="include-legend-section">
        <label>Show Legend</label>
        <input type="checkbox" id="showLegend" checked={showLegend} onChange={handleShowLegend} disabled={disabled} />
      </div>
      <div className="fieldname-select">
        <FieldnameList listHeading={"Included"} fieldnameSet={includedColumnsGraph} handleColumnSelect={handleColumnSelectGraph} disabled={disabled} />
        <FieldnameList listHeading={"Excluded"} fieldnameSet={excludedColumnsGraph} handleColumnSelect={handleColumnSelectGraph} disabled={disabled} />
      </div>
    </>
  );
}

export default GraphForm;
