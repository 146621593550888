import FieldnameList from "../../FieldnameList";

function TableForm({ showTotals, handleShowTotals, includedColumnsTable, excludedColumnsTable, handleColumnSelectTable, disabled }) {
  return (
    <>
      <div className="show-totals-section">
        <label>Show Totals</label>
        <input type="checkbox" id="showTotals" checked={showTotals} onChange={handleShowTotals} disabled={disabled} />
      </div>
      <div className={"fieldname-select"}>
        <FieldnameList listHeading={"Included"} fieldnameSet={includedColumnsTable} handleColumnSelect={handleColumnSelectTable} disabled={disabled} />
        <FieldnameList listHeading={"Excluded"} fieldnameSet={excludedColumnsTable} handleColumnSelect={handleColumnSelectTable} disabled={disabled} />
      </div>
    </>
  );
}

export default TableForm;
