import { connectorFunctions } from "../../Utilities/columnFilterFunctions";
import "./style.scss";

function ColumnFilterConnector({ filter, onSymbolChange }) {
  return (
    <select className="column-connector-select" value={filter.key} onChange={onSymbolChange}>
      {Object.entries(connectorFunctions).map(([key, connector], index) => (
        <option key={`connector-option-${index}`} value={key}>
          {connector.symbol}
        </option>
      ))}
    </select>
  );
}

export default ColumnFilterConnector;
