import useNewDataFetcher from "../../../../Hooks/newUseDataFetcher";
import Dashboard from "../../../../Components/Dashboard";
import "./style.scss";

function SingleActivity() {
  const dataURL = "/api/v1/activity/sales/single-activity"
  const { data: apiData, isReady } = useNewDataFetcher({ endPoint: dataURL });
  let { json, data } = apiData || { json: [], data: [] };
  return (
    <>
      <div className="single-activity-wrapper">
        <h1 className="page-heading">Single Activity Trends</h1>
        <Dashboard json={json} data={data} isReady={isReady} />
      </div>
    </>
  );
}

export default SingleActivity;
