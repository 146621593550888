import { useState, useCallback } from "react";
import PhoneInput from "../PhoneInput";
import useAuth from "../../Hooks/useAuth";
import validation from "../../Utilities/validateInput";
import "./style.scss";

function Register({ onSubmit }) {
  const [text, setText] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    bullhornId: "",
    phoneNumber: "",
    companyId: "1",
  });
  const [errors, setErrors] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    bullhornId: "",
    phoneNumber: "",
    companyId: "1",
  });
  const [isSending, setIsSending] = useState(false);
  const { register } = useAuth();

  const handleRegister = useCallback(
    (params) => {
      let errors = {};

      for (let key in params) {
        if (!params[key]) {
          errors[key] = "true";
        }
      }

      let rawNumber = params.phoneNumber.replace(/\D/gm, "");

      if (!validation.isValidEmail(params.email)) errors.email = "true";
      if (!validation.isValidPassword(params.password)) errors.password = "true";
      if (params.confirmPassword !== params.password || !validation.isValidPassword(params.confirmPassword)) errors.confirmPassword = "true";
      if (!validation.isValidPhoneNumber(rawNumber)) errors.phoneNumber = "true";
      if (!validation.isValidBullhornId(params.bullhornId)) errors.bullhornId = "true";

      let hasErrors = Object.values(errors).filter((error) => error).length;
      if (hasErrors) return setErrors(errors);
      setIsSending(true);
      register({ ...params, phoneNumber: rawNumber }, onSubmit, () => setIsSending(false));
    },
    [register, onSubmit]
  );

  const handleChange = useCallback((e) => {
    console.log(e);
    let property = e.target.id.replace(/(-\w)/g, (x) => x[1].toUpperCase()).slice(0, -5);
    setText((prevText) => {
      return { ...prevText, [property]: e.target.value };
    });
  }, []);

  let passwordTip = null;
  if (errors.password) {
    passwordTip = (
      <ul className="password-tip">
        <li>Must be at least 7 characters long</li>
        <li>Must contain both UPPERCASE & lowercase letters</li>
        <li>Must contain numbers</li>
        <li>Must contain special characters</li>
      </ul>
    );
  }

  return (
    <div className="register-box">
      <label htmlFor="email-input">Email:</label>
      <input id="email-input" className={errors.email ? "error" : ""} type="email" value={text.email} onChange={handleChange} disabled={isSending} />
      <label htmlFor="password-input">Password:</label>
      {passwordTip}
      <input
        id="password-input"
        className={errors.password ? "error" : ""}
        type="password"
        value={text.password}
        onChange={handleChange}
        disabled={isSending}
      />
      <label htmlFor="password-input">Confirm Password:</label>
      <input
        id="confirm-password-input"
        className={errors.confirmPassword ? "error" : ""}
        type="password"
        value={text.confirmPassword}
        onChange={handleChange}
        disabled={isSending}
      />
      <label htmlFor="first-name-input">First Name:</label>
      <input
        id="first-name-input"
        className={errors.firstName ? "error" : ""}
        type="text"
        value={text.firstName}
        onChange={handleChange}
        disabled={isSending}
      />
      <label htmlFor="last-name-input">Last Name:</label>
      <input id="last-name-input" className={errors.lastName ? "error" : ""} type="text" value={text.lastName} onChange={handleChange} disabled={isSending} />
      <label htmlFor="bullhorn-id-input">Bullhorn Id:</label>
      <input
        id="bullhorn-id-input"
        className={errors.bullhornId ? "error" : ""}
        type="text"
        value={text.bullhornId}
        onChange={handleChange}
        disabled={isSending}
      />
      <label htmlFor="phone-input">Phone Number:</label>
      <PhoneInput
        id="phone-number-input"
        className={errors.phoneNumber ? "error" : ""}
        type="tel"
        value={text.phoneNumber}
        onChange={handleChange}
        disabled={isSending}
      />
      <button className="submit-button" onClick={() => handleRegister(text)} disabled={isSending}>
        Register
      </button>
    </div>
  );
}

export default Register;
