import { useCallback, useContext, useState, useEffect, useRef } from "react";
import axios from "axios";
import { ToastContext } from "../../Contexts/toastContext";
import { DashboardContext } from "../../Contexts/dashboardContext";
import Modal from "../Modal";
import "./style.scss";

function CardSettings({ settingsOpen, setSettingsOpen, width, height, position, numOfCards, dashboardCardId, dashboard }) {
  const { addToast } = useContext(ToastContext);
  const { updateDashboardCard, deleteDashboardCard } = useContext(DashboardContext);
  const cardDimensions = [1, 2, 3, 4, 5, 6];
  let positions = [];
  for (let i = 0; i < numOfCards; i++) {
    positions.push(i + 1);
  }

  const [newCardWidth, setNewCardWidth] = useState(width);
  const [newCardHeight, setNewCardHeight] = useState(height);
  const [newCardPosition, setNewCardPosition] = useState(position);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const divRef = useRef();

  const handleCloseCardSettings = useCallback(() => {
    setNewCardWidth(width);
    setNewCardHeight(height);
    setNewCardPosition(position);
    setSettingsOpen(false);
  }, [width, height, position, setSettingsOpen]);

  const handleOpenConfirmation = useCallback(() => {
    setIsConfirmationOpen(true)
  }, []);

  const handleCloseConfirmation = useCallback(() => {
    setIsConfirmationOpen(false);
  }, []);

  function handleCardWidthChange(e) {
    setNewCardWidth(e.target.value);
  }

  function handleCardHeightChange(e) {
    setNewCardHeight(e.target.value);
  }
  function handleCardPositionChange(e) {
    setNewCardPosition(e.target.value);
  }

  const handleBlur = useCallback(
    (e) => {
      if (divRef.current.contains(e.relatedTarget) || isConfirmationOpen) return;
      handleCloseCardSettings();
    },
    [handleCloseCardSettings, isConfirmationOpen]
  );

  const deleteCard = useCallback(() => {
    setIsConfirmationOpen(false);
    setIsDisabled(true);
    axios
      .delete(`/api/v1/dashboard/${dashboard.id}/cards/${dashboardCardId}`)
      .then((res) => {
        addToast({ type: "success", message: "Cards Updated" });
        deleteDashboardCard(dashboard.id, dashboardCardId);
      })
      .catch((err) => {
        console.log(err);
        addToast({ type: "error", message: "Unable to Update Cards" });
        setIsDisabled(false);
      });
  }, [addToast, dashboard.id, dashboardCardId, deleteDashboardCard]);

  const updateCardSettings = useCallback(() => {
    if (newCardWidth !== width || newCardHeight !== height || newCardPosition !== position) {
      setIsDisabled(true);
      axios
        .put(`/api/v1/dashboard/${dashboard.id}/cards/${dashboardCardId}`, {
          dashboardCardId: dashboardCardId,
          newCardWidth: newCardWidth,
          newCardHeight: newCardHeight,
          dashboard: dashboard.id,
          newCardPosition: newCardPosition,
          position: position,
        })
        .then((res) => {
          addToast({ type: "success", message: "Cards Updated" });
          updateDashboardCard(dashboard.id, dashboardCardId, newCardWidth, newCardHeight, newCardPosition, position);
        })
        .catch((err) => {
          console.log(err);
          addToast({ type: "error", message: "Unable to Update Cards" });
        })
        .finally(() => {
          setIsDisabled(false);
        });
    }
  }, [addToast, dashboard.id, dashboardCardId, height, newCardHeight, width, newCardWidth, position, newCardPosition, updateDashboardCard]);

  useEffect(() => {
    if (!divRef.current) return;
    divRef.current.focus();
  });

  return (
    <>
      <div className="dashboard-card-settings-wrapper">
        <button
          className="card-button settings-button"
          onClick={() => {
            setSettingsOpen((prevOpen) => !prevOpen);
          }}
        ></button>
        {settingsOpen && (
          <div className="dashboard-card-settings" tabIndex="0" onBlur={handleBlur} ref={divRef} autoFocus>
            <div className="header-section">
              <div className="">Card Settings</div>
              <button className="selector-exit" onClick={handleCloseCardSettings}></button>
            </div>
            <div className="card-settings-body-section">
              <label className="card-settings-option-label">Card Width</label>
              <select className="card-settings-select" value={newCardWidth} onChange={handleCardWidthChange} disabled={isDisabled}>
                {cardDimensions.map((dimension) => {
                  return (
                    <option value={dimension} key={`cardDimensionWidth${dimension}`}>
                      {dimension}
                    </option>
                  );
                })}
              </select>
              <label className="card-settings-option-label">Card Height</label>
              <select className="card-settings-select" value={newCardHeight} onChange={handleCardHeightChange} disabled={isDisabled}>
                {cardDimensions.map((dimension) => {
                  return (
                    <option value={dimension} key={`cardDimensionHeight${dimension}`}>
                      {dimension}
                    </option>
                  );
                })}
              </select>
              <label className="card-settings-option-label">Card Position</label>
              <select className="card-settings-select" value={newCardPosition} onChange={handleCardPositionChange} disabled={isDisabled}>
                {positions.map((position) => {
                  return (
                    <option value={position} key={`cardposition${position}`}>
                      {position}
                    </option>
                  );
                })}
              </select>
              <button className="card-delete-button" onClick={handleOpenConfirmation} disabled={isDisabled}>
                Delete
              </button>
              <button className="card-update-button" onClick={updateCardSettings} disabled={isDisabled}>
                Update
              </button>
            </div>
          </div>
        )}
      </div>
      <Modal className="delete-card-confirmation" title="Delete Card" type="danger" isOpen={isConfirmationOpen} onExit={handleCloseConfirmation}>
        <p>Are you sure you want to delete the card?</p>
        <div className="delete-card-confirmation-buttons">
          <button onClick={handleCloseConfirmation}>Cancel</button>
          <button className="delete-card-confirmation-button" onClick={deleteCard}>Delete</button>
        </div>
      </Modal>
    </>
  );
}

export default CardSettings;
