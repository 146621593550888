import { useState, useEffect, useRef, useCallback, useMemo, useContext } from "react";
import { DateTime } from "luxon";
import Graph from "../Pieces/Graph";
import Table from "../Table";
import { DateRange } from "../DateRange";
import Timestamp from "../Timestamp";
import Axis from "../Pieces/Axis";
import Bars from "../Pieces/Bars";
import Lines from "../Pieces/Lines";
import Circles from "../Pieces/Circles";
import Funnel from "../Pieces/Funnel";
import Spinner from "../Pieces/Spinner";
import useParams from "../../Hooks/useParams";
import { populateVariables, formatDate, formatNumber } from "../../Utilities/formatting";
import { evaluateFilters, getParamsFromFilters, getFiltersFromParams } from "../../Utilities/columnFilterFunctions";
import { domains, percentageRange } from "../../Utilities/domains";
import { EmailContext } from "../../Contexts/emailContext";
import "./style.scss";

import PinModal from "../PinModal";

const defaultPerPage = 50;
const defaultChecked = 15;
const maxLength = 25;

function GraphTable({
  children,
  data: apiData = {},
  dateRange,
  interval,
  filters,
  filterTypes,
  checked: paramChecked,
  columnFilters: paramColumnFilters,
  categories: paramCategories,
  showTotals: paramShowTotals,
  showLegend: paramShowLegend,
  isReady,
  isCard,
}) {
  const { searchParams, updateParams } = useParams();
  let initialSort = {};
  if (searchParams.sort) initialSort = { fieldName: searchParams.sort.replace("-", ""), direction: searchParams.sort.includes("-") ? "down" : "up" };

  let initialChecked = [];
  if (searchParams.checked) {
    initialChecked = searchParams.checked;
    if (!Array.isArray(initialChecked)) initialChecked = [initialChecked];
  }

  const { ref } = useContext(EmailContext);

  const [graphAdjusted, setGraphAdjusted] = useState(false);
  const [sort, setSort] = useState(initialSort);
  const [columnFilters, setColumnFilters] = useState(paramColumnFilters || getFiltersFromParams(searchParams.columnFilters));
  const [checked, setChecked] = useState(paramChecked || initialChecked);
  const [page, setPage] = useState(searchParams.page ? searchParams.page - 1 : 0);
  const [categories, setCategories] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const maxCheckedCount = useRef(initialChecked.length || maxLength);

  const onToggleCheck = useCallback((changed) => {
    if (!Array.isArray(changed)) changed = [changed];

    setChecked((prevChecked) => {
      let newChecked = [...prevChecked];
      for (let change of changed) {
        if (newChecked.includes(change.uid) && !change.isVisible) newChecked.splice(newChecked.indexOf(change.uid), 1);
        else if (!newChecked.includes(change.uid) && change.isVisible) newChecked.push(change.uid);
      }
      updateParams({ checked: newChecked });
      return newChecked;
    });
  }, [updateParams]);

  const onToggleCategory = useCallback((changed) => {
    if (!Array.isArray(changed)) changed = [changed];

    setCategories((prevOn) => {
      let newOn = [];
      for (let prevCat of prevOn) {
        let newCat = changed.find((item) => item.label === prevCat.label);
        newOn.push(newCat || prevCat);
      }
      return newOn;
    });
  }, []);

  const onToggleExpanded = useCallback((changed) => {
    if (!Array.isArray(changed)) changed = [changed];

    setExpanded((prevOn) => {
      let newOn = [...prevOn];
      for (let { uid } of changed) {
        let index = newOn.indexOf(uid);
        if (index === -1) newOn.push(uid);
        else newOn.splice(index, 1);
      }
      return newOn;
    });
  }, []);

  const onSortChange = useCallback((sort) => {
    setSort(sort);
  }, []);

  const onFilterChange = useCallback((filterObj) => {
    setColumnFilters((prevFilters) => {
      let newFilters = { ...prevFilters, ...filterObj };
      let key = Object.keys(filterObj)[0];
      if (newFilters[key] === undefined) delete newFilters[key];
      return newFilters;
    });
  }, []);

  const onPageChange = useCallback(
    (newPage) => {
      if (!apiData.data) return;
      let maxPages = Math.ceil(apiData.data.length / defaultPerPage);
      if (newPage >= maxPages || newPage < 0) return;
      setPage(newPage);
    },
    [apiData.data]
  );

  //Set initial sort
  useEffect(() => {
    if (sort.fieldName || !apiData.table || !apiData.table.sortBy) return;
    setSort(apiData.table.sortBy);
  }, [apiData.table, sort]);

  //When a new request is made, reset the graph adjusted
  useEffect(() => {
    if (isReady) return;
    setGraphAdjusted(false);
  }, [isReady]);

  //Set initial categories
  useEffect(() => {
    if (!apiData?.table || !apiData?.graphs.length || categories.length) return;
    let newCategories = [];
    let shapeIndex = 0;
    let colorIndex = 0;
    let isBar = apiData.graphs.reduce((acc, cur) => acc && ["stacked", "bar"].includes(cur.type), true);

    for (let column of apiData.table.columns) {
      if (column.exclude === true || column.exclude === "graph" || column.isMain || column.isId) continue;
      let isVisible = true;
      if (paramCategories && !paramCategories.includes(column.fieldName)) isVisible = false;
      if (searchParams.categories && !searchParams.categories.includes(column.label)) isVisible = false;
      let shape = isBar ? "square" : column.shape || shapes[shapeIndex];
      newCategories.push({ label: column.label, shape, color: column.color || colors[colorIndex], isVisible });
      shapeIndex = (shapeIndex + 1) % shapes.length;
      colorIndex = (colorIndex + 1) % colors.length;
    }

    setCategories(newCategories);
  }, [apiData.table, apiData.graphs, searchParams.categories, paramCategories, categories]);

  const hasTime = useMemo(() => {
    if (!apiData || !apiData.graphs) return false;
    return apiData.graphs.reduce((hasTime, graph) => hasTime || graph.axes.reduce((hasTime, axis) => hasTime || axis.type === "time", false), false);
  }, [apiData]);

  const itemsPerPage = useMemo(() => {
    return (apiData.table && apiData.table.itemsPerPage) || defaultPerPage;
  }, [apiData.table]);

  const categoryLabels = useMemo(() => {
    return categories.filter((category) => category.isVisible).map((category) => category.label);
  }, [categories]);

  const { paginated, itemCount, overallTotals } = useMemo(() => {
    if (!apiData?.table) return { paginated: [], itemCount: 0, overallTotals: [] };

    maxCheckedCount.current = apiData.table.checked;
    let { uidField, mainField, subField, expandableField, typeByFieldName } = apiData.table.columns.reduce(
      (acc, cur) => {
        if (cur.isId) {
          acc.uidField = cur.fieldName;
        } else if (cur.isMain) {
          acc.mainField = cur.fieldName;
        } else if (cur.isSub) {
          acc.subField = cur.fieldName;
        } else if (cur.type === "array") {
          acc.expandableFieldName = cur.fieldName;
        }
        acc.typeByFieldName[cur.fieldName] = cur.type || "number";
        return acc;
      },
      { typeByFieldName: {} }
    );

    let withUids = apiData.data.map((item) => {
      let uid = (item[uidField] || `${item[mainField]}${item[subField] ? ` ${item[subField]}` : ""}`).toString();
      let obj = { ...item, uid };
      if (expandableField) {
        let expandable = [];
        for (let innerRow of item[expandableField]) {
          let innerUid = expandableField + (innerRow[uidField] || `${innerRow[mainField]}${innerRow[subField] ? ` ${innerRow[subField]}` : ""}`).toString();
          expandable.push({ ...innerRow, uid: innerUid });
        }
        obj[expandableField] = expandable;
      }
      return obj;
    });

    let filtered = withUids.filter((item) => {
      for (let fieldName in columnFilters) {
        let filters = columnFilters[fieldName];
        let value = item[fieldName];
        if (value === undefined || value === null) {
          if (["number", "date"].includes(typeByFieldName[fieldName])) value = 0;
          else value = "None";
        }
        if (!evaluateFilters(value, filters)) return false;
      }
      return true;
    });

    let shouldTotal = apiData.graphs.reduce((acc, cur) => acc && !cur.ignoreSort && (cur.type === "stacked" || cur.type === "bar"), true);

    let sorted = filtered;
    if (sort.fieldName) {
      let column = apiData.table.columns.find((column) => column.fieldName === sort.fieldName) || {};
      sorted = filtered.sort((a, b) => {
        let value = 0;
        let aVal = a[sort.fieldName];
        let bVal = b[sort.fieldName];
        let type = column.type || "number";
        if (!aVal || !bVal) {
          let fillVal;
          if (type === "string") {
            fillVal = "";
          } else {
            fillVal = 0;
          }
          aVal = aVal || fillVal;
          bVal = bVal || fillVal;
        }
        if (type === "number") value = aVal - bVal;
        else if (type === "date") {
          if (DateTime.fromSQL(aVal).isValid) {
            value = DateTime.fromSQL(aVal).toMillis() - DateTime.fromSQL(bVal).toMillis();
          } else {
            value = aVal - bVal;
          }
        } else value = aVal.toString().trim().localeCompare(bVal.toString().trim());
        if (sort.direction === "down") value = value * -1;
        return value;
      });
    } else if (shouldTotal) {
      let cols = apiData.table.columns.filter(
        (column) =>
          (!categoryLabels.length || categoryLabels.includes(column.label)) &&
          !(column.exclude === true || column.exclude === "graph" || column.isId || column.isMain || column.isSub || (column.type && column.type !== "number"))
      );

      if (cols.length) {
        let rowTotals = filtered.reduce((totals, row) => {
          totals[row.uid] = 0;
          for (let col of cols) {
            totals[row.uid] += row[col.fieldName] || 0;
          }
          return totals;
        }, {});

        sorted = filtered.sort((a, b) => {
          return rowTotals[b.uid] - rowTotals[a.uid];
        });
      }
    }

    let overallTotals = [];
    for (let row of sorted) {
      let index = 0;
      for (let column of apiData.table.columns) {
        if (column.exclude === true || column.exclude === "table") continue;
        if (overallTotals[index] === undefined) {
          if ((column.type === "number" || !column.type) && column.suffix !== "%")
            overallTotals.push({ fieldName: column.fieldName, value: 0, numAfterDecimals: 0 });
          else overallTotals.push({ fieldName: column.fieldName, value: "" });
        }
        let value = (column.type === "number" || !column.type) && column.suffix !== "%" ? parseFloat(row[column.fieldName]) || 0 : "";
        if (typeof value === "number") {
          let numbers = value.toString().split(".");
          let numAfterDecimals = (numbers[1] && numbers[1].length) || 0;
          if (numAfterDecimals > overallTotals[index].numAfterDecimals) overallTotals[index].numAfterDecimals = numAfterDecimals;
        }
        overallTotals[index].value += value;
        index++;
      }
    }

    if (!overallTotals.filter((item) => item.value !== "").length) overallTotals = null;
    if (overallTotals)
      overallTotals = overallTotals.map((total) => {
        if (!total || total.numAfterDecimals === undefined) return total;
        //Round to max precision allowed by the numbers the total is calculated from

        let totalVal = Math.round(total.value * 10 ** total.numAfterDecimals) / 10 ** total.numAfterDecimals;
        return { ...total, value: totalVal };
      });

    let start = page * itemsPerPage;
    let paginated = sorted.slice(start, start + itemsPerPage);

    if (expanded.length) {
      paginated = paginated.reduce((acc, cur) => {
        acc.push({ ...cur, isExpanded: expanded.includes(cur.uid) });
        return acc;
      }, []);
    }

    return { paginated, itemCount: sorted.length, overallTotals };
  }, [apiData, sort, columnFilters, page, expanded, itemsPerPage, categoryLabels]);

  //Update params when a sort is changed
  let stringSort = JSON.stringify(sort);
  useEffect(() => {
    if (isCard) return;
    let sort = JSON.parse(stringSort);
    if (!sort.fieldName) return;
    updateParams({ sort: `${sort.direction === "down" ? "-" : ""}${sort.fieldName}` });
  }, [isCard, stringSort, updateParams]);

  //Update params when categories are changed
  let stringCategories = JSON.stringify(categories);
  useEffect(() => {
    if (isCard) return;
    let categories = JSON.parse(stringCategories);
    if (!categories.length) return;
    let filtered = categories.filter((category) => category.isVisible);
    if (categories.length === filtered.length) updateParams({ categories: undefined });
    else updateParams({ categories: filtered.map((category) => category.label) });
  }, [isCard, stringCategories, updateParams]);

  //Update params when column filters change
  const stringColumnFilters = JSON.stringify(columnFilters);
  useEffect(() => {
    if (isCard) return;
    let columnFilters = JSON.parse(stringColumnFilters);
    if (!Object.keys(columnFilters).length) return;
    let columnFilterParams = getParamsFromFilters(columnFilters);
    updateParams({ columnFilters: columnFilterParams });
  }, [isCard, stringColumnFilters, updateParams]);

  //Update checked params when checked is changed
  let stringChecked = JSON.stringify(checked);
  // useEffect(() => {
  //   if (isCard) return;
  //   let checked = JSON.parse(stringChecked);
  //   if (!checked.length) return;
  //   updateParams({ checked });
  // }, [isCard, stringChecked, updateParams]);
  
  //Update page params when page is changed
  useEffect(() => {
    if (isCard) return;
    if (!page) updateParams({ page: undefined });
    else updateParams({ page: page + 1 });
  }, [isCard, page, updateParams]);

  const stringPaginated = JSON.stringify(paginated);

  const { processed, sortedUids, selectedTotals, showTable, showGraph, hideChecks, hideTotals, showLegend, hidePagination } = useMemo(() => {
    let sortedUids = [];
    let processed = [];
    let selectedTotals = [];
    if (!apiData.table || !apiData.table.columns) return { processed, selectedTotals };

    let paginated = JSON.parse(stringPaginated);
    let checked = JSON.parse(stringChecked);

    let filteredColumns = apiData.table.columns.filter((column) => column.type === "array" || (column.exclude !== true && column.exclude !== "table"));

    for (let item of paginated) {
      let isVisible = checked.includes(item.uid);
      if (isVisible) sortedUids.push(item.uid);
      processed.push({ ...item, isVisible });
      for (let column of filteredColumns) {
        let value;

        if ((column.type === "number" || !column.type) && column.suffix !== "%") value = parseFloat(item[column.fieldName]) || 0;
        else value = "";

        if (column.type === "array") {
          let parentIndex = processed.length - 1;
          let shouldBeExpanded = false;
          for (let inner of item[column.fieldName]) {
            let innerVisible = checked.includes(inner.uid);
            if (innerVisible || item.isExpanded) {
              processed.push({ ...inner, isVisible: innerVisible });
              shouldBeExpanded = true;
            }
          }
          if (shouldBeExpanded) processed[parentIndex].isExpanded = true;
          continue;
        }

        let index = selectedTotals.findIndex((col) => col.fieldName === column.fieldName);
        let numAfterDecimals;
        if (typeof value === "number") {
          let numbers = value.toString().split(".");
          numAfterDecimals = (numbers[1] && numbers[1].length) || 0;
        }
        if (index === -1) {
          let obj = { fieldName: column.fieldName, value };
          if (numAfterDecimals !== undefined) obj.numAfterDecimals = numAfterDecimals;
          selectedTotals.push(obj);
        } else if (isVisible) {
          selectedTotals[index].value += value;
          if (selectedTotals[index].numAfterDecimals !== undefined && selectedTotals[index].numAfterDecimals < numAfterDecimals)
            selectedTotals[index].numAfterDecimals = numAfterDecimals;
        }
      }
    }

    selectedTotals = selectedTotals.map((total) => {
      if (total.numAfterDecimals === undefined) return total;
      //Round to max precision allowed by the numbers the total is calculated from

      let totalVal = Math.round(total.value * 10 ** total.numAfterDecimals) / 10 ** total.numAfterDecimals;
      return { ...total, value: totalVal };
    });

    const showTable = apiData.table.show !== false;
    const showGraph = apiData.graphs.length;
    const { checked: tableCount, hideChecks, hidePagination } = apiData.table;
    let hideTotals = paramShowTotals === undefined ? apiData.table.hideTotals : !paramShowTotals;
    let showLegend = paramShowLegend === undefined ? apiData.graphs.reduce((acc, cur) => acc && (cur.legend === undefined ? true : cur.legend), true) : paramShowLegend;


    if (!sortedUids.length) {
      sortedUids = processed.slice(0, tableCount || defaultChecked).map((item) => item.uid);
      setChecked(sortedUids);
    }

    sortedUids = sortedUids.reverse();

    if (!selectedTotals.filter((item) => item.value !== "").length) selectedTotals = null;

    return { processed, sortedUids, selectedTotals, showTable, showGraph, hideChecks, hideTotals, showLegend, hidePagination };
  }, [stringPaginated, stringChecked, apiData, paramShowTotals, paramShowLegend]);

  const prevPage = useRef(page);
  const prevSort = useRef(sort);
  const prevColumnFilters = useRef(columnFilters);
  const prevExpanded = useRef(expanded);

  //Set expanded if they were checked in the params;
  useEffect(() => {
    if (!processed.length || expanded.length || prevExpanded.current.length) return;
    let shouldBeExpanded = [];
    for (let item of processed) {
      if (item.isExpanded) shouldBeExpanded.push(item.uid);
    }
    if (shouldBeExpanded.length === expanded.length) return;
    setExpanded(shouldBeExpanded);
  }, [processed, expanded]);

  //Update checked on sort or page change
  useEffect(() => {
    if (
      !paginated ||
      !paginated.length ||
      (prevPage.current === page && prevSort.current === sort && prevColumnFilters.current === columnFilters && prevExpanded.current === expanded)
    )
      return (prevSort.current = sort);

    let sortChanged = prevSort.current !== sort;

    prevPage.current = page;
    prevSort.current = sort;
    prevColumnFilters.current = columnFilters;
    let prevExpandedLength = prevExpanded.current.length;
    prevExpanded.current = expanded;

    setChecked((prevChecked) => {
      let checkedCount = (!prevExpandedLength && prevChecked.length) || maxCheckedCount.current || defaultChecked;
      if (expanded.length) {
        let newChecked = paginated.reduce((acc, cur) => {
          if (cur.isExpanded) {
            for (let key in cur) {
              if (!Array.isArray(cur[key])) continue;
              for (let { uid } of cur[key]) {
                acc.push(uid);
              }
            }
          }
          return acc;
        }, []);
        return newChecked;
      }

      //If one axis is time and the only thing that changed was the sort keep the previously checked items
      // if (sortChanged && hasTime) return prevChecked;

      let newChecked = paginated.slice(0, hasTime && sortChanged ? paginated.length : checkedCount).map((item) => item.uid);
      return newChecked;
    });
  }, [paginated, page, sort, columnFilters, expanded, hasTime]);

  const prevIsReady = useRef(isReady);

  //Update checked on new data
  useEffect(() => {
    let checked = JSON.parse(stringChecked);
    if ((apiData?.table?.sortBy && !sort.fieldName) || (isReady === prevIsReady.current && (!isReady || checked.length)) || !paginated.length) return;

    prevIsReady.current = isReady;

    setChecked((prevChecked) => {
      //If there is any overlap between the new data and old data keep the ones that were previously checked
      if (prevChecked.length && !hasTime) {
        for (let { uid } of paginated) {
          if (prevChecked.includes(uid)) return prevChecked;
        }
      }

      let checkedCount = maxCheckedCount.current || defaultChecked;
      let newChecked = paginated.slice(0, hasTime ? paginated.length : checkedCount).map((item) => item.uid);
      return newChecked;
    });
  }, [isReady, stringChecked, paginated, hasTime, apiData?.table?.sortBy, sort.fieldName]);

  const tableData = useMemo(() => {
    if (!apiData.table) return { columns: [], rows: [] };

    let { startDate, endDate } = dateRange || {};
    if (startDate) startDate = startDate();
    if (endDate) endDate = endDate();
    let filteredColumns = apiData.table.columns.filter((item) => !item.exclude || item.exclude === "graph");
    let expandable = apiData.table.columns.filter((item) => item.type === "array");
    let rows = apiToTable(processed, [...filteredColumns, ...expandable], { startDate, endDate, interval });

    return { rows, columns: filteredColumns };
  }, [processed, apiData.table, dateRange, interval]);

  const graphData = useMemo(() => {
    if (!apiData || !apiData.graphs) return [];

    let data = apiToGraph(processed, apiData.table.columns, apiData.graphs, categories);

    return data;
  }, [processed, apiData, categories]);

  let axes = useMemo(() => {
    if (!apiData || !apiData.graphs) return [];

    let domainFuncs = domains;
    let existing = {};
    let axesComponents = apiData.graphs.reduce((components, graph) => {
      graph.axes.forEach((axis) => {
        let { name, type: axisType, position, ticks, domain: domainName } = axis;
        if (existing[name]) return;
        existing[name] = true;
        domainName = `${domainName || axisType}Domain`;
        let domain = domainFuncs[domainName];
        let type;
        if (axisType === "stacked") {
          type = "linear";
        } else {
          type = axisType;
        }
        let range;
        if (["stacked", "layered", "bar"].includes(graph.type) && axisType === "time") {
          range = (min, max, isHorizontal) => {
            let array = [min + 50, max - 50];
            return array;
          };
        }

        if (axisType === "percentage") {
          let hasFoundIndex = false;
          let index = 0;
          let numberOfPercentageGraphs = 0;
          for (let tempGraph of apiData.graphs) {
            if (tempGraph === graph) hasFoundIndex = true;
            if (!hasFoundIndex) index++;
            for (let tempAxis of tempGraph.axes) {
              if (tempAxis.type === "percentage") {
                numberOfPercentageGraphs++;
                break;
              }
            }
          }
          range = percentageRange(numberOfPercentageGraphs, index);
        }

        components.push(
          <Axis
            key={name}
            className={`${name}-axis`}
            name={name}
            position={position}
            type={type}
            domain={domain(name)}
            range={range}
            ticks={ticks}
            dataSource={["y", "x"].includes(name) ? undefined : name}
            show={axis.show === undefined ? true : axis.show}
            interval={interval}
            additionalData={apiData}
          />
        );
      });
      return components;
    }, []);

    return axesComponents;
  }, [apiData, interval]);

  const graphs = useMemo(() => {
    if (!apiData || !apiData.graphs) return [];

    let graphComponents = apiData.graphs.map(({ name, type, axes }, idx) => {
      let xScale = axes.find(({ position }) => ["top", "bottom"].includes(position)).name;
      let yScale = axes.find(({ position }) => ["left", "right"].includes(position)).name;
      let isPercentage = axes.reduce((acc, cur) => acc || cur.type === "percentage" || cur.domain === "percentage", false);
      let dataSource = (xScale === "x" ? undefined : xScale) || (yScale === "y" ? undefined : yScale);

      switch (type) {
        case "line":
          return <Lines key={`${type}-${idx}`} name={name} type="line" xScale={xScale} yScale={yScale} dataSource={dataSource} />;
        case "bar":
          return <Bars key={`${type}-${idx}`} name={name} type="bar" xScale={xScale} yScale={yScale} dataSource={dataSource} percentage={isPercentage} />;
        case "stacked":
          return <Bars key={`${type}-${idx}`} name={name} type="bar" xScale={xScale} yScale={yScale} dataSource={dataSource} stacked />;
        case "layered":
          return <Bars key={`${type}-${idx}`} name={name} type="bar" xScale={xScale} yScale={yScale} dataSource={dataSource} layered />;
        case "circle":
          return <Circles key={`${type}-${idx}`} name={name} xScale={xScale} yScale={yScale} rMin={5} rMax={25} dataSource={dataSource} />;
        case "funnel":
          return <Funnel key={`${type}-${idx}`} name={name} xScales={xScale} yScale={yScale} dataSource={dataSource} />;
        default:
          return null;
      }
    });

    return graphComponents;
  }, [apiData]);

  let isLoading = !isReady || (!graphAdjusted && showGraph);
  let hasNoData = apiData.data && !apiData.data.length;

  let pinComponent;
  if (!isCard && !isLoading) {
    pinComponent = <PinModal dateRange={dateRange} apiData={apiData.data} columns={apiData.table} graphs={apiData.graphs} />
  }

  // Component return
  return (
    <div className="graph-table-wrapper" ref={!isCard ? ref : () => { }}>
      {children}
      {isCard || (apiData && apiData.table && apiData.table.show === false) ? null : (
        <DateRange dateRange={dateRange} interval={interval} filterTypes={filterTypes} filters={filters} />
      )}
      {pinComponent} 
      <div className="graph-table">
        <Spinner isCard={isCard} isVisible={isLoading} hasNoData={hasNoData} />

        {!showGraph ? null : (
          <div>
            <Graph
              className={`graph ${isCard ? "card-graph" : "page-graph"}`}
              data={graphData}
              sortedUids={sortedUids}
              categories={categories}
              onToggle={onToggleCategory}
              onGraphReady={(value) => setGraphAdjusted(value)}
              isReady={isReady}
              axisCount={axes.length}
              legend={showLegend}
            >
              {axes}
              {graphs}
            </Graph>
          </div>
        )}
        {isLoading || !showTable ? null : (
          <div>
            <Table
              data={tableData}
              itemsPerPage={itemsPerPage}
              itemCount={itemCount}
              sort={sort}
              page={page}
              columnFilters={columnFilters}
              selectedTotals={selectedTotals}
              overallTotals={overallTotals}
              onToggle={onToggleCheck}
              onExpand={onToggleExpanded}
              onSortChange={onSortChange}
              onPageChange={onPageChange}
              onFilterChange={onFilterChange}
              hideChecks={hideChecks}
              hideTotals={hideTotals}
              hidePagination={hidePagination}
              isCard={isCard}
            />
          </div>
        )}
      <Timestamp isVisible={!isLoading} isCard={isCard} />
      </div>
    </div>
  );
}

function apiToTable(data, columns, options) {
  const { interval, startDate, endDate } = options;
  const expandableColumn = columns.find((column) => column.type === "array");

  let rows = data.map((item) => {
    let isExpandable = expandableColumn && item[expandableColumn.fieldName] && item[expandableColumn.fieldName].length;
    let row = {
      isVisible: item.isVisible,
      uid: item.uid,
      isExpanded: item.isExpanded,
      isExpandable,
    };

    for (let { fieldName, type, hover, link } of columns) {
      if (hover) hover = populateVariables(hover, { interval, startDate, endDate, ...item }).replace(/\s{2,}|\n{2, }/gm, "");
      if (link) link = populateVariables(link, { interval, startDate, endDate, ...item }, true);
      let value = item[fieldName];
      switch (type) {
        case "date":
          let temp = DateTime.fromSQL(value).toJSDate();
          if (isNaN(temp)) {
            temp = DateTime.fromMillis(value).toJSDate();
          }
          value = temp;
          break;
        case "string":
          value = (value || "None").toString().trim();
          break;
        case "array":
          break;
        default:
          value = +(+value).toFixed(6) || 0;
          break;
      }

      row[fieldName] = { value, hover, link };
    }
    return row;
  });
  return rows;
}

//@TODO: Fix inefficient mapping of data because of nested loops
function apiToGraph(data, columns, graphs, categories) {
  if (!categories.length) return [];

  let timeColumn;
  for (let column of columns) {
    if (column.type === "date") {
      timeColumn = column;
      break;
    }
  }

  //If there is a time column, make sure the sort is by date for the graph
  if (timeColumn) {
    data = [...data].sort((a, b) => {
      let value = 0;
      let aVal = a[timeColumn.fieldName];
      let bVal = b[timeColumn.fieldName];
      if (DateTime.fromSQL(aVal).isValid) {
        value = DateTime.fromSQL(aVal).toMillis() - DateTime.fromSQL(bVal).toMillis();
      } else {
        value = aVal - bVal;
      }
      return value;
    });
  }

  let newData = graphs.reduce((newGraphs, graph) => {
    let mainCol;
    let radiusCol;
    let filteredColumns = [];
    for (let column of columns) {
      if (column.isMain) mainCol = column;
      else if (column.axis === "r") radiusCol = column;
      else if (!column.isId && !column.isSub && column.exclude !== true && column.exclude !== "graph") filteredColumns.push(column);
    }

    let mainAxis = graph.axes.find((axis) => axis.name === mainCol.axis);
    let otherAxis = graph.axes.find((axis) => axis.name !== mainCol.axis);

    let graphData = {};
    let tempData = [...data];
    if (
      (["stacked", "bar"].includes(mainAxis.type) && ["left", "right"].includes(mainAxis.position)) ||
      (["stacked", "bar"].includes(otherAxis.type) && ["left", "right"].includes(otherAxis.position))
    )
      tempData.reverse();

    for (let row of tempData) {
      if (!row.isVisible) continue;
      let mainValue = row[mainCol.fieldName];
      let mainLabel = mainValue;

      if (mainCol.type === "date") {
        let temp = DateTime.fromSQL(row[mainCol.fieldName]);
        if (!temp.isValid) temp = DateTime.fromMillis(row[mainCol.fieldName]);
        mainValue = temp.toJSDate();
        mainLabel = formatDate(temp.toJSDate());
      } else if (mainCol.type === "string") {
        mainValue = (mainValue || "None").slice(0, maxLength);
        mainLabel = (mainLabel || "None").slice(0, maxLength);
      }

      for (let column of filteredColumns) {
        if ((column.axis && column.axis !== otherAxis.name) || column.graph !== graph.name) continue;
        let category = categories.find((cat) => cat.label === column.label);

        if (!graphData[column.label]) {
          let dots = true;
          if (graph.dots === false || column.dots === false) dots = false;
          graphData[column.label] = {
            label: column.label,
            isVisible: category.isVisible,
            uid: column.label,
            items: [],
            line: graph.lines,
            dots,
            color: category.color,
            shape: category.shape,
            dashed: column.dashed,
          };
        }

        let otherValue = row[column.fieldName];
        let otherLabel = otherValue;

        if (otherValue === undefined) continue;

        if (column.type === "date") {
          otherValue = DateTime.fromSQL(otherValue).toJSDate();
          otherLabel = formatDate(otherLabel);
        } else if (!column.type || column.type === "number") {
          otherValue = parseFloat(otherValue || 0);
          otherLabel = formatNumber(otherLabel);
        }

        let hoverData;
        if (column.graphHover) hoverData = column.graphHover.map((string) => populateVariables(string, row));
        else hoverData = [mainLabel, `${column.label}: ${column.prefix || ""}${otherLabel}${column.suffix || ""}`];

        let item = {
          uid: row.uid,
          [mainAxis.name]: mainValue,
          [otherAxis.name]: otherValue,
          data: hoverData,
          label: mainLabel,
        };

        if (radiusCol) item.r = row[radiusCol.fieldName];

        graphData[column.label].items.push(item);
      }
    }

    graphData = Object.values(graphData);
    if (!graph.name) return [...(Array.isArray(newGraphs) ? newGraphs : []), ...graphData];
    newGraphs[graph.name] = graphData;
    return newGraphs;
  }, {});
  return newData;
}

const colors = [
  "#3DD2FF",
  "#4D439B",
  "#8C3FA0",
  "#BA2B14",
  "#CC6C00",
  "#C4AF0A",
  "#408635",
  "#2DA187",
  "#0081A0",
  "#8179C5",
  "#B876C9",
  "#EA553D",
  "#FF9825",
  "#F5DF31",
  "#5FBA50",
  "#57D0B5",
  "#00B3E9",
];
const shapes = ["diamond", "square", "circle", "triangle-up", "triangle-down", "ellipse-right", "ellipse-left"];

export default GraphTable;
