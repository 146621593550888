import { useLocation } from "react-router-dom";
import { useState, useEffect, Children, cloneElement } from "react";
import "./style.scss";

function SubMenu({ label, children, path }) {
  let location = useLocation();
  const [isOpen, setIsOpen] = useState(location.pathname.includes(path));

  useEffect(() => {
    setIsOpen(location.pathname.includes(path));
  }, [path, location.pathname]);

  return (
    <div className={`sub-menu-wrapper ${isOpen ? "open" : "closed"}`}>
      <p className="menu-item" onClick={() => setIsOpen((isOpen) => !isOpen)}>
        {label}
      </p>
      <div className="sub-menu">{Children.map(children, (child) => cloneElement(child, { path: path + child.props.path }))}</div>
    </div>
  );
}

export default SubMenu;
