import { useState, useRef, useCallback } from "react";
import ChipDropDown from "../ChipDropDown";
import "./style.scss";

function ChipInput({ className, options, value, onChange, disabled, name, ...props }) {
  const [isOpen, setIsOpen] = useState(false);
  const divRef = useRef(null);
  const inputRef = useRef(null);
  const optionsRef = useRef(null);

  const addChip = useCallback(
    (chip) => {
      const existingValues = value.split(", ").slice(0, -1).join(", ");
      if (!existingValues.includes(chip.value)) {
        onChange({ target: { value: existingValues ? `${existingValues}, ${chip.value}, ` : `${chip.value}, `, className, name } });
      }
      inputRef.current.focus();
    },
    [value, onChange, className, name]
  );

  const removeChip = useCallback(
    (chip) => {
      let filteredValues = value
        .split(", ")
        .filter((item) => item !== chip.value)
        .join(", ");

      onChange({ target: { value: filteredValues, className, name } });
    },
    [value, onChange, className, name]
  );

  const handleChange = useCallback(
    (e) => {
      const existingValues = value.split(", ").slice(0, -1).join(", ");
      onChange({ target: { value: existingValues ? `${existingValues}, ${e.target.value}` : e.target.value, className, name } });
    },
    [value, onChange, className, name]
  );

  const handleInputKeyDown = useCallback(
    (e) => {
      switch (e.code) {
        case "Enter":
          let value = e.target.value;
          addChip({ label: value, value: value });
          break;
        case "ArrowDown":
          optionsRef.current.children[0].focus();
          break;
        case "Escape":
          setIsOpen(false);
          break;
        default:
        //Do Nothing
      }
    },
    [addChip]
  );

  const handleOptionsKeyDown = useCallback(
    (e, option) => {
      switch (e.code) {
        case "Enter":
          addChip(option);
          break;
        case "ArrowUp":
          if (e.target.previousSibling) {
            e.target.previousSibling.focus();
          } else {
            inputRef.current.focus();
          }
          break;
        case "ArrowDown":
          if (e.target.nextSibling) {
            e.target.nextSibling.focus();
          }
          break;
        case "Escape":
          setIsOpen(false);
          break;
        default:
        //Do Nothing
      }
    },
    [addChip]
  );

  const handleFocus = useCallback((e) => {
    setIsOpen(true);
  }, []);

  const handleBlur = useCallback((e) => {
    if (divRef.current.contains(e.relatedTarget)) return;
    setIsOpen(false);
  }, []);

  const [inputValue, ...completedValues] = value.split(", ").reverse();

  const filteredOptions = options.filter((item) => {
    let itemValue = item.value.slice(0, inputValue.length).toLowerCase();
    let itemLabel = item.label.slice(0, inputValue.length).toLowerCase();
    if (itemValue === inputValue.toLowerCase() || itemLabel === inputValue.toLowerCase()) return true;
    return false;
  });

  const chips = completedValues.reverse().map((value) => {
    let option = options.find((option) => option.value === value);
    let label = option ? option.label : value;
    return { value, label };
  });

  return (
    <div className="chip-input-wrapper" onFocus={handleFocus} onBlur={handleBlur} ref={divRef}>
      {/* <div className="chips">
        {chips.map((chip) => (
          <Chip key={chip.value} value={chip.label} onDelete={() => removeChip(chip)} sendingEmail={sendingEmail} />
        ))}
      </div> */}
      <ChipDropDown chips={chips} onDelete={removeChip} onBlur={handleBlur} disabled={disabled} />
      <input
        className={`${className}`}
        type="text"
        value={inputValue}
        onChange={handleChange}
        onKeyDown={handleInputKeyDown}
        ref={inputRef}
        disabled={disabled}
      />
      {filteredOptions.length === 0 ? null : (
        <div className={`autofill-options ${isOpen ? "open" : "closed"}`} ref={optionsRef}>
          {filteredOptions.map((option) => {
            return (
              <div
                key={`option-${option.label}-${option - value}`}
                className="autofill-option"
                onClick={() => addChip(option)}
                onKeyDown={(e) => handleOptionsKeyDown(e, option)}
                tabIndex={0}
              >
                <p>{option.label}</p>
                <p>{option.value}</p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default ChipInput;
