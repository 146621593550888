import { useState, useEffect, useCallback, useContext } from "react";
import axios from "axios";
import { ToastContext } from "../../Contexts/toastContext";
import PaymentMethod from "../PaymentMethod";
import "./style.scss";

function PaymentMethodList({ customer, handleAdd, getCustomer, isBusy, setIsBusy }) {
  const { addToast } = useContext(ToastContext);
  const [selectedCard, setSelectedCard] = useState(customer.cards.filter((card) => card.isDefault)[0]?.id);

  const handleDefault = useCallback(() => {
    if (customer.cards.filter((card) => card.isDefault)[0]?.id === selectedCard) return;
    setIsBusy(true);
    axios
      .put("/api/v1/admin/card", { cardId: selectedCard })
      .then((response) => {
        addToast({ type: "success", message: "Default Card Set" });
        getCustomer();
      })
      .catch((err) => {
        console.log(err);
        addToast({ type: "error", message: "Failed to set default card" });
      })
      .finally(() => {
        setIsBusy(false);
      });
  }, [selectedCard, customer.cards, addToast, getCustomer, setIsBusy]);

  const handleDelete = useCallback(() => {
    if (customer.cards.filter((card) => card.isDefault)[0]?.id === selectedCard) return addToast({ type: "error", message: "Cannot delete default card" });
    setIsBusy(true);
    axios
      .delete(`/api/v1/admin/card/${selectedCard}`)
      .then((response) => {
        addToast({ type: "success", message: "Card Deleted" });
        getCustomer();
      })
      .catch((err) => {
        console.log(err);
        addToast({ type: "error", message: "Failed to delete card" });
      })
      .finally(() => {
        setIsBusy(false);
      });
  }, [selectedCard, customer.cards, addToast, getCustomer, setIsBusy]);

  useEffect(() => {
    setSelectedCard(customer.cards.filter((card) => card.isDefault)[0]?.id);
  }, [customer.cards]);

  return (
    <div className="payment-method-section">
      <div className="payment-method-list">
        {customer.cards.map((card) => (
          <PaymentMethod key={card.id} card={card} isSelected={card.id === selectedCard} onSelect={setSelectedCard} />
        ))}
      </div>
      <div className="payment-method-buttons">
        <button className="payment-method-new-button" onClick={handleAdd} disabled={isBusy}>
          Add New Card
        </button>
        <button className="payment-method-default-button" onClick={handleDefault} disabled={isBusy}>
          Set As Default
        </button>
        <button className="payment-method-delete-button" onClick={handleDelete} disabled={isBusy}>
          Remove Card
        </button>
      </div>
    </div>
  );
}

export default PaymentMethodList;
