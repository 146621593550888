import { Routes, Route, Navigate } from "react-router-dom";
import PageNav from "../../Components/PageNav";
import CompanyGoals from "./SubPages/CompanyGoals";
import EmployeeRevenueGoals from "./SubPages/EmployeeRevenueGoals";
import EmployeeActivityGoals from "./SubPages/EmployeeActivityGoals";

function Goals() {
  return (
    <>
      <PageNav
        title="Goals"
        links={[
          { label: "Company Goals", href: "company" },
          { label: "Employee Bookings", href: "employee-bookings" },
          { label: "Employee Activities", href: "employee-activities" },
        ]}
      />
      <div className="page">
        <Routes>
          <Route path={`company`} element={<CompanyGoals />} />
          <Route path={`employee-bookings`} element={<EmployeeRevenueGoals />} />
          <Route path={`employee-activities`} element={<EmployeeActivityGoals />} />
          <Route index element={<Navigate to={`company`} />} />
        </Routes>
      </div>
    </>
  );
}

export default Goals;
