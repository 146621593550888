import { useState, useCallback } from "react";
import { minimumPasswordLength } from "../../Utilities/constants";
import { isValidPassword } from "../../Utilities/validateInput";

function ChangePassword({ onSubmit, disabled }) {
  const [errors, setErrors] = useState({ newPassword: "", confirmPassword: "" });
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleClick = useCallback(() => {
    if (password === confirmPassword && isValidPassword(password)) {
      return onSubmit(password);
    }
    let newPasswordError = "";
    let confirmPasswordError = "";
    if (!isValidPassword(password)) {
      newPasswordError = `Passwords must be at least ${minimumPasswordLength} characters long`;
    }
    if (password !== confirmPassword) {
      confirmPasswordError = `Password and confirm password must match`;
    }
    setErrors({ newPassword: newPasswordError, confirmPassword: confirmPasswordError });
  }, [password, confirmPassword, onSubmit]);

  let passwordTip = null;
  if (errors.newPassword) {
    passwordTip = (
      <ul className="password-tip">
        <li>Must be at least 7 characters long</li>
        <li>Must contain both UPPERCASE & lowercase letters</li>
        <li>Must contain numbers</li>
        <li>Must contain special characters</li>
      </ul>
    );
  }

  return (
    <div>
      <label>Enter new password</label>
      {passwordTip}
      <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} disabled={disabled} />
      <label>Confirm password</label>
      <p className="error-message">{errors.confirmPassword}</p>
      <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} disabled={disabled} />
      <button className="submit-button" onClick={handleClick} disabled={disabled}>
        Update Password
      </button>
    </div>
  );
}

export default ChangePassword;
