import { useMemo, useCallback } from "react";
import GraphTable from "../../../../Components/GraphTable";
import useNewDataFetcher from "../../../../Hooks/newUseDataFetcher";

function ByGoals() {
  const dataURL = "/api/v1/activity/sales/goals"
  const { data, dateRange, filterTypes, filters, isReady } = useNewDataFetcher({
    endPoint: dataURL,
  });

  const percentageDomain = useCallback((variable) => (data) => [0, 100], []);
  const ranges = useMemo(() => {
    if (!data || !data.table.columns) return undefined;
    const gutter = 150;
    const rightGap = 150;
    let filtered = data.table.columns.filter((col) => !(col.exclude && col.exclude !== "table"));
    let range = filtered.reduce((acc, cur, idx) => {
      if (cur.isMain) return acc;
      acc[cur.fieldName] = (min, max) => {
        let inner = max - min - (filtered.length - 2) * gutter;
        let size = inner / (filtered.length - 1) - rightGap / filtered.length;
        let minimum = min + size * (idx - 1) + (idx - 1) * gutter;
        let maximum = minimum + size;
        return [minimum, maximum];
      };
      return acc;
    }, {});
    return range;
  }, [data]);

  return (
    <GraphTable
      data={data}
      dateRange={dateRange}
      interval={dateRange.interval}
      filterTypes={filterTypes}
      filters={filters}
      isReady={isReady}
      domains={{ percentageDomain }}
      ranges={ranges}
      legend={false}
      dataURL={dataURL}
    >
      <h1 className="page-heading">How are we performing versus activity goals?</h1>
      <h2 className="page-subheading">
        <span className="page-data"></span> average team progress towards goals
      </h2>
    </GraphTable>
  );
}

export default ByGoals;
