import "./style.scss";

function FieldnameList({ listHeading, fieldnameSet, handleColumnSelect, disabled }) {
  return (
    <div className={`list-section ${listHeading === "Included" ? "included-list" : "excluded-list"}`}>
      <div className="fieldname-list-heading">{listHeading}</div>
      {fieldnameSet
        ? fieldnameSet.map((column) => {
            return (
              <button
                key={`fieldList-${listHeading}-${column.fieldName}`}
                className="fieldname-list-item"
                onClick={() => handleColumnSelect(column)}
                disabled={disabled}
              >
                {column.label}
              </button>
            );
          })
        : null}
    </div>
  );
}

export default FieldnameList;
