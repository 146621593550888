import { useMemo } from "react";
import useNewDataFetcher from "../../../../Hooks/newUseDataFetcher";
import GraphTable from "../../../../Components/GraphTable";
import SubheaderTimePeriod from "../../../../Components/SubheaderTimePeriod";

function OverTime() {
  const dataURL = "/api/v1/activity/recruiting"
  const { data, dateRange, filterTypes, filters, isReady } = useNewDataFetcher({
    endPoint: dataURL,
  });

  const total = useMemo(() => {
    if (!data) return 0;
    let sum = 0;
    for (let key in data.data) {
      sum += Object.values(data.data[key]).reduce((acc, cur) => (typeof cur === "string" ? acc : acc + cur), 0);
    }

    return sum.toLocaleString();
  }, [data]);

  return (
    <GraphTable data={data} dateRange={dateRange} interval={dateRange.interval} filterTypes={filterTypes} filters={filters} isReady={isReady} dataURL={dataURL}>
      <h1 className="page-heading">How much are we working?</h1>
      <h2 className="page-subheading">
        <span className="page-data">{total}</span> total activities <SubheaderTimePeriod data={dateRange} />
      </h2>
    </GraphTable>
  );
}

export default OverTime;
