import useNewDataFetcher from "../../../../Hooks/newUseDataFetcher";
import GraphTable from "../../../../Components/GraphTable";

function OverTime() {
  const dataURL = "/api/v1/activity/recruiting-ratios"
  const { data, dateRange, filterTypes, filters, isReady } = useNewDataFetcher({
    endPoint: dataURL,
  });

  return (
    <GraphTable data={data} dateRange={dateRange} interval={dateRange.interval} filterTypes={filterTypes} filters={filters} isReady={isReady} dataURL={dataURL}>
      <h1 className="page-heading">What is the efficiency of our recent activity?</h1>
      <h2 className="page-subheading">Activity ratios using activity date</h2>
    </GraphTable>
  );
}

export default OverTime;
