import { createContext, useState, useEffect, useContext, useCallback } from "react";
import axios from "axios";
import { ToastContext } from "./toastContext";
import { AuthContext } from "./authContext";

const DashboardContext = createContext();

function DashboardProvider({ children }) {
  const [dashboards, setDashboards] = useState([]);
  const { addToast } = useContext(ToastContext);
  const { user, hasChecked } = useContext(AuthContext);

  const getDashboards = useCallback(() => {
    axios
      .get("/api/v1/dashboard")
      .then((res) => {
        if (!res.data) return;
        setDashboards(res.data);
      })
      .catch((err) => {
        console.log(err);
        addToast({ type: "error", message: "Failed to get dashboards" });
      });
  }, [addToast]);

  useEffect(() => {
    if (!hasChecked || !user) return;
    const CancelToken = axios.CancelToken;
    let cancel;

    axios
      .get("/api/v1/dashboard", {
        cancelToken: new CancelToken((c) => (cancel = c)),
      })
      .then((res) => {
        if (!res.data || !res.data.length) return;
        setDashboards(res.data);
      })
      .catch((err) => {
        console.log(err);
        addToast({ type: "error", message: "Failed to get dashboards" });
      });
    return () => cancel();
  }, [user, hasChecked, addToast]);

  const updateDashboardCard = useCallback(
    (dashboardId, dashboardCardId, newCardWidth, newCardHeight, newCardPosition, oldCardPosition) => {
      const dashboardIndex = dashboards.findIndex((obj) => obj.id === dashboardId);
      const cardIndex = dashboards[dashboardIndex].cards.findIndex((obj) => obj.id === dashboardCardId);
      let newDashboards = [...dashboards];

      if (newCardWidth) {
        newDashboards[dashboardIndex].cards[cardIndex].width = newCardWidth;
      }
      if (newCardHeight) {
        newDashboards[dashboardIndex].cards[cardIndex].height = newCardHeight;
      }
      if (newCardPosition) {
        if (oldCardPosition > newCardPosition) {
          for (let i = 0; i < newDashboards[dashboardIndex].cards.length; i++) {
            if (
              newDashboards[dashboardIndex].cards[i].positionIndex < oldCardPosition &&
              newDashboards[dashboardIndex].cards[i].positionIndex >= newCardPosition
            ) {
              newDashboards[dashboardIndex].cards[i].positionIndex = newDashboards[dashboardIndex].cards[i].positionIndex + 1;
            }
          }
        }
        if (oldCardPosition < newCardPosition) {
          for (let i = 0; i < newDashboards[dashboardIndex].cards.length; i++) {
            if (
              newDashboards[dashboardIndex].cards[i].positionIndex > oldCardPosition &&
              newDashboards[dashboardIndex].cards[i].positionIndex <= newCardPosition
            ) {
              newDashboards[dashboardIndex].cards[i].positionIndex = newDashboards[dashboardIndex].cards[i].positionIndex - 1;
            }
          }
        }
        newDashboards[dashboardIndex].cards[cardIndex].positionIndex = parseInt(newCardPosition);
        newDashboards[dashboardIndex].cards = newDashboards[dashboardIndex].cards.sort((a, b) => (a.positionIndex > b.positionIndex ? 1 : -1));
      }
      setDashboards(newDashboards);
    },
    [dashboards]
  );

  const deleteDashboardCard = useCallback((dashboardId, cardId) => {
    setDashboards((prevDashboards) => {
      let dashboardIndex = prevDashboards.findIndex((dashboard) => dashboard.id === dashboardId);
      let dashboard = prevDashboards[dashboardIndex];
      let cardIndex = dashboard.cards.findIndex((card) => card.id === cardId);

      let newCards = dashboard.cards.slice(0, cardIndex).concat(
        dashboard.cards.slice(cardIndex + 1).map((card) => {
          return { ...card, positionIndex: card.positionIndex - 1 };
        })
      );
      let newDashboards = prevDashboards.slice(0, dashboardIndex).concat([{ ...dashboard, cards: newCards }, ...prevDashboards.slice(dashboardIndex + 1)]);

      return newDashboards;
    });
  }, []);

  const deleteDashboard = useCallback((dashboardId) => {
    setDashboards((prevDashboards) => prevDashboards.filter((dashboard) => dashboard.id.toString() !== dashboardId.toString()));
  }, []);

  return (
    <DashboardContext.Provider value={{ dashboards, updateDashboardCard, deleteDashboardCard, deleteDashboard, setDashboards, getDashboards }}>
      {children}
    </DashboardContext.Provider>
  );
}

export { DashboardContext, DashboardProvider };
