import { useState, useContext } from "react";
import axios from "axios";
import ManageTagsCard from "../ManageTagsCard";
import { ToastContext } from "../../Contexts/toastContext";
import "./style.scss";

function ManageTagsModal({ closeModal, tagsData, handleSetData }) {
  const { addToast } = useContext(ToastContext);

  tagsData = tagsData.reduce((acc, cur) => {
    acc[cur.id] = { id: cur.id, tagName: cur.tag_name, isDeleted: false, isHovering: false, isFocused: false };
    return acc;
  }, {});

  const [tags, setTags] = useState(
    tagsData
    // tagsData.reduce((acc, cur) => {
    //   acc[cur.id] = { id: cur.id, tagName: cur.tag_name, isDeleted: false, isHovering: false, isFocused: false };
    //   return acc;
    // }, {})
  );

  let savedTags = [];
  let deletedTags = [];
  let updatedTags = [];

  const handleChange = (e, id) => {
    setTags((prevTags) => {
      return { ...prevTags, [id]: { ...prevTags[id], tagName: e.target.value } };
    });
  };

  function onHover(id, bool) {
    setTags((prevTags) => {
      return { ...prevTags, [id]: { ...prevTags[id], isHovering: bool } };
    });
  }

  function focused(id, bool) {
    setTags((prevTags) => {
      return { ...prevTags, [id]: { ...prevTags[id], isFocused: bool } };
    });
  }

  function onDelete(id) {
    setTags((prevTags) => {
      return { ...prevTags, [id]: { ...prevTags[id], isDeleted: true } };
    });
  }

  function onAddTag() {
    setTags((prevTags) => {
      return { ...prevTags, [-Date.now()]: { id: -Date.now(), tagName: "", isDeleted: false, isHovering: false } };
    });
  }

  function saveTags() {
    Object.values(tags).filter((tag) => {
      if (tag.id < 0 && tag.tagName && !tag.isDeleted) {
        savedTags.push(tag.tagName);
      } else if (tag.id > 0 && tag.isDeleted) {
        deletedTags.push(tag.id);
      }
      return null;
    });

    for (let i = 0; i < Object.values(tagsData).length; i++) {
      for (let j = 0; j < Object.values(tags).length; j++) {
        if (Object.values(tagsData)[i].id === Object.values(tags)[j].id && Object.values(tagsData)[i].tagName !== Object.values(tags)[j].tagName) {
          updatedTags.push(Object.values(tags)[j]);
        }
      }
    }
    if (savedTags[0]) {
      axios
        .post("/api/v1/admin/filters/save-tags", savedTags)
        .then((res) => {
          handleSetData();
          addToast({ type: "success", message: "Tags Updated" });
        })
        .catch((err) => {
          console.log(err);
          addToast({ type: "error", message: "Unable to Update Tags" });
        });
    }
    if (deletedTags[0]) {
      axios
        .post("/api/v1/admin/filters/delete-tags", deletedTags)
        .then((res) => {
          handleSetData();
          addToast({ type: "success", message: "Tags Updated" });
        })
        .catch((err) => {
          console.log(err);
          addToast({ type: "error", message: "Unable to Update Tags" });
        });
    }
    if (updatedTags[0]) {
      axios
        .post("/api/v1/admin/filters/update-tags", updatedTags)
        .then((res) => {
          handleSetData();
          addToast({ type: "success", message: "Tags Updated" });
        })
        .catch((err) => {
          console.log(err);
          addToast({ type: "error", message: "Unable to Update Tags" });
        });
    }

    closeModal();
  }

  return (
    <div className="manage-tags-modal">
      <div className="manage-tags-header">
        Manage Tags
        <div className="manage-tags-exit" onClick={closeModal}>
          x
        </div>
      </div>
      <div className="manage-tags-main">
        <div className="manage-tags-addtag" onClick={() => onAddTag()}>
          + Add Tag
        </div>
        <div className="tag-card-area">
          {Object.values(tags)
            .sort((a, b) => a.id - b.id)
            .map((item) => {
              if (!item.isDeleted) {
                return <ManageTagsCard key={item.id} tag={item} onDelete={onDelete} onHover={onHover} focused={focused} handleChange={handleChange} />;
              } else {
                return null;
              }
            })}
        </div>
      </div>
      <div className="manage-tags-footer">
        <button className="manage-tags-cancel" onClick={closeModal}>
          Cancel
        </button>
        <button className="manage-tags-save" onClick={saveTags}>
          Save
        </button>
      </div>
    </div>
  );
}

export default ManageTagsModal;
