import { DateTime } from "luxon";
import { useCallback, useEffect, useState } from "react";
import "./style.scss";

function RangeSelector({ dateRanges, onDateChange, onCurrentChange, onExit, current, includeInterval, label }) {
  let yearArray = [];
  const [disableCurrent, setDisableCurrent] = useState(false);
  const [quarterYear, setQuarterYear] = useState(DateTime.now().year);
  const [customRangeError, setCustomRangeError] = useState(false);
  const [customStartDate, setCustomStartDate] = useState();
  const [customStartDateString, setCustomStartDateString] = useState();
  const [customEndDate, setCustomEndDate] = useState();
  const [customEndDateString, setCustomEndDateString] = useState();

  useEffect(() => {
    if (label.includes("-") || /Q[0-9]/.test(label)) {
      setDisableCurrent(true);
    }
  }, [label]);

  for (let i = DateTime.now().year; i > 2011; i--) {
    yearArray.push(i);
  }

  const changeCustomStartDate = useCallback((e) => {
    setCustomStartDate(DateTime.fromFormat(e.target.value, "yyyy-MM-dd").toMillis());
    setCustomStartDateString(DateTime.fromFormat(e.target.value, "yyyy-MM-dd").toFormat("MM/dd/yyyy"));
  }, []);

  const changeCustomEndDate = useCallback((e) => {
    setCustomEndDate(DateTime.fromFormat(e.target.value, "yyyy-MM-dd").endOf("day").toMillis());
    setCustomEndDateString(DateTime.fromFormat(e.target.value, "yyyy-MM-dd").toFormat("MM/dd/yyyy"));
  }, []);

  const updateDateRange = useCallback(() => {
    if (!customStartDate) {
      setCustomRangeError("Missing Start Date");
    } else if (!customEndDate) {
      setCustomRangeError("Missing End Date");
    } else if (customStartDate > customEndDate) {
      setCustomRangeError("End Date Predates Start Date");
    } else {
      setCustomRangeError(false);
      if (current) {
        onCurrentChange();
      }
      let dateRangeString = customStartDateString + "-" + customEndDateString;
      let customInterval;

      switch (true) {
        case DateTime.fromMillis(customEndDate).diff(DateTime.fromMillis(customStartDate), "days").toObject().days < 14:
          customInterval = "Day";
          break;
        case DateTime.fromMillis(customEndDate).diff(DateTime.fromMillis(customStartDate), "weeks").toObject().weeks < 12:
          customInterval = "Week";
          break;
        case DateTime.fromMillis(customEndDate).diff(DateTime.fromMillis(customStartDate), "months").toObject().months < 12:
          customInterval = "Month";
          break;
        case DateTime.fromMillis(customEndDate).diff(DateTime.fromMillis(customStartDate), "quarters").toObject().quarters < 8:
          customInterval = "Quarter";
          break;
        case DateTime.fromMillis(customEndDate).diff(DateTime.fromMillis(customStartDate), "quarters").toObject().quarters >= 8:
          customInterval = "Year";
          break;
        default:
          customInterval = "Week";
          break;
      }

      let item = { label: dateRangeString, interval: customInterval, startDate: customStartDate, endDate: customEndDate };
      let category = "";

      onDateChange(item, category);
    }
  }, [current, customEndDate, customEndDateString, customStartDate, customStartDateString, onCurrentChange, onDateChange]);

  const changeQuarterYear = useCallback((e) => {
    setQuarterYear(parseInt(e.target.value));
  }, []);

  const handleQuarterClick = useCallback(
    (quarter) => {
      if (current) {
        onCurrentChange();
      }
      let qStart;
      let qEnd;
      switch (quarter) {
        case "Q1":
          qStart = DateTime.local(quarterYear, 1, 1).startOf("quarter").toMillis();
          qEnd = DateTime.local(quarterYear, 1, 1).endOf("quarter").toMillis();
          break;
        case "Q2":
          qStart = DateTime.local(quarterYear, 4, 1).startOf("quarter").toMillis();
          qEnd = DateTime.local(quarterYear, 4, 1).endOf("quarter").toMillis();
          break;
        case "Q3":
          qStart = DateTime.local(quarterYear, 7, 1).startOf("quarter").toMillis();
          qEnd = DateTime.local(quarterYear, 7, 1).endOf("quarter").toMillis();
          break;
        case "Q4":
          qStart = DateTime.local(quarterYear, 10, 1).startOf("quarter").toMillis();
          qEnd = DateTime.local(quarterYear, 10, 1).endOf("quarter").toMillis();
          break;
        default:
          break;
      }
      let item = { label: quarterYear, interval: "Week", startDate: parseInt(qStart), endDate: parseInt(qEnd) };
      onDateChange(item, quarter);
    },
    [current, onCurrentChange, onDateChange, quarterYear]
  );

  return (
    <div className="date-range-selector">
      <div className="header-section">
        <h1 className="selector-header">Date Range</h1>
        <div className={disableCurrent ? "current-wrapper current-disabled" : "current-wrapper"}>
          <span>Current: </span>
          <input type="checkbox" checked={!!current} onChange={onCurrentChange} disabled={disableCurrent} />
        </div>
        <button className="selector-exit" onClick={onExit}></button>
      </div>
      <div className="date-range-section">
        <div className="date-range-wrapper">
          {(() => {
            let categories = dateRanges.map((item) => (
              <p key={`date-range-heading-${item.category}`} className="date-range-heading">
                {item.category}
              </p>
            ));
            for (let i = 0; i < dateRanges[0].options.length; i++) {
              for (let j = 0; j < dateRanges.length; j++) {
                let category = dateRanges[j].category;
                let item = dateRanges[j].options[i];
                categories.push(
                  <p
                    key={`${item.startDate().toISODate()}-${item.endDate().toISODate()}-${i}`}
                    className="date-range-link"
                    onClick={() => {
                      onDateChange(item, category);
                    }}
                  >
                    {item.label}
                  </p>
                );
              }
            }
            return categories;
          })()}
        </div>
        <div className="quarter-section">
          <div className="quarter-heading">
            <span className="date-range-heading">Quarter</span>
            <select value={quarterYear} onChange={changeQuarterYear}>
              {yearArray.map((year) => {
                return (
                  <option value={year} key={year + year}>
                    {year}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="date-range-wrapper">
            <span className="date-range-link" onClick={() => handleQuarterClick("Q1")}>
              Q1
            </span>
            <span className="date-range-link" onClick={() => handleQuarterClick("Q2")}>
              Q2
            </span>
            <span className="date-range-link" onClick={() => handleQuarterClick("Q3")}>
              Q3
            </span>
            <span className="date-range-link" onClick={() => handleQuarterClick("Q4")}>
              Q4
            </span>
          </div>
        </div>
      </div>
      <p className="custom-date-range-heading">Custom Date Range</p>
      <div className={customRangeError ? "range-error" : "no-range-error"}>*{customRangeError}</div>
      <input className="custom-date-picker" type="date" onChange={changeCustomStartDate}></input>
      <span> to </span>
      <input className="custom-date-picker" type="date" onChange={changeCustomEndDate}></input>
      <button className="custom-date-button" onClick={updateDateRange}>
        Update Range
      </button>
    </div>
  );
}

export default RangeSelector;
