import { Navigate, useLocation } from "react-router-dom";
import useAuth from "../../Hooks/useAuth";
import { roles } from "../../Utilities/constants";

function RequireAuthRoute({ role, licensed, children }) {
  const { user, hasChecked } = useAuth();
  const location = useLocation();

  let from = location.state?.from?.pathname + location.state?.from?.search || "/";

  if (!hasChecked) return <h1>Loading...</h1>;

  if (!role || (role === "none" && !user)) return children;

  if (!user) return <Navigate to="/login" state={{ from: location }} replace />;

  // @Uncomment: Uncommenting this makes routes with the licensed indicator require a license to access
  // if (licensed && !user.licenseId && user.role !== "dev") return <Navigate to="/license-needed" state={{ from: location }} replace />;

  if (role === "none" || roles.indexOf(user.role) < roles.indexOf(role)) return <Navigate to={from} replace />;

  return children;
}

export default RequireAuthRoute;
