import { useContext, useMemo } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { AuthContext } from "../../Contexts/authContext";
import Accounts from "./SubPages/Accounts";
import StaffingLicenses from "./SubPages/StaffingLicenses";
import PageNav from "../../Components/PageNav";
import RequireAuthRoute from "../../Components/RequireAuthRoute";
import PaymentInfo from "./SubPages/PaymentInfo";
import TransactionHistory from "./SubPages/TransactionHistory";
import "./style.scss";

function Licensing() {
  const { user } = useContext(AuthContext);

  const links = useMemo(() => {
    if (user.role === "dev") {
      return [
        { label: "Companies", href: "accounts" },
        { label: "Staffing Licenses", href: "staffing-licenses" },
        { label: "Payment Info", href: "payment-info" },
        { label: "Transaction History", href: "transaction-history" },
      ];
    } else {
      return [
        { label: "Staffing Licenses", href: "staffing-licenses" },
        { label: "Payment Info", href: "payment-info" },
        { label: "Transaction History", href: "transaction-history" },
      ];
    }
  }, [user.role]);

  return (
    <>
      <PageNav title="Accounts" links={links} />
      <div className="page">
        <Routes>
          <Route
            path={`accounts`}
            element={
              <RequireAuthRoute role="dev" licensed>
                <Accounts />
              </RequireAuthRoute>
            }
          />
          <Route
            path={`payment-info`}
            element={
              <RequireAuthRoute role="admin" licensed>
                <PaymentInfo />
              </RequireAuthRoute>
            }
          />
          <Route
            path={`transaction-history`}
            element={
              <RequireAuthRoute role="admin" licensed>
                <TransactionHistory />
              </RequireAuthRoute>
            }
          />
          <Route
            path={`staffing-licenses`}
            element={
              <RequireAuthRoute role="admin" licensed>
                <StaffingLicenses />
              </RequireAuthRoute>
            }
          />
          <Route index element={<Navigate to={`staffing-licenses`} />} />
        </Routes>
      </div>
    </>
  );
}

export default Licensing;
