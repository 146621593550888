import { useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../Contexts/authContext";
import "./style.scss";

const UserDropdown = (props) => {
  const { logout, userDropdownOpen, closeUserDropdown } = useContext(AuthContext);
  const navigate = useNavigate();
  const ref = useRef(null);

  useEffect(() => {
    ref.current.focus();
  }, [userDropdownOpen]);

  return (
    <div className={`userDropdownContainer ${!userDropdownOpen ? "userDropdownClosed" : ""}`} onBlur={closeUserDropdown}>
      {/* <button className="userDropdownOption">{props.notifications} Notifications</button>
      <br /> */}
      <button
        ref={ref}
        className="userDropdownOption"
        onClick={() => {
          closeUserDropdown();
          logout(
            () => navigate("/login"),
            () => {}
          );
        }}
      >
        Log Out
      </button>
    </div>
  );
};

export default UserDropdown;
