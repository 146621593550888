import { useCallback, useContext } from "react";
import { AuthContext } from "../../../../Contexts/authContext";
import "./style.scss";

function Accounts() {
  const { user, companies, changeCompany } = useContext(AuthContext);

  const handleAccountSelection = useCallback(
    (e) => {
      let company = companies.find((company) => company.id.toString() === e.target.value);
      if (!company) return;
      changeCompany(
        { companyId: company.id, companyName: company.name },
        () => {},
        () => console.log("Failed to change company")
      );
    },
    [companies, changeCompany]
  );

  return (
    <>
      <div className="accounts-wrapper">
        <h1>Company Account Selection</h1>
        <table className="license-table">
          <thead>
            <tr>
              <th className="account-table-heading">Companies</th>
              <th className="account-table-heading">Selection</th>
            </tr>
          </thead>
          <tbody>
            {companies.map((company) => {
              return (
                <tr key={"license-account-" + company.name + company.id}>
                  <td>{company.name}</td>
                  <td>
                    {user.companyId === company.id ? (
                      "Selected"
                    ) : (
                      <button value={company.id} onClick={handleAccountSelection}>
                        Select
                      </button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default Accounts;
