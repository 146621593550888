import { useState, useRef } from "react";
import "./style.scss";

function EmployeeTagCard({ id, name, extId, assignedTags, employeeSelected, setEmployeeSelected, handleChange, newTag, tagsData, assignTag, removeTag }) {
  const [addTagSelected, setAddTagSelected] = useState(false);
  const addTagRef = useRef();

  return (
    <tr className={employeeSelected === id ? "employee-tag-table-row-selected" : "employee-tag-table-row"}>
      <td className="employee-tag-table-name">{name}</td>
      <td className="employee-tag-table-extId">{extId}</td>
      <td className="employee-tag-table-item">
        <div
          className={employeeSelected === id ? "selected-employee" : "not-selected"}
          ref={addTagRef}
          onBlur={(e) => {
            if (!addTagRef.current.contains(e.relatedTarget)) {
              setAddTagSelected(false);
            }
          }}
        >
          <div className="assigned-tags-display">
            {assignedTags.map((item, index) => {
              if (item) {
                return (
                  <span
                    key={index}
                    className={employeeSelected === id ? "employee-tag-table-item-tags-clickable" : "employee-tag-table-item-tags"}
                    onClick={() => {
                      if (employeeSelected === id) {
                        removeTag(id, item);
                      }
                    }}
                  >
                    {employeeSelected === id ? item + " x" : item}
                  </span>
                );
              } else {
                return null;
              }
            })}
          </div>
          <input
            className={employeeSelected === id ? "selected-employee-input" : "not-selected-input"}
            placeholder="+ Add"
            value={newTag}
            onClick={() => {
              setAddTagSelected(true);
            }}
            onChange={(e) => handleChange(e)}
          ></input>
          <div className={addTagSelected ? "addTagSelected" : "addTagUnselected"}>
            {tagsData
              .filter((tag) => {
                if (tag.tag_name.toLowerCase().includes(newTag.toLowerCase())) {
                  return tag;
                } else {
                  return null;
                }
              })
              .map((item, index) => {
                return (
                  <div
                    key={index}
                    className="dropdownTag"
                    onClick={() => {
                      let match = false;
                      for (let i = 0; i < assignedTags.length; i++) {
                        if (assignedTags[i] === item.tag_name) {
                          match = true;
                        }
                      }
                      if (!match) {
                        assignTag(id, item.id);
                      }
                      setAddTagSelected(false);
                    }}
                    tabIndex={0}
                  >
                    {item.tag_name}
                  </div>
                );
              })}
          </div>
        </div>
        <button
          className="edit-tags-button"
          onClick={() => {
            setEmployeeSelected(id);
          }}
        >
          Edit Tags
        </button>
      </td>
    </tr>
  );
}

export default EmployeeTagCard;
