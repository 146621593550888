import { Routes, Route, Navigate } from "react-router-dom";
import PageNav from "../../Components/PageNav";
import ByCreationDate from "./SubPages/ByCreationDate";
import ByEmployeeStage from "./SubPages/ByEmployeeStage";
import ByEmployeeMomentum from "./SubPages/ByEmployeeMomentum";

function OpenJobs() {
  return (
    <>
      <PageNav
        title="Open Jobs"
        links={[
          { label: "By Creation Date", href: "by-creation-date" },
          { label: "By Employee (Stage)", href: "by-employee-stage" },
          { label: "By Employee (Momentum)", href: "by-employee-momentum" },
        ]}
      />
      <div className="page">
        <Routes>
          <Route path={`by-creation-date`} element={<ByCreationDate />} />
          <Route path={`by-employee-stage`} element={<ByEmployeeStage />} />
          <Route path={`by-employee-momentum`} element={<ByEmployeeMomentum />} />
          <Route index element={<Navigate to={`by-creation-date`} />} />
        </Routes>
      </div>
    </>
  );
}

export default OpenJobs;
