import { useCallback, useMemo } from "react";
import RequireAuthComponent from "../RequireAuthComponent";
import "./style.scss";

function PinNewDashboardSection({ selectedDashboard, newDashboardName, setNewDashboardName, accessTags, setAccessTags, roles, tags, users, disabled }) {
  const handleNewDashboardName = useCallback(
    (e) => {
      setNewDashboardName(e.target.value);
    },
    [setNewDashboardName]
  );

  const handleDashboardRoleChange = useCallback(
    (e) => {
      if (!e.target.value) return;
      let role = roles.find((role) => role.id.toString() === e.target.value);
      setAccessTags((prevAccessTags) => {
        let exists = prevAccessTags.find(tag => tag.type === 'role' && tag.id === role.id);
        if (exists) return prevAccessTags;
        return [...prevAccessTags, { type: "role", id: role.id, label: role.name }]
      });
    },
    [roles, setAccessTags]
  );

  const handleDashboardTagChange = useCallback(
    (e) => {
      if (!e.target.value) return;
      let tag = tags.find((tag) => tag.id.toString() === e.target.value);
      setAccessTags((prevAccessTags) => {
        let exists = prevAccessTags.find(existingTag => existingTag.type === 'tag' && existingTag.id === tag.id);
        if (exists) return prevAccessTags;
        return [...prevAccessTags, { type: "tag", id: tag.id, label: tag.tag_name }]
      });
    },
    [tags, setAccessTags]
  );

  const handleDashboardUserChange = useCallback(
    (e) => {
      if (!e.target.value) return;
      let user = users.find((user) => user.id.toString() === e.target.value);
      setAccessTags((prevAccessTags) => {
        let exists = prevAccessTags.find(tag => tag.type === "user" && tag.id === user.id);
        if (exists) return prevAccessTags;
        return [...prevAccessTags, { type: "user", id: user.id, label: user.label }]
      });
    },
    [users, setAccessTags]
  );

  const handleAccessTagItem = useCallback(
    (e) => {
      setAccessTags((prevAccessTags) => {
        let tempArray = [...prevAccessTags];
        let tempObj = JSON.parse(e.target.getAttribute("value"));
        let removeObj = { type: tempObj["type"], id: tempObj["id"], label: tempObj["label"] };
        tempArray = tempArray.filter((obj) => {
          if (obj.type !== removeObj.type || obj.id.toString() !== removeObj.id || obj.label !== removeObj.label) {
            return obj;
          }
          return false;
        });
        return tempArray;
      });
    },
    [setAccessTags]
  );

  const filteredRoles = useMemo(() => {
    return roles.filter(role => {
      return !accessTags.find(tag => tag.type === "role" && tag.id === role.id);
    });
  }, [roles, accessTags]);
  
  const filteredTags = useMemo(() => {
    return tags.filter(tag => {
      return !accessTags.find(existingTag => existingTag.type === "tag" && existingTag.id === tag.id);
    });
  }, [tags, accessTags]);

  const filteredUsers = useMemo(() => {
    return users.filter(user => {
      return !accessTags.find(tag => tag.type === "user" && tag.id === user.id);
    });
  }, [users, accessTags]);

  return (
    <div className={selectedDashboard === "New Dashboard" ? "display-new-dashboard-section" : "hide-new-dashboard-section"}>
      <div className="dashboard-name-new">
        <label>Dashboard Name</label>
        <input className="dashboard-name-input" value={newDashboardName} onChange={handleNewDashboardName} disabled={disabled} />
      </div>
      <RequireAuthComponent role="admin">
        <div className="dashboard-access-section">
          <div className="dashboard-access-label">
            <span>Access: </span>
            <div className="access-tags-wrapper">
              {accessTags.map((accessTag) => {
                return (
                  <button
                    className="dashboard-access-item"
                    onClick={handleAccessTagItem}
                    value={`{"type": "${accessTag.type}", "id": "${accessTag.id}", "label": "${accessTag.label}"}`}
                    key={`accessTagList${accessTag.type}${accessTag.id}`}
                    disabled={disabled}
                  >
                    {accessTag.label}
                  </button>
                );
              })}
            </div>
          </div>
        </div>
        <div>
          <div className="dashboard-access-options">
            <label>Roles</label>
            {roles ? (
              <select value={""} onChange={handleDashboardRoleChange} disabled={disabled}>
                <option value={""}>--Roles--</option>
                {filteredRoles.map((role) => {
                  return (
                    <option value={role.id} key={`role-option-${role.id}-${role.name}`}>
                      {role.name}
                    </option>
                  );
                })}
              </select>
            ) : null}
          </div>
          <div className="dashboard-access-options">
            <label>Tags</label>
            {tags ? (
              <select value={""} onChange={handleDashboardTagChange} disabled={disabled}>
                <option value={""}>--Tags--</option>
                {filteredTags.map((tag) => {
                  return (
                    <option value={tag.id} key={`tag-option-${tag.id}-${tag.tag_name}`}>
                      {tag.tag_name}
                    </option>
                  );
                })}
              </select>
            ) : null}
          </div>
          <div className="dashboard-access-options">
            <label>Users</label>
            {users ? (
              <select value={""} onChange={handleDashboardUserChange} disabled={disabled}>
                <option value={""}>--Users--</option>
                {filteredUsers.map((user) => {
                  return (
                    <option value={user.id} key={`user-option-${user.id}-${user.label}`}>
                      {user.label}
                    </option>
                  );
                })}
              </select>
            ) : null}
          </div>
        </div>
      </RequireAuthComponent>
    </div>
  );
}

export default PinNewDashboardSection;
