import { useState, useCallback } from "react";
import * as d3 from "d3";

function Lines({ data, scales, xScale = "x", yScale = "y", onEnter, onLeave, offset, dashed, dataSource }) {
  const [hovered, setHovered] = useState({ lineIdx: null, pointIdx: null });

  const line = useCallback(
    (data) => {
      const line = d3
        .line()
        .x((d) => {
          let x = (scales[xScale] ? scales[xScale](d[xScale]) : 0) + offset.x;
          return x;
        })
        .y((d) => {
          let y = (scales[yScale] ? scales[yScale](d[yScale]) : 0) + offset.y;
          return y;
        })
        .defined((d) => {
          let x = scales[xScale] ? scales[xScale](d[xScale]) : true;
          let y = scales[yScale] ? scales[yScale](d[yScale]) : true;
          return x && y;
        })
        .curve(d3.curveCatmullRom);

      return line(data);
    },
    [scales, xScale, yScale, offset.x, offset.y]
  );

  let tempPoint;

  return (
    <>
      {data.map((item, lIdx) => {
        let filtered = item.items.filter((point) => {
          let x = scales[xScale] ? scales[xScale](point[xScale]) || scales[xScale](point.uid) : 0;
          let y = scales[yScale] ? scales[yScale](point[yScale]) || scales[yScale](point.uid) : 0;

          if (x === undefined || y === undefined) return false;
          return true;
        });

        let hasLine = item.line === undefined ? true : item.line;
        let hasDots = item.dots === undefined ? true : item.dots;
        let isDashed = item.dashed;
        return (
          <g className="line-group" key={`line-group-${item.label}`}>
            {!hasLine ? null : (
              <path
                className="line"
                fill="none"
                stroke={item.color}
                strokeWidth={3}
                d={line ? line(filtered) : 0}
                strokeDasharray={dashed || isDashed ? "5,5" : 0}
              />
            )}
            {filtered.map((point, pIdx) => {
              let x, y;
              if (scales[xScale] && scales[yScale]) {
                x = scales[xScale](point[xScale]);
                y = scales[yScale](point[yScale]);
                if (x === undefined && point[xScale] === point.label) x = scales[xScale](point.uid);
                if (y === undefined && point[yScale] === point.label) y = scales[yScale](point.uid);
              }
              if (x === undefined || y === undefined || isNaN(x) || isNaN(y)) return null;
              x += offset.x;
              y += offset.y;
              if (hovered.lineIdx === lIdx && hovered.pointIdx === pIdx) {
                tempPoint = { item, point };
              }

              return (
                <use
                  className="point"
                  key={`point-${item.uid}-${point.uid}-${point[xScale]}-${point[yScale]}`}
                  href={`#${item.shape || "circle"}`}
                  fill={item.isVisible && hasDots ? item.color : "#00000000"}
                  strokeOpacity={item.isVisible && hasDots ? 1 : 0}
                  transform={`translate(${x}, ${y})`}
                  onMouseEnter={(e) => {
                    setHovered({ lineIdx: lIdx, pointIdx: pIdx });
                    return onEnter({ x, y, data: point.data, color: item.color });
                  }}
                />
              );
            })}
            {(() => {
              if (!tempPoint) return null;
              const { item, point } = tempPoint;
              let x = scales[xScale] ? scales[xScale](point[xScale]) || scales[xScale](point.uid) : 0;
              let y = scales[yScale] ? scales[yScale](point[yScale]) || scales[yScale](point.uid) : 0;
              if (x === undefined || y === undefined) return null;
              x += offset.x;
              y += offset.y;
              return (
                <use
                  className="point"
                  href={`#${item.shape || "circle"}`}
                  fill={item.isVisible ? item.color : "#00000000"}
                  transform={`translate(${x}, ${y}) scale(${2})`}
                  onMouseLeave={(e) => {
                    setHovered({ lineIdx: null, pointIdx: null });
                    return onLeave();
                  }}
                />
              );
            })()}
          </g>
        );
      })}
    </>
  );
}

export default Lines;
