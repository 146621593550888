import { useState, useCallback, useContext, useEffect, useMemo } from "react";
import { AuthContext } from "../../Contexts/authContext";
import { ToastContext } from "../../Contexts/toastContext";
import { DashboardContext } from "../../Contexts/dashboardContext";
import { EmailContext } from "../../Contexts/emailContext";
import { useLocation } from "react-router-dom";
import axios from "axios";
import GraphForm from "./GraphForm";
import TableForm from "./TableForm";
import TextForm from "./TextForm";
import PinNewDashboardSection from "../PinNewDashboardSection";
import Modal from "../Modal";
import "./style.scss";

function PinModal({ dateRange, apiData, columns, graphs }) {
  const { user } = useContext(AuthContext);
  const { addToast } = useContext(ToastContext);
  const { dashboards, setDashboards } = useContext(DashboardContext);
  const { emails: users, tags } = useContext(EmailContext);
  const [isOpen, setIsOpen] = useState(false);
  const [modifyConfirmationIsOpen, setModifyConfirmationIsOpen] = useState(false);
  const location = useLocation();
  const { card, dashboard } = location.state || {};

  const [newDashboardName, setNewDashboardName] = useState(`New Dashboard 1`);
  const [accessTags, setAccessTags] = useState(user.role === "user" ? [{ type: "user", id: user.id, label: `${user.firstName} ${user.lastName}` }] : []);
  const [isDisabled, setIsDisabled] = useState(false);

  const roles = useMemo(
    () => [
      { id: 1, name: "admin" },
      { id: 2, name: "user" },
    ],
    []
  );

  useEffect(() => {
    let numberColumns = [];
    let columnFieldnames = [];
    let unfilteredFieldnames = [];
    if (columns) {
      for (let i = 0; i < columns.columns.length; i++) {
        if (columns.columns[i].exclude === true) continue;
        unfilteredFieldnames.push(columns.columns[i]);
        if (!columns.columns[i].type || columns.columns[i].type === "number") {
          numberColumns.push(columns.columns[i]);
          columnFieldnames.push(columns.columns[i]);
        }
      }
    }
    setFinalColumnsTable(unfilteredFieldnames);
    setFinalColumnsGraph(numberColumns);
  }, [columns, apiData]);

  const locationName = useMemo(() => {
    let pathname = location.pathname;
    let locationNameArray = pathname.split("/");
    if (locationNameArray.length >= 4) {
      if (locationNameArray.length === 4) {
        locationNameArray = locationNameArray.slice(-2);
      } else {
        locationNameArray = locationNameArray.slice(-3);
      }
      pathname = locationNameArray.join(" ");
      pathname = pathname.replace(/-|\+/g, " ");
      locationNameArray = pathname.split(" ");
      for (let i = 0; i < locationNameArray.length; i++) {
        locationNameArray[i] = locationNameArray[i][0].toUpperCase() + locationNameArray[i].substring(1);
      }
      pathname = locationNameArray.join(" ");
    }
    return pathname;
  }, [location.pathname]);

  const [base, ...urlRest] = (location.pathname + location.search).split(/\?|&/gm).filter((val) => !val.includes("checked"));
  const url = `${base}${urlRest.length ? "?" : ""}${urlRest.join("&")}`;

  let filteredDashboards = useMemo(() => {
    return dashboards.filter((dashboard) => dashboard.canEdit);
  }, [dashboards]);

  const [selectedDashboard, setSelectedDashboard] = useState(dashboard?.id || filteredDashboards[0]?.id || "New Dashboard");
  const [cardHeading, setCardHeading] = useState(card?.name || locationName);
  const [pinRadio, setPinRadio] = useState("table");
  const [showLegend, setShowLegend] = useState(card?.showLegend === undefined ? true : !!card.showLegend);
  const [showTotals, setShowTotals] = useState(card?.showTotals === undefined ? true : !!card.showTotals);
  const [finalColumnsTable, setFinalColumnsTable] = useState();
  const [finalColumnsGraph, setFinalColumnsGraph] = useState();
  const [includedColumnsGraph, setIncludedColumnsGraph] = useState();
  const [excludedColumnsGraph, setExcludedColumnsGraph] = useState([]);
  const [includedColumnsTable, setIncludedColumnsTable] = useState();
  const [excludedColumnsTable, setExcludedColumnsTable] = useState([]);
  const [text, setText] = useState({ mainText: card?.mainText || "", subText: card?.subText || "", hoverText: card?.hoverText || "" });
  const [functions, setFunctions] = useState({ mainText: "", subText: "", hoverText: "" });

  const disableGraph = useMemo(() => {
    return location.pathname.includes("table");
  }, [location.pathname]);

  useEffect(() => {
    setIncludedColumnsGraph(finalColumnsGraph);
  }, [finalColumnsGraph]);

  useEffect(() => {
    setIncludedColumnsTable(finalColumnsTable);
  }, [finalColumnsTable]);

  const resetColumns = useCallback(() => {
    if (!finalColumnsGraph || !finalColumnsTable) return;
    
    let includedGraph = finalColumnsGraph;
    let excludedGraph = [];

    let includedTable = finalColumnsTable;
    let excludedTable = [];

    if (card) {
      if (card.type === "graph") {
        ({ includedGraph, excludedGraph } = includedGraph.reduce((acc, cur) => {
          if (card.params.categories?.includes(cur.fieldName)) acc.includedGraph.push(cur);
          else acc.excludedGraph.push(cur);
          return acc;
        }, { includedGraph: [], excludedGraph: [] }));
      } else if (card.type === "table") {
        ({ includedTable, excludedTable } = includedTable.reduce((acc, cur) => {
          if (card.columns.includes(cur.fieldName)) acc.includedTable.push(cur);
          else acc.excludedTable.push(cur);
          return acc;
        }, { includedTable: [], excludedTable: [] }));
      }
    }

    setIncludedColumnsGraph(includedGraph);
    setExcludedColumnsGraph(excludedGraph);

    setIncludedColumnsTable(includedTable);
    setExcludedColumnsTable(excludedTable);
  }, [card, finalColumnsGraph, finalColumnsTable]);

  useEffect(() => {
    resetColumns()
  }, [pinRadio, resetColumns]);

  const fieldnameList = useMemo(() => {
    if (pinRadio === "graph") return includedColumnsGraph;
    if (pinRadio === "table") return includedColumnsTable;
    return [];
  }, [pinRadio, includedColumnsGraph, includedColumnsTable]);

  useEffect(() => {
    if (!graphs || !graphs.length) return;
    setPinRadio(card?.type || "graph");
  }, [graphs, card]);

  const handleColumnSelectGraph = useCallback(
    (column) => {
      let selectedColumns = [...includedColumnsGraph];
      let unselectedColumns = [...excludedColumnsGraph];
      let index = selectedColumns.findIndex((col) => col.fieldName === column.fieldName);
      if (index === -1) {
        index = unselectedColumns.findIndex((col) => col.fieldName === column.fieldName);
        selectedColumns.push(column);
        unselectedColumns.splice(index, 1);
      } else {
        selectedColumns.splice(index, 1);
        unselectedColumns.push(column);
      }
      setIncludedColumnsGraph(selectedColumns);
      setExcludedColumnsGraph(unselectedColumns);
    },
    [includedColumnsGraph, excludedColumnsGraph]
  );

  const handleColumnSelectTable = useCallback(
    (column) => {
      let selectedColumns = [...includedColumnsTable];
      let unselectedColumns = [...excludedColumnsTable];
      let index = selectedColumns.findIndex((col) => col.fieldName === column.fieldName);
      if (index === -1) {
        index = unselectedColumns.findIndex((col) => col.fieldName === column.fieldName);
        selectedColumns.push(column);
        unselectedColumns.splice(index, 1);
      } else {
        selectedColumns.splice(index, 1);
        unselectedColumns.push(column);
      }
      setIncludedColumnsTable(selectedColumns);
      setExcludedColumnsTable(unselectedColumns);
    },
    [includedColumnsTable, excludedColumnsTable]
  );

  useEffect(() => {
    if (!filteredDashboards.length || selectedDashboard) return;
    setSelectedDashboard(filteredDashboards[0].id);
  }, [filteredDashboards, selectedDashboard]);

  const resetPinModal = useCallback(() => {
    setSelectedDashboard(dashboard?.id || filteredDashboards[0]?.id || "New Dashboard");
    setCardHeading(card?.name || locationName);
    setPinRadio(card?.type || (graphs && graphs.length ? "graph" : "table"));
    setShowLegend(card?.showLegend === undefined ? true : !!card.showLegend);
    setShowTotals(card?.showTotals === undefined ? true : !!card.showTotals);
    setIsOpen(false);
    setText({ mainText: card?.mainText || "", subText: card?.subText || "", hoverText: card?.hoverText || "" });
    setFunctions({ mainText: "", subText: "", hoverText: "" });
    resetColumns();
    setNewDashboardName(`New Dashboard 1`);
    setAccessTags(user.role === "user" ? [{ type: "user", id: user.id, label: `${user.firstName} ${user.lastName}` }] : []);
  }, [filteredDashboards, graphs, locationName, setIsOpen, resetColumns, card?.hoverText, card?.mainText, card?.name, card?.showLegend, card?.showTotals, card?.subText, card?.type, dashboard?.id, user.role, user.id, user.firstName, user.lastName]);

  const handlePinRadio = useCallback((e) => {
    setPinRadio(e.target.value);
  }, []);

  const handleDashboardSelection = useCallback((e) => {
    setSelectedDashboard(e.target.value);
  }, []);

  const handleCardHeading = useCallback((e) => {
    setCardHeading(e.target.value);
  }, []);

  const handleShowLegend = useCallback(() => {
    setShowLegend((prevShowLegend) => !prevShowLegend);
  }, []);

  const handleShowTotals = useCallback(() => {
    setShowTotals((prevShowTotals) => !prevShowTotals);
  }, []);

  const handleTextChange = useCallback((field, value) => {
    setText((prev) => {
      let newText = { ...prev, [field]: value };
      return newText;
    });
  }, []);

  const handleFunctionChange = useCallback((field, value) => {
    setFunctions((prev) => {
      let newFunctions = { ...prev, [field]: value };
      return newFunctions;
    });
  }, []);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleModifyOpen = useCallback(() => {
    setModifyConfirmationIsOpen(true);
  }, [])

  const handleModifyClose = useCallback(() => {
    setModifyConfirmationIsOpen(false);
  }, [])

  const {
    serviceName,
    functionName,
    params: entityParams,
    query: entityQuery,
  } = useMemo(() => {
    let pinLocationNameArray = location.pathname.split("/");

    if (location.pathname.includes("entities"))
      return {
        serviceName: "Entity",
        functionName: "GetPage",
        params: {
          pageType: pinLocationNameArray[2],
          basePage: pinLocationNameArray[3],
          group: pinLocationNameArray[4],
          entity: pinLocationNameArray[5],
        },
        query: location.search
          .slice(1)
          .split("&")
          .filter((param) => !param.includes("checked="))
          .reduce((acc, param) => {
            let decoded = decodeURIComponent(param).replace(/\+/gm, " ");
            let [fieldName, value] = decoded.split("=");
            if (!acc[fieldName]) {
              acc[fieldName] = value;
              return acc;
            }
            if (!Array.isArray(acc[fieldName])) acc[fieldName] = [acc[fieldName]];
            acc[fieldName].push(value);
            return acc;
          }, {}),
      };

    pinLocationNameArray = pinLocationNameArray.slice(-2);
    for (let i = 0; i < pinLocationNameArray.length; i++) {
      pinLocationNameArray[i] = pinLocationNameArray[i]
        .split("-")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join("");
    }
    return { serviceName: pinLocationNameArray[0], functionName: pinLocationNameArray[1] };
  }, [location.pathname, location.search]);

  const getDashboardItem = useCallback(() => {
    if (!pinRadio) {
      console.log("Error");
      return;
    }
    let parameters = url.split("?")[1];

    if (parameters) {
      parameters = parameters.split("&").reduce((acc, param) => {
        let [fieldName, value] = param.split("=");
        if (["checked"].includes(fieldName)) return acc;
        value = decodeURIComponent(value).replace(/\+/gm, " ");
        if (!acc[fieldName]) {
          acc[fieldName] = value;
          return acc;
        }
        if (!Array.isArray(acc[fieldName])) acc[fieldName] = [acc[fieldName]];
        acc[fieldName].push(value);
        return acc;
      }, {});
    } else {
      parameters = {};
    }

    let dashboardId = selectedDashboard === "New Dashboard" ? null : selectedDashboard;

    let dashboardItem = {
      cardType: pinRadio,
      name: cardHeading,
      company_id: user.companyId,
      page_url: url,
      data_path: {
        params: entityParams || {
          ...parameters,
          current: dateRange.label.includes("-") || parameters.current ? parameters.current : dateRange.label,
          endDate: dateRange.endDate().toMillis(),
          startDate: dateRange.startDate().toMillis(),
          interval: dateRange.interval,
          categories: fieldnameList.map((column) => column.fieldName),
        },
        query: entityQuery,
        serviceName: serviceName,
        functionName: functionName,
      },
    };

    switch (pinRadio) {
      case "graph":
        let categories = parameters.categories;
        if (categories) {
          if (!Array.isArray(categories)) categories = [categories];
          categories = includedColumnsGraph.filter((column) => categories.includes(column.label)).map((column) => column.fieldName);
          if (!categories.length) categories = null;
        }
        dashboardItem = {
          ...dashboardItem,
          show_legend: showLegend,
          categories: categories || includedColumnsGraph.map((category) => category.fieldName),
          width: 2,
          height: 2,
        };
        break;
      case "table":
        dashboardItem = { ...dashboardItem, show_totals: showTotals, columns: includedColumnsTable.map((column) => column.fieldName), width: 2, height: 2 };
        break;
      case "text":
        dashboardItem = { ...dashboardItem, main_text: text.mainText, sub_text: text.subText, width: 1, height: 1 };
        if (text.hoverText) dashboardItem.hover_text = text.hoverText;
        break;
      default:
    };

    dashboardItem[dashboardId ? "dashboard_id" : "dashboard_name"] = selectedDashboard;

    return dashboardItem;
  }, [
    cardHeading,
    dateRange,
    includedColumnsTable,
    includedColumnsGraph,
    functionName,
    showLegend,
    showTotals,
    pinRadio,
    selectedDashboard,
    serviceName,
    entityParams,
    entityQuery,
    url,
    user.companyId,
    fieldnameList,
    text.mainText,
    text.subText,
    text.hoverText,
  ]);

  const refetchDashboards = useCallback(() => {
    axios
      .get(`/api/v1/dashboard`)
      .then(res => {
        setDashboards(res.data);
      })
      .catch((err) => addToast({ type: "error", message: "Failed to re-fetch dashboards" }));
  }, [setDashboards, addToast]);

  const handleModify = useCallback(() => {
    let { width, height, ...dashboardItem } = getDashboardItem();
    let reqUrl = `/api/v1/dashboard/${dashboard.id}/cards/${card.id}`;
    setIsDisabled(true);
    handleModifyClose();
    dashboardItem.originalCardType = card.type;
    axios
      .put(reqUrl, dashboardItem)
      .then(res => {
        addToast({ type: "success", message: "Card successfully modified" });
        resetPinModal();
        refetchDashboards();
      })
      .catch(err => {
        console.log(err);
        addToast({ type: "error", message: "Unable to modify card" });
      })
      .finally(() => {
        setIsDisabled(false);
      })
  }, [getDashboardItem, card, dashboard, addToast, resetPinModal, refetchDashboards, handleModifyClose]);

  const handlePin = useCallback(() => {
    let dashboardItem = getDashboardItem();
    
    let reqUrl = "";
    if (!dashboardItem.dashboard_id) {
      reqUrl = "/api/v1/dashboard/card";
      let { roles, tags, users } = accessTags.reduce(
        (acc, cur) => {
          if (cur.type === "role") acc.roles.push(cur);
          else if (cur.type === "tag") acc.tags.push(cur);
          else if (cur.type === "user") acc.users.push(cur);
          return acc;
        },
        { roles: [], tags: [], users: [] }
      );
      dashboardItem.access_tags = { roles, tags, users };
    } else {
      reqUrl = `/api/v1/dashboard/${dashboardItem.dashboard_id}/cards`;
    }

    setIsDisabled(true);
    axios
      .post(reqUrl, dashboardItem)
      .then((res) => {
        addToast({ type: "success", message: "Item Pinned" });
        resetPinModal();
        refetchDashboards();
      })
      .catch((err) => {
        console.log(err);
        addToast({ type: "error", message: "Unable to Pin Item" });
      })
      .finally(() => {
        setIsDisabled(false);
      });
  }, [
    addToast,
    accessTags,
    getDashboardItem,
    resetPinModal,
    refetchDashboards
  ]);

  


  let formComponent;
  switch (pinRadio) {
    case "graph":
      formComponent = (
        <GraphForm
          showLegend={showLegend}
          handleShowLegend={handleShowLegend}
          includedColumnsGraph={includedColumnsGraph}
          excludedColumnsGraph={excludedColumnsGraph}
          handleColumnSelectGraph={handleColumnSelectGraph}
          disabled={isDisabled}
        />
      );
      break;
    case "table":
      formComponent = (
        <TableForm
          showTotals={showTotals}
          handleShowTotals={handleShowTotals}
          includedColumnsTable={includedColumnsTable}
          excludedColumnsTable={excludedColumnsTable}
          handleColumnSelectTable={handleColumnSelectTable}
          disabled={isDisabled}
        />
      );
      break;
    case "text":
      formComponent = (
        <TextForm
          finalColumnsGraph={finalColumnsGraph}
          text={text}
          functions={functions}
          handleTextChange={handleTextChange}
          handleFunctionChange={handleFunctionChange}
          disabled={isDisabled}
        />
      );
      break;
    default:
  }

  const canModify = card && selectedDashboard.toString() === dashboard?.id.toString();

  return (
    <>
      <div className="pin-modal-wrapper">
        <button
          className="pin-to-dash-button"
          onClick={handleOpen}
        >
          {card ? "Modify Card" : "Pin to Dashboard"}
        </button>
        <div className={isOpen ? "date-range-selector pin-modal" : "modal-closed"}>
          <div className="header-section">
            <h1 className="selector-header">Dashboard Pin Settings</h1>
            <button className="selector-exit" onClick={resetPinModal}></button>
          </div>
          <div className="dashboard-pin-settings">
            <div className="dashboard-selector">
              <label className="dashboard-selector-label">Dashboard</label>
              <select className="dashboard-selector-input" value={selectedDashboard} onChange={handleDashboardSelection} disabled={isDisabled}>
                {filteredDashboards.map((dashboard) => {
                  return (
                    <option value={dashboard.id} key={`dashboard-select-option-${dashboard.id}`}>
                      {dashboard.name}
                    </option>
                  );
                })}
                <option value={"New Dashboard"} key={`dashboard-select-option-new`}>
                  -- New Dashboard --
                </option>
              </select>
            </div>
            <PinNewDashboardSection
              selectedDashboard={selectedDashboard}
              newDashboardName={newDashboardName}
              setNewDashboardName={setNewDashboardName}
              accessTags={accessTags}
              setAccessTags={setAccessTags}
              roles={roles}
              tags={tags}
              users={users}
              disabled={isDisabled}
            />
            <div className="pin-card-heading-section">
              <label>Card Heading</label>
              <input className="card-heading-input" value={cardHeading} onChange={handleCardHeading} disabled={isDisabled} />
            </div>
            <div className="page-section-selectors">
              <span className="card-graph-select">
                <input type="radio" name="pinType" value="graph" checked={pinRadio === "graph"} disabled={disableGraph || isDisabled} onChange={handlePinRadio} />
                Graph
              </span>
              <span className="card-table-select">
                <input type="radio" name="pinType" value="table" checked={pinRadio === "table"} onChange={handlePinRadio} disabled={isDisabled} />
                Table
              </span>
              <span className="card-text-select">
                <input type="radio" name="pinType" value="text" checked={pinRadio === "text"} onChange={handlePinRadio} disabled={isDisabled} />
                Text
              </span>
            </div>
            {formComponent}
            {canModify ? <button className="custom-date-button pin-modify-button" onClick={handleModifyOpen} disabled={isDisabled}>
              Modify Card
            </button> : null}
            <button className="custom-date-button pin-save-button" onClick={handlePin} disabled={isDisabled}>
             Create Card
            </button>
          </div>
        </div>
      </div>
      <Modal className="modify-modal" title="Modify Card" type="warning" isOpen={modifyConfirmationIsOpen} onExit={handleModifyClose}>
        <p>Are you sure you want to modify the existing card?</p>
        <p>("{card?.name}" on the "{dashboard?.name}" dashboard)</p>
        <div className="modify-buttons">
          <button onClick={handleModifyClose}>Cancel</button>
          <button onClick={handleModify}>Modify</button>
        </div>
      </Modal>
    </>
  );
}

export default PinModal;
