import { useState, useCallback, useContext } from "react";
import axios from "axios";
import { ToastContext } from "../../Contexts/toastContext";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [wasSent, setWasSent] = useState(false);
  const [error, setError] = useState("");
  const [isSending, setIsSending] = useState(false);
  const { addToast } = useContext(ToastContext);

  const handleClick = useCallback(() => {
    if (!email || !/.+@.+\..+/g.test(email)) return setError("Invalid Email");
    setIsSending(true);
    axios
      .post("/api/v1/auth/forgot-password", { email })
      .then((resp) => {
        setWasSent(true);
      })
      .catch((err) => {
        console.log(err);
        addToast({ type: "error", message: "Request failed, please try again later" });
        setIsSending(false);
      });
  }, [email, addToast]);

  if (wasSent) {
    return (
      <div>
        <h1 className="auth-heading">Password reset email sent</h1>
        <p>If a user with that email exists, a reset password link was sent.</p>
      </div>
    );
  }

  return (
    <div>
      <h1 className="auth-heading">Forgot your password?</h1>
      <p>Please enter the email you use to sign in.</p>
      <input className={error ? "error-input" : ""} type="email" value={email} onChange={(e) => setEmail(e.target.value)} disabled={isSending} />
      <button className="submit-button" onClick={handleClick} disabled={isSending}>
        Request password reset
      </button>
    </div>
  );
}

export default ForgotPassword;
