import "./style.scss";

function ProductCard({ product, setQuantity, onSelect, disabled }) {
  return (
    <div key={`${product.id}`} className="product-card">
      <h2 className="product-name">{product.name}</h2>
      <p className="product-description">{product.description}</p>
      <h1 className="product-price">${(product.default_price.unit_amount / 100).toFixed(0)}</h1>
      <p className="product-subtext">user/month</p>
      <input
        type="number"
        onChange={(e) => setQuantity({ value: e.target.value, productId: product.id })}
        value={product.quantity}
        min={0}
        className="product-quantity"
        disabled={disabled}
      />
      <p className="product-subtotal">{`Sub total: $${((product.quantity * product.default_price.unit_amount) / 100).toFixed(2)}/month`}</p>
      <button className="purchase-button" onClick={() => onSelect({ price: product.default_price.id, quantity: product.quantity })} disabled={disabled}>
        Purchase
      </button>
    </div>
  );
}

export default ProductCard;
