import { createContext, useState, useEffect, useCallback, useRef, useContext } from "react";
import axios from "axios";
import { AuthContext } from "./authContext";
import { ToastContext } from "./toastContext";

const EmailContext = createContext();

function EmailProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);
  const [emails, setEmails] = useState([]);
  const [tags, setTags] = useState([]);
  const { user } = useContext(AuthContext);
  const { addToast } = useContext(ToastContext);
  const ref = useRef();

  useEffect(() => {
    if (!user) return;
    const CancelToken = axios.CancelToken;
    let cancel;
    axios
      .get("/api/v1/email/user-list", {
        cancelToken: new CancelToken((c) => (cancel = c)),
      })
      .then((resp) => {
        setEmails(
          resp.data
            .map((item) => ({ id: item.id, label: item.name, value: item.email }))
            .sort((a, b) => {
              if (a.label > b.label) return 1;
              if (a.label < b.label) return -1;
              return 0;
            })
        );
      })
      .catch((err) => {
        console.log(err);
        addToast({ type: "error", message: "Failed to get email list" });
      });

    return () => cancel();
  }, [addToast, user]);

  useEffect(() => {
    if (!user) return;
    const CancelToken = axios.CancelToken;
    let cancel;

    axios
      .get("/api/v1/filters", {
        cancelToken: new CancelToken((c) => (cancel = c)),
      })
      .then((resp) => {
        if (resp.data) setTags(resp.data);
      })
      .catch((err) => {
        console.log(err);
        addToast({ type: "error", message: "Failed to get tag list" });
      });

    return () => cancel();
  }, [addToast, user]);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  const open = useCallback(() => {
    setIsOpen(true);
  }, []);

  return <EmailContext.Provider value={{ isOpen, open, close, emails, tags, ref }}>{children}</EmailContext.Provider>;
}

export { EmailContext, EmailProvider };
