import useAuth from "../../Hooks/useAuth";
import { roles } from "../../Utilities/constants";

function RequireAuthComponent({ role, licensed, children }) {
  const { user, hasChecked } = useAuth();

  if (!hasChecked) return null;

  if (!role) return children;

  if (!user) {
    if (role === "none") return children;
    return null;
  }

  // @Uncomment: Uncommenting this makes components with the licensed indicator require a license to access
  // if (licensed && !user.licenseId && user.role !== "dev") return null;

  if (roles.indexOf(role) > roles.indexOf(user.role)) return null;

  return children;
}

export default RequireAuthComponent;
