import "./style.scss";

function IntervalSelector({ intervals, onIntervalChange, onExit }) {
  return (
    <div className="interval-selector">
      <div className="header-section">
        <h1 className="selector-header">Interval</h1>
        <button className="selector-exit" onClick={onExit}></button>
      </div>
      <div className="interval-section">
        {intervals.map((item) => (
          <p key={`interval-${item}`} className="date-range-link" onClick={() => onIntervalChange(item)}>
            {item}
          </p>
        ))}
      </div>
    </div>
  );
}

export default IntervalSelector;
