import { useMemo } from "react";
import useNewDataFetcher from "../../../../Hooks/newUseDataFetcher";
import GraphTable from "../../../../Components/GraphTable";
import SubheaderTimePeriod from "../../../../Components/SubheaderTimePeriod";

function ByJobOrder() {
  const dataURL = "/api/v1/activity/recruiting/joborder"
  const { data, dateRange, filterTypes, filters, isReady } = useNewDataFetcher({
    endPoint: dataURL,
  });

  const count = useMemo(() => {
    if (!data) return 0;
    // let count = Object.values(data.data).filter((obj) => Object.keys(obj).length).length;
    // return count;
  }, [data]);

  return (
    <GraphTable data={data} dateRange={dateRange} filterTypes={filterTypes} filters={filters} isReady={isReady} dataURL={dataURL}>
      <h1 className="page-heading">What job orders are we working?</h1>
      <h2 className="page-subheading">
        <span className="page-data">{count}</span> job orders and <span className="page-data">{count}</span> clients with activity <SubheaderTimePeriod data={dateRange} />
      </h2>
    </GraphTable>
  );
}

export default ByJobOrder;
