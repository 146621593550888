import "./style.scss";

function ManageTagsCard({ tag, onDelete, onHover, focused, handleChange }) {
  return (
    <div
      className="tag-card"
      onMouseOver={() => {
        onHover(tag.id, true);
      }}
      onMouseLeave={() => {
        onHover(tag.id, false);
      }}
      onFocus={() => {
        focused(tag.id, true);
      }}
      onBlur={() => {
        focused(tag.id, false);
      }}
    >
      {tag.isHovering || tag.isFocused ? (
        <div>
          <input className="manage-tags-edit-input" value={tag.tagName} onChange={(e) => handleChange(e, tag.id)}></input>
          <button className="manage-tags-delete-button" onClick={() => onDelete(tag.id)}>
            Delete
          </button>
        </div>
      ) : (
        <div>
          {tag.tagName}
          <button className="manage-tags-delete-hidden"></button>
        </div>
      )}
    </div>
  );
}

export default ManageTagsCard;
