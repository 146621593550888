import { useLocation, useParams } from "react-router-dom";
import PageNav from "../PageNav";
import GraphTable from "../GraphTable";
import useNewDataFetcher from "../../Hooks/newUseDataFetcher";
import { formatString } from "../../Utilities/formatting";

function EntityQuery() {
  const params = useParams();
  const { pathname, search } = useLocation();
  const { data, dateRange, filterTypes, filters, isReady } = useNewDataFetcher({
    endPoint: `/api/v1${pathname}`,
    search: search,
  });

  let basePage = formatString(params.basePage || "");
  let entity = formatString(params.entity || "");
  //@TODO: Allow title to be determined by the json;

  console.log(data);

  return (
    <>
      <PageNav title={basePage} links={[]} />
      <div className="page">
        <GraphTable
          data={data}
          dateRange={dateRange}
          interval={dateRange.interval}
          filterTypes={filterTypes}
          filters={filters}
          updateURL={false}
          isReady={isReady}
        >
          <h1 className="page-heading">{entity}</h1>
        </GraphTable>
      </div>
    </>
  );
}

export default EntityQuery;
