import EditTable from "../../../../Components/EditTable";
import useNewDataFetcher from "../../../../Hooks/newUseDataFetcher";

function EmployeeActivityGoals() {
  const { data } = useNewDataFetcher({ endPoint: "/api/v1/admin/goals/employee-activity" });
  const { employeeActivity, columns } = data || {};

  return <EditTable initialValues={employeeActivity} columns={columns} endPoint="/api/v1/admin/goals/employee-activity" />;
}

export default EmployeeActivityGoals;
