import { useState, useEffect, useRef } from "react";
import "./style.scss";

function Toast({ type, message, duration, close }) {
  const [isClosing, setIsClosing] = useState(false);
  const outerTimeout = useRef();
  const innerTimeout = useRef();

  useEffect(() => {
    const length = duration || 5000;
    if (length !== "infinite" && !outerTimeout.current && !innerTimeout.current) {
      outerTimeout.current = setTimeout(() => {
        innerTimeout.current = setTimeout(() => {
          close();
        }, 500);
        setIsClosing(true);
      }, length);
    }
  }, [close, duration, message]);

  useEffect(() => {
    return () => {
      clearTimeout(outerTimeout.current);
      clearTimeout(innerTimeout.current);
    };
  }, []);

  return (
    <div className={`toast ${isClosing ? "closing" : ""}`}>
      <div className={`identifier ${type}`}></div>
      <p className="message">{message || "This is a test message"}</p>
      <div className="close" onClick={() => close()}></div>
    </div>
  );
}

export default Toast;
