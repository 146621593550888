import { Routes, Route, Navigate } from "react-router-dom";
import PageNav from "../../Components/PageNav";
import ValueOverTime from "./SubPages/ValueOverTime";
import ByValue from "./SubPages/ByValue";
import Landscape from "./SubPages/Landscape";

function OpenJobs() {
  return (
    <>
      <PageNav
        title="Placements"
        links={[
          { label: "Landscape", href: "landscape" },
          { label: "By Value", href: "by-value" },
          { label: "Value Over Time", href: "value-over-time" },
        ]}
      />
      <div className="page">
        <Routes>
          <Route path={`landscape`} element={<Landscape />} />
          <Route path={`by-value`} element={<ByValue />} />
          <Route path={`value-over-time`} element={<ValueOverTime />} />
          <Route index element={<Navigate to={`landscape`} />} />
        </Routes>
      </div>
    </>
  );
}

export default OpenJobs;
