import { Fragment } from "react";

function Funnel({ data, scales, xScale = "x", yScale = "y", padding, width, height, margin, onEnter, onLeave, offset }) {
  let isHorizontal = !(scales[xScale] && scales[xScale].bandwidth);
  let prev;

  return (
    <g className="funnel-group">
      {data
        .sort((a, b) => b.items[0].value - a.items[0].value)
        .map((category, idx) => {
          return category.items.map((item) => {
            if (!scales[xScale] || !scales[yScale]) return null;

            let x, y, bWidth, bHeight, backX, backY, backWidth, backHeight, value;
            const [xOff, xRange] = scales[xScale].range();
            const maxWidth = xRange - xOff;

            if (isHorizontal) {
              scales[yScale].padding(0).paddingInner(0.5);
              bWidth = scales[xScale](item[xScale]); // - padding;
              bHeight = scales[yScale].bandwidth();
              x = maxWidth / 2 - bWidth / 2 + padding;
              y = scales[yScale](item[yScale]);
              backX = padding;
              backY = scales[yScale](item[yScale]);
              backWidth = maxWidth;
              backHeight = bHeight;
              value = item[xScale];
            } else {
              scales[xScale].padding(0).paddingInner(0.5);
              bWidth = scales[xScale].bandwidth();
              bHeight = height - scales[yScale](item[yScale]) - margin.top - margin.bottom - padding;
              x = scales[xScale](item[xScale]);
              y = height / 2 - bHeight / 2;
              backX = scales[xScale](item[xScale]);
              backY = height - margin.bottom - margin.top - bHeight - padding;
              backWidth = bWidth;
              backHeight = height;
              value = item[xScale];
            }

            if (x === undefined || y === undefined) return null;

            let percentage;
            if (prev) {
              percentage = Math.floor((value / prev) * 100);
            }
            prev = value;

            return (
              <Fragment key={`funnel-group-${item.label}`}>
                <g className="funnel-group" transform={`translate(${offset.x}, ${offset.y})`}>
                  <rect x={backX} y={backY} width={backWidth} height={backHeight} fill="#dddddd" />
                  <rect x={x} y={y} width={bWidth} height={bHeight} fill={category.color} />
                  <text x={backX + backWidth / 2} y={backY + backHeight / 2} fontWeight="bold" textAnchor="middle" dominantBaseline="middle">
                    {value}
                  </text>
                  {percentage !== undefined ? (
                    <text x={backX + backWidth / 2} y={backY - backHeight / 2} fill="#999999" fontWeight="bold" textAnchor="middle" dominantBaseline="middle">
                      {percentage}%
                    </text>
                  ) : null}
                </g>
              </Fragment>
            );
          });
        })}
    </g>
  );
}

export default Funnel;
