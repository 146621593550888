import { useState, useEffect, useCallback, useContext } from "react";
import axios from "axios";
import { ToastContext } from "../../Contexts/toastContext";
import { formatNumber } from "../../Utilities/formatting";
import useParams from "../../Hooks/useParams";
import "./style.scss";

function EditTable({ initialValues, columns, endPoint, dateRange, byMonth }) {
  const [originalValues, setOriginalValues] = useState(initialValues);
  const [values, setValues] = useState(initialValues);
  const { addToast } = useContext(ToastContext);
  const { updateParams } = useParams();

  useEffect(() => {
    setOriginalValues(() => {
      if (!initialValues || !initialValues.length) return initialValues;
      return initialValues.map((row) => {
        return Object.entries(row).reduce((acc, [fieldName, value]) => {
          if (/^(\d+,*\.*)+/g.test(value)) {
            value = parseFloat(value.toString().replace(/,/g, ""));
            value = formatNumber(value, false);
          }
          acc[fieldName] = value === null ? "" : value;
          return acc;
        }, {});
      });
    });
  }, [initialValues]);

  useEffect(() => {
    setValues((prevValues) => {
      return originalValues;
    });
  }, [originalValues]);

  const onSave = useCallback(() => {
    axios
      .put(endPoint, values)
      .then((resp) => {
        setOriginalValues(values);
        addToast({ type: "success", message: "Values saved" });
      })
      .catch((err) => {
        console.log(err);
        addToast({ type: "error", message: "Unable to save values" });
      });
  }, [values, endPoint, addToast]);

  const onChange = useCallback((rIdx, fieldName, value) => {
    setValues((prevValues) => {
      return prevValues.map((row, idx) => {
        if (rIdx !== idx) return row;
        return { ...row, [fieldName]: value };
      });
    });
  }, []);

  const onFocus = useCallback((rIdx, fieldName) => {
    setValues((prevValues) => {
      let value = prevValues[rIdx][fieldName];
      if (/^(\d+,*\.*)+/g.test(value)) {
        value = parseFloat(value.toString().replace(/,/g, ""));
      }
      return prevValues.map((row, idx) => {
        if (rIdx !== idx) return row;
        return { ...row, [fieldName]: value };
      });
    });
  }, []);

  const onBlur = useCallback((rIdx, fieldName) => {
    setValues((prevValues) => {
      let value = prevValues[rIdx][fieldName];
      if (/^(\d+\.*)+/g.test(value)) {
        value = parseFloat(value);
        value = formatNumber(value, false);
      }
      return prevValues.map((row, idx) => {
        if (rIdx !== idx) return row;
        return { ...row, [fieldName]: value };
      });
    });
  }, []);

  const onPrev = useCallback(() => {
    let startDate = dateRange.startDate().minus({ months: 1 }).toMillis();
    let endDate = dateRange.endDate().minus({ months: 1 }).toMillis();

    updateParams({ startDate, endDate });
  }, [dateRange, updateParams]);

  const onNext = useCallback(() => {
    let startDate = dateRange.startDate().plus({ months: 1 }).toMillis();
    let endDate = dateRange.endDate().plus({ months: 1 }).toMillis();

    updateParams({ startDate, endDate });
  }, [dateRange, updateParams]);

  return (
    <div className="edit-table-wrapper">
      <table className={`data-table ${byMonth ? "" : "extended-table"}`}>
        <thead>
          <tr className="month-controls">
            {(() => {
              if (byMonth) {
                return (
                  <>
                    <th></th>
                    <th></th>
                    <th>
                      <button className="prev-button" onClick={onPrev}>
                        <span className="button-icon"></span>Prev
                      </button>
                    </th>
                    <th>
                      <h4>Monthly Goal</h4>
                    </th>
                    <th>
                      <button className="next-button" onClick={onNext}>
                        Next<span className="button-icon"></span>
                      </button>
                    </th>
                  </>
                );
              }
              if (!columns) return null;
              return columns.map((col, idx) => (idx < columns.length - 2 ? <th key={`column-spacer-${idx}`}></th> : null));
            })()}
            <th key="save-column" className="save-th" colSpan={byMonth ? 1 : 2}>
              <button className="save-button" onClick={onSave} disabled={JSON.stringify(values) === JSON.stringify(originalValues)}>
                Save
              </button>
            </th>
          </tr>
          <tr>
            {(columns || []).map((column) => (
              <th key={`column-label-${column.label}`}>{column.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {(values || []).map((row, rIdx) => {
            return (
              <tr key={`row-${rIdx}`}>
                {(columns || []).map((column) => {
                  return !column.isInput ? (
                    <td key={`cell-${rIdx}-${column.fieldName}`}>
                      <span>{row[column.fieldName] || ""}</span>
                    </td>
                  ) : (
                    <td className={`${byMonth ? "input-td" : ""}`} key={`cell-${rIdx}-${column.fieldName}`}>
                      <input
                        className="table-input"
                        type="text"
                        value={row[column.fieldName]}
                        onChange={(e) => onChange(rIdx, column.fieldName, e.target.value)}
                        onFocus={() => onFocus(rIdx, column.fieldName)}
                        onBlur={() => onBlur(rIdx, column.fieldName)}
                      />
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default EditTable;
