import { useMemo } from "react";
import useNewDataFetcher from "../../../../Hooks/newUseDataFetcher";
import GraphTable from "../../../../Components/GraphTable";
import SubheaderTimePeriod from "../../../../Components/SubheaderTimePeriod";

function ByJobOwner() {
  const dataURL = "/api/v1/candidate/status/by-candidate-owner"
  const { data, dateRange, filterTypes, filters, isReady } = useNewDataFetcher({
    endPoint: dataURL,
  });

  const candidates = useMemo(() => {
    if (!data) return 0;
    return data.data.reduce((acc, cur) => {
      acc += cur["New Lead"] || 0;
      return acc;
    }, 0);
  }, [data]);

  return (
    <GraphTable data={data} dateRange={dateRange} filterTypes={filterTypes} filters={filters} isReady={isReady} dataURL={dataURL}>
      <h1 className="page-heading">What status are my employees' candidates in?</h1>
      <h2 className="page-subheading">
        <span className="page-data">{candidates}</span> candidates created <SubheaderTimePeriod data={dateRange} /> have the status New Lead
      </h2>
    </GraphTable>
  );
}

export default ByJobOwner;
