import { useMemo } from "react";
import useNewDataFetcher from "../../../../Hooks/newUseDataFetcher";
import GraphTable from "../../../../Components/GraphTable";
import SubheaderTimePeriod from "../../../../Components/SubheaderTimePeriod";

function WonLost() {
  const dataURL = "/api/v1/activity/sales/won-lost"
  const { data, dateRange, filterTypes, filters, isReady } = useNewDataFetcher({
    endPoint: dataURL,
  });

  const total = useMemo(() => {
    if (!data) return 0;
    let total = data.data.reduce((acc, cur) => {
      if (cur.category !== "Won") return acc;
      return cur.totalJobOrders;
    }, 0);
    return total;
  }, [data]);

  return (
    <GraphTable data={data} dateRange={dateRange} interval={dateRange.interval} filterTypes={filterTypes} filters={filters} isReady={isReady} dataURL={dataURL}>
      <h1 className="page-heading">How do won and lost job orders compare?</h1>
      <h2 className="page-subheading">
        <span className="page-data">{total}</span> job orders created <SubheaderTimePeriod data={dateRange} /> were subsequently won
      </h2>
    </GraphTable>
  );
}

export default WonLost;
