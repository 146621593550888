import { filterFunctions } from "../../Utilities/columnFilterFunctions";
import "./style.scss";

function ColumnFilter({ type = "number", filter, onSymbolChange, onValueChange, onDelete }) {
  return (
    <>
      <span className="column-filter-value">value</span>
      <select className="column-filter-select" value={filter.key} onChange={onSymbolChange}>
        {Object.entries(filterFunctions)
          .filter(([key, filter]) => filter.types.includes(type))
          .map(([key, filter], index) => {
            return (
              <option key={`filter-option-${index}`} value={key}>
                {filter.symbol}
              </option>
            );
          })}
      </select>
      <input className="column-filter-input" type="text" value={filter.compareValue} onChange={onValueChange} />
      <button className="column-filter-delete" onClick={onDelete}></button>
    </>
  );
}

export default ColumnFilter;
