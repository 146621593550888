import { useEffect, useState, useRef } from "react";
import { formatDate, formatNumber } from "../../../Utilities/formatting";
import "./style.scss";

const fontSize = 16;
const padding = 4;

function Tooltip({ x: pX = 0, y: pY = 0, data, isVisible, width: gWidth, height: gHeight, color }) {
  const tipRef = useRef(null);
  const [{ width, height }, setDimensions] = useState({ width: 0, height: 0 });

  function formatString(value) {
    if (typeof value === "string") return value;
    if (typeof value === "number") return formatNumber(value);
    return formatDate(value);
  }

  useEffect(() => {
    let width = Math.max(0, ...Array.from(tipRef.current.querySelectorAll(".tooltip-text")).map((item) => item.getBoundingClientRect().width)) + 2 * padding;
    let height = 2 * padding + Array.from(tipRef.current.querySelectorAll(".tooltip-text")).length * fontSize;
    width = width === -Infinity ? 0 : width;
    height = height === -Infinity ? 0 : height;
    setDimensions({ width, height });
  }, [data]);

  let x = 0;
  let y = 0;
  if (pX > gWidth / 2) {
    x = pX - width;
  } else x = pX;

  if (pY > gHeight / 2) {
    y = pY - height;
  } else y = pY;

  return (
    <g className="tooltip" transform={`translate(${x}, ${y})`} opacity={isVisible ? 1 : 0} ref={tipRef}>
      <rect className="tooltip-background" x={0} y={0} width={width} height={height} stroke={color} strokeWidth={2} />
      {data.map((string, idx) => (
        <text key={`tooltip-${idx}`} className={`tooltip-text`} x={padding} y={padding / 2 + (idx + 1) * fontSize} textAnchor="start">
          {formatString(string).toString()}
        </text>
      ))}
    </g>
  );
}

export default Tooltip;
