import "./style.scss";

function PaymentMethod({ card, isSelected, onSelect }) {
  return (
    <div key={card.id} className={isSelected ? "payment-method selected-payment-method" : "payment-method"} onClick={() => onSelect(card.id)} tabIndex={0}>
      <div>
        {card.card.brand.toUpperCase()}
        {card.isDefault ? <span className="payment-method-default-tag">(default)</span> : null}
      </div>
      <div>{`XXXX XXXX XXXX ${card.card.last4}`}</div>
      <div>{`Expiration: ${card.card.exp_month}/${card.card.exp_year}`}</div>
    </div>
  );
}

export default PaymentMethod;
