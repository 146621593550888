import { DateTime } from "luxon";

function truncate(str, maxLength = 25) {
  if (!str) return str;
  return str.slice(maxLength);
}

function formatDate(date) {
  if (!date) return date;
  if (!(date instanceof Date)) return date.toString();
  let formatter = new Intl.DateTimeFormat("en-us", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
  return formatter.format(date);
}

function formatNumber(num, shouldShorten = true) {
  if (!num) return num;
  let isNegative = num < 0;
  let string = Math.trunc(Math.abs(num)).toString();
  //if (string.length < 4) return num.toString();
  if (shouldShorten && string.length > 9) return (num / 1_000_000_000).toFixed(2) + "B";
  if (shouldShorten && string.length > 6) return (num / 1_000_000).toFixed(2) + "M";
  string = string
    .split("")
    .reverse()
    .join("")
    .replace(/\d{3}/gm, (x) => `${x},`)
    .split("")
    .reverse()
    .join("");
  if (string[0] === ",") string = string.slice(1);
  if (isNegative) string = "-" + string;
  let decimal = Math.abs(num - Math.trunc(num));
  decimal = decimal ? decimal.toFixed(2).slice(1) : "".toString().slice(1);
  return string + decimal;
}

function formatString(unformatted) {
  let formatted = unformatted
    .replace(/[A-Z]/g, (letter) => `_${letter}`)
    .split(/_|\s|-|\+/g)
    .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
  return formatted;
}

function populateVariables(string, data, isLink) {
  let pieces = string.replace(/%((?:\w+(?:\(\w+\))?[+\-*/]*)+)%/gm, (x, y) => `~${x}~`).split(/~/gm);
  let newString = pieces.map((piece) => {
    if (piece[0] !== "%" || piece[piece.length - 1] !== "%" || piece === "%") return piece;
    let parts = piece
      .replace(/%/gm, "")
      .replace(/[+\-*/<>]=?/gm, (x) => `~${x}~`)
      .split("~");
    //@TODO: Make it solve both sides of equation before evaluating

    let { value, isDate } = parts.reduce(
      (acc, cur) => {
        let val = data[cur];
        if (!val) {
          let func, argument;
          if (cur.includes("(")) {
            [func, argument] = cur.split(/\(|\)/);
            cur = "FUNCTION";
          }
          switch (cur) {
            case "now":
              val = DateTime.now();
              break;
            case "this":
              val = data;
              break;
            case "FUNCTION":
              if (DateTime.now()[func]) {
                acc.isDate = true;
                val = DateTime.now()[func](argument);
              } else if (valueFunctions[func]) {
                val = valueFunctions[func](argument, data);
              }
              break;
            default:
          }
        }
        if (val && val.value) val = val.value;
        let dateTest = DateTime.fromSQL(val);
        if (dateTest.isValid && val && val.toString().includes("-")) {
          val = dateTest;
          acc.isDate = true;
        }
        if (acc.value === null) {
          acc.value = val;
          return acc;
        }
        if (acc.operator === null) {
          acc.operator = cur;
          return acc;
        }
        if (val === undefined) val = parseFloat(cur);
        switch (acc.operator) {
          case "+":
            if (acc.value && acc.value.isValid) {
              acc.value = acc.value.plus({ [val]: 1 });
            } else {
              acc.value += val;
            }
            break;
          case "-":
            if (acc.value && acc.value.isValid) {
              acc.value = acc.value.minus({ [val]: 1 });
            } else {
              acc.value -= val;
            }
            break;
          case "*":
            acc.value *= val;
            break;
          case "/":
            acc.value /= val;
            break;
          case ">":
            acc.value = acc.value > val;
            break;
          case ">=":
            acc.value = acc.value >= val;
            break;
          case "<":
            acc.value = acc.value < val;
            break;
          case "<=":
            acc.value = acc.value <= val;
            break;
          default:
        }
        acc.operator = null;
        return acc;
      },
      { value: null, operator: null, isDate: false }
    );
    if (isLink) {
      if (value && value.isValid) value = value.toMillis();
    } else {
      if (typeof value === "number" && (!isDate || parts.length > 1)) value = formatNumber(value);
      else if (value && value.isValid) value = formatDate(value.toJSDate());
    }

    return value;
  });
  return newString.join("") || string;
}

function parsePhoneNumber(phoneNumber) {
  return phoneNumber
    .toString()
    .replace(/[^0-9]/gm, (_) => "")
    .slice(0, 10);
}

function formatPhoneNumber(phoneNumber) {
  let formatted = `(${phoneNumber.slice(0, 3) || ""}) ${phoneNumber.slice(3, 6) || ""}-${phoneNumber.slice(6) || ""}`;
  return formatted;
}

const valueFunctions = {
  sum: (fieldName, { data }) => {
    let sum = 0;
    for (let item of data) {
      sum += item[fieldName] || 0;
    }
    return sum;
  },
  average: (fieldName, { data }) => {
    if (!data.length) return 0;
    let sum = 0;
    for (let item of data) {
      sum += item[fieldName] || 0;
    }
    return parseFloat((sum / data.length).toFixed(2));
  },
  count: (fieldName, { data }) => {
    return data.length || 0;
  },
};

export { formatDate, formatNumber, formatString, formatPhoneNumber, parsePhoneNumber, truncate, populateVariables };
