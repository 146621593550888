import { useState, useContext } from "react";
import SubMenu from "../SubMenu";
import SubMenuItem from "../SubMenuItem";
import RequireAuthComponent from "../RequireAuthComponent";
import { DashboardContext } from "../../Contexts/dashboardContext";
import "./style.scss";

function SideNav() {
  const [isOpen, setIsOpen] = useState(true);
  const { dashboards } = useContext(DashboardContext);

  const dashboardLinks = dashboards.map((dashboard) => {
    return <SubMenuItem key={`dashboard-link-${dashboard.name}-${dashboard.id}`} label={dashboard.name} path={`/${dashboard.id}`} />;
  });

  return (
    <RequireAuthComponent role="user">
      <div className={`side-nav ${isOpen ? "open" : "closed"}`}>
        <div className="side-nav-heading">
          <h1 className="side-nav-header">Library</h1>
          <div className="side-nav-close" onClick={() => setIsOpen(false)}></div>
        </div>
        {/* <SubMenu label="Scorecards">
        <SubMenuItem label="Employees" path="/" />
        <SubMenuItem label="Clients" path="/" />
      </SubMenu> */}
        <RequireAuthComponent role="user" licensed>
          <SubMenu label="Activities" path="/activities">
            <SubMenuItem label="Activities (Sales)" path="/activity-sales" />
            <SubMenuItem label="Activity Ratios (Sales)" path="/activity-ratio-sales" />
            <SubMenuItem label="Activities (Recruiting)" path="/activity-recruiting" />
            <SubMenuItem label="Activity Ratios (Recruiting)" path="/activity-ratio-recruiting" />
            <SubMenuItem label="Record Counts" path="/record-counts" />
          </SubMenu>
          <SubMenu label="Job Orders" path="/joborders">
            <SubMenuItem label="Open Jobs" path="/open-jobs" />
            {/* <SubMenuItem label="Job Order Trends" path="/" />
        <SubMenuItem label="Sales Forecast" path="/" /> */}
          </SubMenu>
          <SubMenu label="Candidates" path="/candidates">
            <SubMenuItem label="Candidate Status" path="/candidate-status" />
            {/* <SubMenuItem label="Candidate Activity" path="/" />
        <SubMenuItem label="Candidate Funnel" path="/" /> */}
          </SubMenu>
          <SubMenu label="Results" path="/results">
            <SubMenuItem label="Placements" path="/placements" />
            <SubMenuItem label="Bookings" path="/bookings" />
            {/* <SubMenuItem label="Placement Funnel" path="/" />
        <SubMenuItem label="Bookings Trajectory" path="/" />
        <SubMenuItem label="Active Placements" path="/" />
        <SubMenuItem label="Time To Fill" path="/" />
        <SubMenuItem label="Won/Lost" path="/" />
        <SubMenuItem label="Candidate Source" path="/" />
        <SubMenuItem label="Win Factors" path="/" /> */}
          </SubMenu>
          {/* <SubMenu label="Data Quality">
        <SubMenuItem label="Fix List" path="/" />
      </SubMenu>
      <SubMenu label="The Lab">
        <SubMenuItem label="Projected Revenue" path="/" />
      </SubMenu> */}
          <SubMenu label="Companies" path="/companies">
            <SubMenuItem label="Corporations" path="/corporations" />
          </SubMenu>
          <SubMenu label="Dashboards" path="/dashboards">
            {/* <SubMenuItem label="Altus Team Dashboard" path="/altus-team" />
          <SubMenuItem label="Director Of Recruiting" path="/director-of-recruiting" />
          <SubMenuItem label="Director Of Sales" path="/director-of-sales" />
          <SubMenuItem label="Executive: Daily View" path="/executive-daily" /> */}
            {dashboardLinks}
          </SubMenu>
        </RequireAuthComponent>
        <RequireAuthComponent role="admin">
          <SubMenu label="Admin" path="/admin">
            <SubMenuItem label="Filters" path="/filters" />
            <SubMenuItem label="Goals" path="/goals" />
            <SubMenuItem label="Accounts" path="/licensing" />
            <SubMenuItem label="Reports" path="/reports" />
          </SubMenu>
        </RequireAuthComponent>
      </div>
      <div className={isOpen ? "expand-button-hidden" : "expand-button-visible"} onClick={() => setIsOpen(true)}>
        <div className="sidenav-expand-triangle"></div>
      </div>
    </RequireAuthComponent>
  );
}

export default SideNav;
