import { useState, useCallback, useContext } from "react";
import axios from "axios";
import Modal from "../Modal";
import PhoneInput from "../PhoneInput";
import { ToastContext } from "../../Contexts/toastContext";
import { isValidEmail, isValidPhoneNumber, isValidBullhornId } from "../../Utilities/validateInput";
import "./style.scss";

function CreateUserModal({ isOpen, onExit, getData }) {
  const { addToast } = useContext(ToastContext);
  const [info, setInfo] = useState({ email: "", phoneNumber: "", firstName: "", lastName: "", bullhornId: "" });
  const [isCreating, setIsCreating] = useState(false);

  const handleExit = useCallback(() => {
    setInfo({ email: "", phoneNumber: "", firstName: "", lastName: "", bullhornId: "" });
    onExit();
  }, [onExit]);

  const handleChange = useCallback((e) => {
    setInfo((prevInfo) => ({ ...prevInfo, [e.target.name]: e.target.value }));
  }, []);

  const handleClick = useCallback(() => {
    let rawNumber = info.phoneNumber.replace(/\D/gm, "");
    if (!isValidEmail(info.email) || !isValidPhoneNumber(rawNumber) || !isValidBullhornId(info.bullhornId) || !info.firstName || !info.lastName)
      return console.log("Does not meet requirements");

    setIsCreating(true);
    axios
      .post("/api/v1/admin/accounts/user", { ...info, phoneNumber: rawNumber })
      .then((resp) => {
        handleExit();
        addToast({ type: "success", message: "User successfully created" });
        getData();
      })
      .catch((err) => addToast({ type: "error", message: "Failed to create user" }))
      .finally(() => setIsCreating(false));
  }, [info, addToast, handleExit, getData]);

  return (
    <Modal className="create-user-modal" title="Create New User" isOpen={isOpen} onExit={handleExit}>
      <label className="create-user-label">Email</label>
      <input className="create-user-input" name="email" type="email" value={info.email} onChange={handleChange} />
      <label className="create-user-label">Phone Number</label>
      <PhoneInput className="create-user-input" name="phoneNumber" value={info.phoneNumber} onChange={handleChange} />
      <label className="create-user-label">First Name</label>
      <input className="create-user-input" name="firstName" type="text" value={info.firstName} onChange={handleChange} />
      <label className="create-user-label">Last Name</label>
      <input className="create-user-input" name="lastName" type="text" value={info.lastName} onChange={handleChange} />
      <label className="create-user-label">Bullhorn Id</label>
      <input className="create-user-input" name="bullhornId" type="text" value={info.bullhornId} onChange={handleChange} />
      <div className="create-user-buttons">
        <button className="create-user-button create-user-cancel-button" onClick={onExit} disabled={isCreating}>
          Cancel
        </button>
        <button className="create-user-button create-user-confirm-button" onClick={handleClick} disabled={isCreating}>
          Create User
        </button>
      </div>
    </Modal>
  );
}

export default CreateUserModal;
