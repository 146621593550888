import { useState, useCallback, useContext } from "react";
import axios from "axios";
import Modal from "../Modal";
import { ToastContext } from "../../Contexts/toastContext";
import "./style.scss";

function BuyLicenseModal({ isOpen, onExit }) {
  const [numOfLicenses, setNumOfLicenses] = useState("");
  const { addToast } = useContext(ToastContext);

  const handleChange = useCallback((e) => {
    let value = e.target.value;
    if (value.match(/^\d*$/g)) {
      setNumOfLicenses(value);
    }
  }, []);

  const handleExit = useCallback(
    (e) => {
      setNumOfLicenses("");
      onExit();
    },
    [onExit]
  );

  const handlePurchase = useCallback(
    (e) => {
      if (!parseInt(numOfLicenses)) return;
      //Axios request here
      axios
        .post(`/api/v1/admin/licenses`, { numOfLicenses })
        .then((res) => {
          console.log(res);
          addToast({ type: "success", message: "Licenses Purchased" });
          console.log("purchased");
          handleExit();
        })
        .catch((err) => {
          console.log(err);
          addToast({ type: "error", message: "Licenses Not Purchased" });
        });
    },
    [numOfLicenses, handleExit, addToast]
  );

  return (
    <Modal className="license-modal" title="Buy More Licenses" isOpen={isOpen} onExit={handleExit}>
      <h3 className="license-modal-heading">Staffing Analytics Licenses</h3>
      <p className="license-modal-text">
        How many licenses do you want to buy? <input className="license-modal-input" type="text" onChange={handleChange} value={numOfLicenses} />
      </p>
      <div className="license-modal-buttons">
        <button className="cancel-button" onClick={handleExit}>
          Cancel
        </button>
        <button className="buy-license-button" onClick={handlePurchase}>
          Buy Licenses
        </button>
      </div>
    </Modal>
  );
}

export default BuyLicenseModal;
