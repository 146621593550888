import { DateTime } from "luxon";
import { dateRanges } from "../Components/DateRange";

function calculateDateRange({ startDate, endDate }) {
  startDate = DateTime.fromMillis(parseInt(startDate));
  endDate = DateTime.fromMillis(parseInt(endDate));
  let possibilities = [];
  for (let category of dateRanges) {
    for (let range of category.options) {
      let rangeStart = range.startDate();
      let rangeEnd = range.endDate();
      let startDiff = Math.abs(startDate.diff(rangeStart, ["days"]).toObject().days);
      let endDiff = Math.abs(endDate.diff(rangeEnd, ["days"]).toObject().days);
      if (startDiff < 1 && endDiff < 1) {
        possibilities.push({
          startDate: range.startDate,
          endDate: range.endDate,
          label: category.category === "To Date" || ["Yesterday", "Today"].includes(range.label) ? range.label : `${category.category} ${range.label}`,
        });
      }
    }
  }

  if (possibilities.length === 1) return possibilities[0];

  return {
    startDate: () => startDate,
    endDate: () => endDate,
    label: `${startDate.toFormat("M/d/y")}-${endDate.toFormat("M/d/y")}`,
  };
}

export default calculateDateRange;
