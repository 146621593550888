import { useContext } from "react";
import { AuthContext } from "../../Contexts/authContext";
import RequireAuthComponent from "../RequireAuthComponent";
import UserDropdown from "../UserDropdown";
import "./style.scss";

const GlobalNav = () => {
  const { user, numOfNotifications, openUserDropdown, closeUserDropdown, userDropdownOpen } = useContext(AuthContext);
  const notifications = numOfNotifications;

  return (
    <RequireAuthComponent role="user">
      <div className="global-nav">
        <img className="logo" src="/cloudgate-logo.png" alt="Cloud Gate logo" />
        <p className="nav-menu-heading">Metrix</p>
        {/* <input className="search-box right" type="text" placeholder="search" />
        <p className="nav-menu-heading">Help</p>
        <p className="nav-menu-heading">Settings</p> */}
        <div
          className="nav-menu-heading right"
          onClick={() => {
            if (!userDropdownOpen) {
              openUserDropdown();
            } else {
              closeUserDropdown();
            }
          }}
        >
          {user ? user.firstName : "User"}
          {notifications > 0 ? <span className="global-notification">{notifications}</span> : null}
          <UserDropdown notifications={notifications} />
        </div>
      </div>
    </RequireAuthComponent>
  );
};

export default GlobalNav;
