import { useMemo } from "react";
import useNewDataFetcher from "../../../../Hooks/newUseDataFetcher";
import GraphTable from "../../../../Components/GraphTable";
import SubheaderTimePeriod from "../../../../Components/SubheaderTimePeriod";

function ByEmployeeStage() {
  const dataURL = "/api/v1/joborder/open-jobs/by-employee-stage"
  const { data, dateRange, filterTypes, filters, isReady } = useNewDataFetcher({
    endPoint: dataURL,
  });

  const average = useMemo(() => {
    if (!data) return 0;
    let total = 0;
    data.data.forEach((row) => {
      total += row.total;
    });
    return Math.round(total / data.data.length);
  }, [data]);

  return (
    <GraphTable data={data} dateRange={dateRange} filterTypes={filterTypes} filters={filters} isReady={isReady} dataURL={dataURL}>
      <h1 className="page-heading">What stage are our job orders in?</h1>
      <h2 className="page-subheading">
        <span className="page-data">{average}</span> open job orders per employee, on average, created <SubheaderTimePeriod data={dateRange} />
      </h2>
    </GraphTable>
  );
}

export default ByEmployeeStage;
