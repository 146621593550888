import * as d3 from "d3";

const domains = {
  timeDomain: (variable) => (data) => d3.extent([].concat(...data.map((item) => item.items)), (d) => new Date(d[variable])),

  linearDomain: (variable) => (data) =>
    [
      Math.min(...[].concat(...data.map((item) => item.items.map((inner) => inner[variable]))), 0),
      Math.max(...[].concat(...data.map((item) => item.items.map((inner) => inner[variable])))),
    ],

  bandDomain: (variable) => (data) => {
    if (!data || !data[0]) return [];
    let domain = data
      .reduce((acc, cur) => {
        if (cur.items.length > acc.items.length) return cur;
        return acc;
      })
      .items.map((item) => item.uid.toString())
      .reverse();
    return domain;
  },

  stackedDomain: (variable) => (data) => {
    let totals = Object.values(
      data.reduce((acc, cur) => {
        cur.items.forEach(({ data, [variable]: value, ...labels }) => {
          let label = Object.values(labels).join("");
          if (!acc[label]) acc[label] = 0;
          acc[label] += value;
        });
        return acc;
      }, {})
    );
    return [Math.min(...totals, 0), Math.max(...totals)];
  },

  percentageDomain: () => () => [0, 100],
};

const percentageRange =
  (numOfPercentGraphs, currentIndex, gutter = 25, rightGap = 0) =>
  (min, max) => {
    let section = (max - min - rightGap - gutter * (numOfPercentGraphs - 1)) / numOfPercentGraphs;
    let minimum = min + currentIndex * (section + gutter);
    let maximum = minimum + section;
    return [minimum, maximum];
  };

export { domains, percentageRange };
