import { Routes, Route, Navigate } from "react-router-dom";
import PageNav from "../../Components/PageNav";
import EmployeeTags from "./SubPages/EmployeeTags";
import "./style.scss";

function Filters() {
  return (
    <>
      <PageNav title="Filters" links={[{ label: "Employee Tags", href: "employee-tags" }]} />
      <div className="page">
        <Routes>
          <Route path={`employee-tags`} element={<EmployeeTags />} />
          <Route index element={<Navigate to={`employee-tags`} />} />
        </Routes>
      </div>
    </>
  );
}

export default Filters;
