import { useMemo } from "react";
import "./style.scss";

function SubheaderTimePeriod(data) {
  let timePeriod = useMemo(() => {
    if (!data) {
      return null;
    } else {
      let subheadingDateRange = data.data.label;
      if (/trailing/i.test(subheadingDateRange)) {
        return subheadingDateRange.replace(/trailing/i, "in the previous").toLowerCase();
      } else if (/td/i.test(subheadingDateRange)) {
        if (/y/i.test(subheadingDateRange)) {
          return subheadingDateRange.replace(/ytd/i, "this year to date").toLowerCase();
        } else if (/q/i.test(subheadingDateRange)) {
          return subheadingDateRange.replace(/qtd/i, "this quarter to date").toLowerCase();
        } else if (/m/i.test(subheadingDateRange)) {
          return subheadingDateRange.replace(/mtd/i, "this month to date").toLowerCase();
        } else if (/w/i.test(subheadingDateRange)) {
          return subheadingDateRange.replace(/wtd/i, "this week to date").toLowerCase();
        }
      } else {
        if (subheadingDateRange) {
          return subheadingDateRange.toLowerCase();
        } else {
          return null;
        }
      }
    }
  }, [data]);

  return <span> {timePeriod}</span>;
}

export default SubheaderTimePeriod;
