import { useRef, useState, useEffect, useCallback, Children, cloneElement, useMemo } from "react";
import * as d3 from "d3";
import Legend from "../Legend";
import Symbols from "../Symbols";
import Tooltip from "../Tooltip";
import "./style.scss";

const margin = {
  left: 10,
  top: 10,
  right: 10,
  bottom: 10,
};

const padding = 15;

const graphActions = {
  refreshData: "REFRESH_DATA",
  toggleItem: "TOGGLE_ITEM",
};

Object.freeze(graphActions);

function Graph({ className, data, sortedUids, children, legend = true, keys, categories, onToggle, axisCount, isReady, onGraphReady }) {
  const divRef = useRef(null);
  const svgRef = useRef(null);
  const [{ width, height }, setDimensions] = useState({ width: 0, height: 0 });
  const [scales, setScales] = useState({});
  const [{ textSize, offset, accountedFor }, setPosition] = useState({
    textSize: { width: 0, height: 0 },
    offset: { x: 0, y: 0 },
    accountedFor: {},
    positions: {},
  });
  const [tooltip, setTooltip] = useState({ x: 0, y: 0, data: [], color: "#000000", isVisible: false });

  const verticalAxisCount = useMemo(() => {
    return Children.toArray(children).reduce((acc, cur) => {
      if (!cur.props.className?.includes("axis") || !["left", "right"].includes(cur.props.position)) return acc;
      return acc + 1;
    }, 0);
  }, [children]);

  const horizontalAxisCount = useMemo(() => {
    return Children.toArray(children).reduce((acc, cur) => {
      if (!cur.props.className?.includes("axis") || !["top", "bottom"].includes(cur.props.position)) return acc;
      if (cur.props.type === "percentage") return 1;
      return acc + 1;
    }, 0);
  }, [children]);

  useEffect(() => {
    let resizeTimer;
    function handleResize() {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        if (!divRef || !divRef.current) return;
        const width = divRef.current.offsetWidth;
        const height = divRef.current.offsetHeight;
        const iWidth = width - margin.left - margin.right - verticalAxisCount * textSize.width - 2 * padding;
        const iHeight = height - margin.top - margin.bottom - horizontalAxisCount * textSize.height - 2 * padding;
        setDimensions({ width: iWidth, height: iHeight });
      }, 50);
    }
    let observer = new ResizeObserver(handleResize);
    observer.observe(divRef.current);
    return () => observer.disconnect();
  }, [textSize.width, textSize.height, horizontalAxisCount, verticalAxisCount]);

  useEffect(() => {
    const scales = Children.toArray(children).reduce((scales, child) => {
      if (!child.props.position) return scales;
      let { domain: domainFunc, type, name, position, dataSource: src, range: rangeFunc } = child.props;
      const isHorizontal = ["top", "bottom"].includes(position);
      let min = padding;
      let max = isHorizontal ? width + padding : height + padding;
      let defRange = isHorizontal ? [min, max] : [max, min];
      let range = rangeFunc ? rangeFunc(min, max, isHorizontal) : defRange;
      let tempData;
      if (Array.isArray(data)) tempData = data;
      else if (src) tempData = data[src];
      else tempData = [].concat(...Object.values(data));
      let filtered = tempData.filter((item) => item.isVisible);
      let sorted = filtered;

      if (type === "percentage") type = "linear";

      let domItems = sorted.filter((row) => row.items.find((item) => item[name] !== undefined));
      let domainVal = domainFunc(domItems);
      if (type === "band") domainVal = sortedUids;
      let scale = d3[`scale${type[0].toUpperCase() + type.slice(1)}`]().domain(domainVal);
      scale = (type === "time" ? scale : type === "band" ? scale.padding(0.2) : scale.nice()).range(range);
      scales[name] = scale;
      return scales;
    }, {});

    setScales(scales);
  }, [width, height, children, data, sortedUids]);

  useEffect(() => {
    if (!isReady) {
      setPosition({ textSize: { width: 0, height: 0 }, offset: { x: 0, y: 0 }, accountedFor: {}, positions: {} });
    }
  }, [isReady]);

  const handleEnter = useCallback(({ x, y, data, color }) => {
    setTooltip({
      x,
      y,
      data,
      color,
      isVisible: true,
    });
  }, []);

  const handleMove = useCallback(({ x, y }) => {
    setTooltip((prev) => {
      return { ...prev, x, y };
    });
  }, []);

  const handleLeave = useCallback(() => {
    setTooltip((prev) => ({ ...prev, isVisible: false }));
  }, []);

  const handleAxisDraw = useCallback(({ textSize, offset, name, position }) => {
    setPosition((prev) => {
      const { textSize: tSize, offset: off } = prev;
      let width, height;
      width = textSize.width || tSize.width;
      height = textSize.height || tSize.height;

      return {
        textSize: { width, height },
        offset: { x: offset.x || off.x, y: offset.y || off.y },
        accountedFor: { ...prev.accountedFor, [name]: true },
        positions: { ...prev.positions, [position]: true },
      };
    });
  }, []);

  useEffect(() => {
    if (!isReady) return;
    //Make sure at least 2 axis are showing and then wait a quarter of a second for everything to settle
    if (Object.keys(accountedFor).length >= 2) setTimeout(() => onGraphReady(true), 250);
  }, [isReady, axisCount, accountedFor, onGraphReady]);

  return (
    <div className={`${className} graph-wrapper`} ref={divRef}>
      <svg className="graph-svg" ref={svgRef} onMouseLeave={() => setTooltip((prev) => ({ ...prev, isVisible: false }))}>
        <g transform={`translate(${margin.left}, ${margin.top})`}>
          {useMemo(
            () => (
              <Symbols />
            ),
            []
          )}
          {useMemo(() => {
            let lineXOff = scales.x && scales.x.bandwidth ? scales.x.bandwidth() / 2 : 0;
            let lineYOff = scales.y && scales.y.bandwidth ? scales.y.bandwidth() / 2 : 0;

            return Children.map(children, (child) => {
              if (!child || !data) return;
              const type = child.props.type;
              let src = child.props.dataSource;
              let tempOffset = {
                x: (type === "line" ? lineXOff : 0) + offset.x,
                y: (type === "line" ? lineYOff : 0) + offset.y,
              };

              if (!Array.isArray(data) && !src) {
                if (["bar", "line"].includes(type)) {
                  src = child.props.name;
                } else {
                  src = Object.keys(data)[0];
                }
              }

              let properties = {
                data: (data[src] || data).filter((item) => item.isVisible),
                width,
                height,
                margin,
                scales,
                padding,
                onEnter: handleEnter,
                onMove: handleMove,
                onLeave: handleLeave,
                onAxisDraw: handleAxisDraw,
                offset: tempOffset,
                textSize,
              };

              return cloneElement(child, properties);
            });
          }, [data, width, height, scales, handleEnter, handleMove, handleLeave, handleAxisDraw, textSize, children, offset.x, offset.y])}
          {useMemo(() => {
            if (!legend) return null;
            return (
              <Legend
                categories={categories}
                data={[].concat(...(Array.isArray(data) ? data : Object.values(data)), ...(keys || []))}
                margin={margin}
                svg={svgRef}
                svgWidth={width + margin.left + margin.right + 2 * padding + textSize.width}
                svgHeight={height + margin.top + margin.bottom + 2 * padding + textSize.height}
                onToggle={onToggle}
              />
            );
          }, [legend, data, keys, height, width, textSize.height, textSize.width, categories, onToggle])}
          <Tooltip {...tooltip} width={divRef.current ? divRef.current.offsetWidth : 0} height={divRef.current ? divRef.current.offsetHeight : 0} />
        </g>
      </svg>
    </div>
  );
}

export default Graph;
