import { useState, useEffect, useRef, useCallback } from "react";
import { DateTime } from "luxon";
import RangeSelector from "../RangeSelector";
import IntervalSelector from "../IntervalSelector";
import FilterSelector from "../FilterSelector";
import useParams from "../../Hooks/useParams";
import calculateDateRange from "../../Utilities/calculateDateRange";
import "./style.scss";

function DateRange({ dateRange: initialDateRange, interval: initialInterval, filters: initialFilters, filterTypes }) {
  let { searchParams, updateParams } = useParams();
  const [dateRange, setDateRange] = useState(getDateRangeFromParams(searchParams));
  const [filters, setFilters] = useState(getFiltersFromParams(searchParams));
  const [current, setCurrent] = useState(searchParams.current);
  const [editing, setEditing] = useState(null);

  const prevDateRange = useRef();
  const prevCurrent = useRef();

  //Set dateRange if received from api
  useEffect(() => {
    if ((dateRange && (dateRange.interval || !dateRange.interval === !initialInterval)) || !initialDateRange.startDate) return;
    setDateRange({ ...initialDateRange, interval: initialInterval });
    setCurrent(initialDateRange.label);
  }, [dateRange, initialDateRange, initialInterval]);

  //Set filters if received from api
  useEffect(() => {
    if (!initialFilters || !initialFilters.length) return;
    let newFilters = getFiltersFromParams({ filters: initialFilters });
    setFilters(newFilters);
  }, [initialFilters]);

  //If the date is changed update params
  useEffect(() => {
    if (!prevDateRange.current) {
      prevDateRange.current = dateRange;
      return;
    }
    if ((!dateRange || prevDateRange.current === dateRange) && prevCurrent.current === current) return;
    prevCurrent.current = current;
    prevDateRange.current = dateRange;
    let paramsObj = getParamsObject(dateRange, searchParams.where, dateRange.interval);
    if (current) {
      paramsObj.current = dateRange.label;
      paramsObj.checked = undefined;
    } else paramsObj.current = undefined;
    updateParams(paramsObj);
  }, [dateRange, current, updateParams, searchParams.where]);

  const onDateChange = useCallback((dateRange, category) => {
    let label = category === "To Date" || ["Today", "Yesterday"].includes(dateRange.label) ? dateRange.label : `${category} ${dateRange.label}`;
    setDateRange((prevDateRange) => {
      let obj = { startDate: dateRange.startDate, endDate: dateRange.endDate, label };
      if (prevDateRange.interval) obj.interval = dateRange.interval;
      return obj;
    });
    setEditing(null);
  }, []);

  const onIntervalChange = useCallback((interval) => {
    setDateRange((prevDateRange) => ({ ...prevDateRange, interval }));
    setEditing(null);
  }, []);

  const onFilterChange = useCallback(
    (filters) => {
      let newFilters = filters.filter(({ filterType, inclusion, filter }) => filterType && filter);
      updateParams({
        filters: newFilters.map(({ filterType, inclusion, filter }) => `${filterType.replace(/\s/gm, "_")}+${inclusion ? "includes" : "excludes"}+${filter}`),
      });
      setFilters(newFilters);
      setEditing(null);
    },
    [updateParams]
  );

  const onCurrentChange = useCallback(() => {
    setCurrent((prevCurrent) => (prevCurrent ? undefined : dateRange.label));
  }, [dateRange]);

  if (!dateRange) return null;

  return (
    <div className="range-picker">
      <div className="date-range-link-wrapper">
        <span
          className={`date-range-link ${editing === "dateRange" ? "active" : ""}`}
          onClick={() => setEditing((prev) => prev !== "dateRange" && "dateRange")}
        >
          {dateRange.label}
        </span>
        {editing !== "dateRange" ? null : (
          <RangeSelector
            dateRanges={dateRanges}
            onDateChange={onDateChange}
            onCurrentChange={onCurrentChange}
            onExit={() => setEditing(null)}
            current={current}
            includeInterval={dateRange.interval}
            label={dateRange.label}
          />
        )}
      </div>
      {dateRange.interval ? " by " : ""}
      {dateRange.interval && (
        <div className="date-range-link-wrapper">
          <span className={`date-range-link ${editing === "interval" ? "active" : ""}`} onClick={() => setEditing((prev) => prev !== "interval" && "interval")}>
            {dateRange.interval}
          </span>
          {editing !== "interval" ? null : <IntervalSelector intervals={intervals} onIntervalChange={onIntervalChange} onExit={() => setEditing(null)} />}
        </div>
      )}
      {(() => {
        if (!filterTypes || !filterTypes.length) return null;
        return (
          <>
            {filters.length && filters[0].filterType ? " filtered by " : " with "}
            <div className="date-range-link-wrapper">
              <span className={`date-range-link ${editing === "filter" ? "active" : ""}`} onClick={() => setEditing((prev) => prev !== "filter" && "filter")}>
                {filters.length && filters[0].filterType
                  ? filters.map((filter) => `${filter.filterType} ${filter.inclusion ? "includes" : "excludes"} ${filter.filter}`).join(" and ")
                  : "no filter"}
              </span>
              {editing !== "filter" ? null : (
                <FilterSelector filterTypes={filterTypes} initialFilters={filters} onFilterChange={onFilterChange} onExit={() => setEditing(null)} />
              )}
            </div>
          </>
        );
      })()}
    </div>
  );
}

const dateRanges = [
  {
    category: "Last",
    options: [
      {
        label: "Year",
        startDate: () => DateTime.now().minus({ years: 1 }).startOf("year"),
        endDate: () => DateTime.now().minus({ years: 1 }).endOf("year"),
        interval: "Month",
      },
      {
        label: "Quarter",
        startDate: () => DateTime.now().minus({ quarters: 1 }).startOf("quarter"),
        endDate: () => DateTime.now().minus({ quarter: 1 }).endOf("quarter"),
        interval: "Week",
      },
      {
        label: "Month",
        startDate: () => DateTime.now().minus({ months: 1 }).startOf("month"),
        endDate: () => DateTime.now().minus({ months: 1 }).endOf("month"),
        interval: "Day",
      },
      {
        label: "Week",
        startDate: () => DateTime.now().minus({ weeks: 1 }).startOf("week").minus({ days: 1 }),
        endDate: () => DateTime.now().minus({ weeks: 1 }).endOf("week").minus({ days: 1 }),
        interval: "Day",
      },
      {
        label: "Yesterday",
        startDate: () => DateTime.now().minus({ days: 1 }).startOf("day"),
        endDate: () => DateTime.now().minus({ days: 1 }).endOf("day"),
        interval: "Day",
      },
    ],
  },
  {
    category: "This",
    options: [
      {
        label: "Year",
        startDate: () => DateTime.now().startOf("year"),
        endDate: () => DateTime.now().endOf("year"),
        interval: "Month",
      },
      {
        label: "Quarter",
        startDate: () => DateTime.now().startOf("quarter"),
        endDate: () => DateTime.now().endOf("quarter"),
        interval: "Week",
      },
      {
        label: "Month",
        startDate: () => DateTime.now().startOf("month"),
        endDate: () => DateTime.now().endOf("month"),
        interval: "Day",
      },
      {
        label: "Week",
        startDate: () => DateTime.now().startOf("week").minus({ days: 1 }),
        endDate: () => DateTime.now().endOf("week").minus({ days: 1 }),
        interval: "Day",
      },
      {
        label: "Today",
        startDate: () => DateTime.now().startOf("day"),
        endDate: () => DateTime.now().endOf("day"),
        interval: "Day",
      },
    ],
  },
  {
    category: "To Date",
    options: [
      { label: "All Time", startDate: () => DateTime.local(2018), endDate: () => DateTime.now().endOf("day"), interval: "Month" },
      { label: "YTD", startDate: () => DateTime.now().startOf("year"), endDate: () => DateTime.now().endOf("day"), interval: "Month" },
      { label: "QTD", startDate: () => DateTime.now().startOf("quarter"), endDate: () => DateTime.now().endOf("day"), interval: "Week" },
      { label: "MTD", startDate: () => DateTime.now().startOf("month"), endDate: () => DateTime.now().endOf("day"), interval: "Day" },
      { label: "WTD", startDate: () => DateTime.now().startOf("week").minus({ days: 1 }), endDate: () => DateTime.now().endOf("day"), interval: "Day" },
    ],
  },
  {
    category: "Trailing",
    options: [
      {
        label: "12 Months",
        startDate: () => DateTime.now().minus({ years: 1 }).startOf("month"),
        endDate: () => DateTime.now().endOf("day"),
        interval: "Month",
      },
      {
        label: "6 Months",
        startDate: () => DateTime.now().minus({ months: 6 }).startOf("day"),
        endDate: () => DateTime.now().endOf("day"),
        interval: "Month",
      },
      {
        label: "90 Days",
        startDate: () => DateTime.now().minus({ days: 90 }).startOf("day"),
        endDate: () => DateTime.now().endOf("day"),
        interval: "Week",
      },
      {
        label: "30 Days",
        startDate: () => DateTime.now().minus({ days: 30 }).startOf("day"),
        endDate: () => DateTime.now().endOf("day"),
        interval: "Day",
      },
      {
        label: "7 Days",
        startDate: () => DateTime.now().minus({ days: 7 }).startOf("day"),
        endDate: () => DateTime.now().endOf("day"),
        interval: "Day",
      },
    ],
  },
];

const intervals = ["Year", "Quarter", "Month", "Week", "Day"];

function getDateRangeFromCurrent(current) {
  let [category, ...label] = current.split(" ");
  if (dateRanges.find((range) => range.category === category)) {
    label = label.join(" ");
  } else {
    switch (current) {
      case "Today":
        category = "This";
        break;
      case "Yesterday":
        category = "Last";
        break;
      default:
        category = "To Date";
    }
    label = current;
  }
  let dateRange = dateRanges.find((range) => range.category === category).options.find((option) => option.label === label);
  return dateRange;
}

function getDateRangeFromParams(params) {
  let startDate, endDate, interval;
  if (params.current) {
    let [category, ...label] = params.current.split(" ");
    if (dateRanges.find((range) => range.category === category)) {
      label = label.join(" ");
    } else {
      switch (params.current) {
        case "Today":
          category = "This";
          break;
        case "Yesterday":
          category = "Last";
          break;
        default:
          category = "To Date";
      }
      label = params.current;
    }
    let dateRange = dateRanges.find((range) => range.category === category).options.find((option) => option.label === label);
    if (dateRange) {
      startDate = dateRange.startDate().toMillis();
      endDate = dateRange.endDate().toMillis();
    } else {
      let dateShift = DateTime.fromMillis(parseInt(params.endDate)).diffNow("milliseconds").toObject().milliseconds;
      startDate = parseInt(params.startDate) - dateShift;
      endDate = DateTime.now().toMillis();
      interval = params.interval;
    }
  } else if (params.startDate && params.endDate) {
    startDate = params.startDate;
    endDate = params.endDate;
    interval = params.interval;
  } else if (params.where) {
    let where = decodeURIComponent(params.where);
    let match = where.match(/\w{2}.date\w+:\[(\d+) TO (\d+)\]/);
    if (match) [, startDate, endDate] = match;
  }
  if (!startDate || !endDate) {
    return null;
  }

  let dateRange = calculateDateRange({ startDate, endDate });

  return { ...dateRange, interval };
}

function getParamsObject(dateRange, where) {
  let paramsObj = {};

  let startDate, endDate;
  if (typeof dateRange.startDate === "function") {
    startDate = dateRange.startDate().toMillis();
    endDate = dateRange.endDate().toMillis();
  } else {
    startDate = dateRange.startDate;
    endDate = dateRange.endDate;
  }

  if (where) {
    let fieldName;
    let newWhere = decodeURIComponent(where);
    let match = /\w{2}\.date\w+/.exec(newWhere);

    if (match) {
      fieldName = match[0];
      newWhere = newWhere.replace(/\w{2}\.date\w+:\[\d+ TO \d+\]/, `${fieldName}:[${startDate} TO ${endDate}]`);
    } else {
      let abbreviation = /\w{2}\./.exec(newWhere);
      fieldName = `${abbreviation}dateAdded`;
      newWhere += `${fieldName}:[${startDate} TO ${endDate}]`;
    }

    paramsObj.where = newWhere;
  } else {
    paramsObj.startDate = startDate;
    paramsObj.endDate = endDate;
  }
  if (dateRange.interval) {
    paramsObj.interval = dateRange.interval;
  }
  return paramsObj;
}

function getFiltersFromParams(params) {
  if (!params.filters) return [];
  let filters = params.filters;
  if (!Array.isArray(filters)) filters = [filters];
  filters = filters.map((filterString) => {
    let decoded = decodeURIComponent(filterString);
    decoded = decoded.replace(/(?:\s)(includes|excludes)(?:\s)/gm, (_, val) => `+${val}+`);
    let [filterType, inclusion, filter] = decoded.split("+");
    return { filterType: filterType.replace(/_/gm, " "), inclusion: inclusion === "includes", filter };
  });
  return filters;
}

//@TODO: Change client to only send startdate and enddate on request and handle "current" clientside

export { DateRange, dateRanges, getDateRangeFromParams, getParamsObject, getDateRangeFromCurrent };
