import { Routes, Route, Navigate } from "react-router-dom";
import PageNav from "../../Components/PageNav";
import ByClient from "./SubPages/ByClient";
import OverTime from "./SubPages/OverTime";
import ByEmployee from "./SubPages/ByEmployee";
import "./style.scss";

function Bookings() {
  return (
    <>
      <PageNav
        title="Bookings"
        links={[
          { label: "Over Time", href: "over-time" },
          { label: "By Client", href: "by-client" },
          { label: "By Employee", href: "by-employee" },
        ]}
      />
      <div className="page">
        <Routes>
          <Route path={`over-time`} element={<OverTime />} />
          <Route path={`by-employee`} element={<ByEmployee />} />
          <Route path={`by-client`} element={<ByClient />} />
          <Route index element={<Navigate to={`over-time`} />} />
        </Routes>
      </div>
    </>
  );
}

export default Bookings;
