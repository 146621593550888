import { useMemo } from "react";
import useNewDataFetcher from "../../../../Hooks/newUseDataFetcher";
import GraphTable from "../../../../Components/GraphTable";
import SubheaderTimePeriod from "../../../../Components/SubheaderTimePeriod";

function ByJobOrder() {
  const dataURL = "/api/v1/candidate/status"
  const { data, dateRange, filterTypes, filters, isReady } = useNewDataFetcher({ endPoint: dataURL });

  const { activeCandidates, jobOrders } = useMemo(() => {
    if (!data) return { activeCandidates: 0, jobOrders: 0 };
    let activeCandidates = data.data.reduce((acc, cur) => {
      acc += cur.numOfCandidates;
      return acc;
    }, 0);
    return { activeCandidates: Math.round(activeCandidates / data.data.length), jobOrders: data.data.length };
  }, [data]);

  return (
    <GraphTable data={data} dateRange={dateRange} filterTypes={filterTypes} filters={filters} isReady={isReady} dataURL={dataURL}>
      <h1 className="page-heading">How many candidates are in each stage for my job orders?</h1>
      <h2 className="page-subheading">
        <span className="page-data">{activeCandidates}</span> active candidates on average for {jobOrders} job orders created <SubheaderTimePeriod data={dateRange} />
      </h2>
    </GraphTable>
  );
}

export default ByJobOrder;
