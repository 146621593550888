import { Routes, Route, Navigate } from "react-router-dom";
import PageNav from "../../Components/PageNav";
import ClientCorporations from "./SubPages/ClientCorporations";
import "./style.scss";

function Corporations() {
    return (
        <>
            <PageNav
                title="Corporations"
                links={[
                    { label: "Client Corporations", href: "client-corporations" },
                ]}
            />
            <div className="page">
                <Routes>
                    <Route path={`client-corporations`} element={<ClientCorporations />} />
                    <Route index element={<Navigate to={`client-corporations`} />} />
                </Routes>
            </div>
        </>
    );
}

export default Corporations;