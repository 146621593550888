import { useState, useEffect, useMemo, useCallback, useContext } from "react";
import axios from "axios";
import { ToastContext } from "../../Contexts/toastContext";
import SubscriptionCard from "../SubscriptionCard";
import "./style.scss";

function CurrentSubscription({ customer, products, getCustomer, isBusy, setIsBusy }) {
  const { addToast } = useContext(ToastContext);
  const [quantities, setQuantities] = useState({});

  useEffect(() => {
    if (!customer.subscriptions.length) return;
    let quantities = {};
    for (let subscription of customer.subscriptions) {
      for (let item of subscription.items.data) {
        quantities[item.price.product] = item.quantity;
      }
    }
    setQuantities(quantities);
  }, [customer.subscriptions]);

  const subscriptions = useMemo(() => {
    if (!products.length) return [];
    return customer.subscriptions.map((subscription) => ({
      ...subscription,
      items: subscription.items.data.map((item) => {
        return { ...item, product: products.find((product) => item.price.product === product.id) };
      }),
    }));
  }, [customer.subscriptions, products]);

  const handleUpdate = useCallback(() => {
    let changes = [];
    for (let subscription of customer.subscriptions) {
      for (let item of subscription.items.data) {
        let quantity = parseInt(quantities[item.price.product]);
        if (item.quantity !== quantity && !isNaN(quantity)) changes.push({ subscriptionId: subscription.id, productId: item.price.product, quantity });
      }
    }
    if (!changes.length) return;
    setIsBusy(true);
    let promises = [];
    for (let { subscriptionId, productId, quantity } of changes) {
      promises.push(axios.put(`/api/v1/admin/subscription/${subscriptionId}`, { productId, quantity }));
    }
    Promise.all(promises)
      .then((resp) => {
        getCustomer();
        addToast({ type: "success", message: "Subscription Updated" });
      })
      .catch((err) => {
        console.log(err);
        addToast({ type: "error", message: "Subscription failed to update" });
      })
      .finally(() => {
        setIsBusy(false);
      });
  }, [quantities, customer.subscriptions, addToast, getCustomer, setIsBusy]);

  const handleQuantityChange = useCallback(({ productId, value }) => {
    setQuantities((prevQuantities) => ({ ...prevQuantities, [productId]: value }));
  }, []);

  const subtotal = useMemo(() => {
    let total = 0;
    for (let subscription of subscriptions) {
      for (let item of subscription.items) {
        total += item.price.unit_amount * quantities[item.price.product];
      }
    }
    return (total / 100).toFixed(2);
  }, [subscriptions, quantities]);

  return (
    <div className="subscription-panel">
      {subscriptions.map((subscription) => {
        return subscription.items.map((item) => (
          <SubscriptionCard key={item.id} subscription={item} quantity={quantities[item.price.product]} setQuantity={handleQuantityChange} disabled={isBusy} />
        ));
      })}
      <div className="subscription-panel-bottom">
        <div className="subscription-subtotal-wrapper">
          <span className="subscription-subtotal-label">Subtotal: </span>
          <span className="subscription-subtotal">${subtotal}/month</span>
        </div>
        <button className="subscription-update-button" onClick={handleUpdate} disabled={isBusy}>
          Update
        </button>
      </div>
    </div>
  );
}

export default CurrentSubscription;
