import { useState } from "react";
import "./style.scss";

function FilterCard({ filterId, onDelete, handleTypeChange, handleTagChange, handleInclusionChange, filterTypes, filters, filterTags }) {
  const [filterTypeListOn, setFilterTypeListOn] = useState(true);
  const [filterTagListOn, setFilterTagListOn] = useState(false);

  return (
    <div className="filter-card">
      <input
        className="filter-card-taglist"
        type="text"
        value={filters[filterId].filterType}
        onChange={(e) => {
          handleTypeChange(e.target.value, filterId);
        }}
        onClick={() => {
          setFilterTypeListOn((prevValue) => !prevValue);
          setFilterTagListOn(false);
        }}
      />
      <div className={filterTypeListOn ? "filter-type-list-on" : "filter-type-list-off"}>
        {Object.values(filterTypes)
          .filter((type) => {
            if (type.toLowerCase().includes(filters[filterId].filterType.toLowerCase())) {
              return type;
            } else {
              return null;
            }
          })
          .map((type, index) => {
            return (
              <button
                className="filter-type-item"
                key={index}
                onClick={() => {
                  handleTypeChange(type, filterId);
                  setFilterTypeListOn(false);
                }}
              >
                {type}
              </button>
            );
          })}
      </div>
      <select
        className={`inclusion-toggle ${filters[filterId].filterType ? "filtertypeSelected" : "filterTypeRequired"}`}
        value={filters[filterId].inclusion}
        onChange={(e) => {
          handleInclusionChange(e.target.value, filterId);
        }}
      >
        <option value={true} className="toggle-choice">
          Includes
        </option>
        <option value={false} className="toggle-choice">
          Excludes
        </option>
      </select>
      <div className={filters[filterId].filterType ? "filtertypeSelected" : "filterTypeRequired"}>
        <input
          className="filter-card-tag-list"
          type="text"
          value={filters[filterId].filter}
          onClick={() => {
            setFilterTagListOn(true);
            setFilterTypeListOn(false);
          }}
          onChange={(e) => {
            handleTagChange(e.target.value, filterId);
          }}
        />
        <div className={filterTagListOn ? "filter-tag-list-on" : "filter-tag-list-off"}>
          {Object.values(filterTags)
            .filter((tag) => {
              if (tag.toLowerCase().includes(filters[filterId].filter.toLowerCase())) {
                return tag;
              } else {
                return null;
              }
            })
            .map((tag, index) => {
              return (
                <button
                  className="filter-type-item"
                  key={index}
                  onClick={() => {
                    handleTagChange(tag, filterId);
                    setFilterTagListOn(false);
                  }}
                >
                  {tag}
                </button>
              );
            })}
        </div>
      </div>
      <button
        className="delete-filter"
        onClick={() => {
          onDelete(filterId);
        }}
      >
        Delete
      </button>
    </div>
  );
}

export default FilterCard;
