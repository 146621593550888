import { Link } from "react-router-dom";
import ColumnFilterList from "../ColumnFilterList";
import { formatDate, formatNumber } from "../../Utilities/formatting";
import "./style.scss";

function Table({
  data,
  itemsPerPage,
  itemCount,
  sort,
  page,
  columnFilters,
  selectedTotals,
  overallTotals,
  onToggle,
  onSortChange,
  onPageChange,
  onFilterChange,
  onExpand,
  hideChecks,
  hideTotals,
  hidePagination,
  isCard,
}) {
  const { direction, fieldName } = sort;
  let allChecked = data.rows.reduce((acc, cur) => cur.isVisible && acc, true);

  const shouldHideChecks = hideChecks || isCard;

  return (
    <div className={isCard ? "table-wrapper dashboard-card" : "table-wrapper"}>
      <table className="data-table">
        <thead className="sticky-thead">
          <tr>
            {shouldHideChecks ? null : <th></th>}
            {data.columns.map((column) => {
              return (
                <th key={`column-label-${column.label}`}>
                  <span className="column-label">{column.label}</span>
                </th>
              );
            })}
          </tr>
          <tr>
            {shouldHideChecks ? null : (
              <th className="check-all">
                <input
                  type="checkbox"
                  checked={allChecked}
                  onChange={() => {
                    onToggle(
                      data.rows.map((row) => ({
                        uid: row.uid,
                        isVisible: !allChecked,
                      }))
                    );
                  }}
                />
              </th>
            )}
            {data.columns.map((column, index) => {
              return (
                <th key={`column-sort-${column.label}`}>
                  <div className="column-sort-table-header">
                    <div className="sort-container">
                      <div
                        className={`sort-up ${direction === "up" && fieldName === column.fieldName ? "active" : ""}`}
                        onClick={() => {
                          onSortChange({
                            direction: "up",
                            fieldName: column.fieldName,
                          });
                        }}
                      ></div>
                      <div
                        className={`sort-down ${direction === "down" && fieldName === column.fieldName ? "active" : ""}`}
                        onClick={() => {
                          onSortChange({
                            direction: "down",
                            fieldName: column.fieldName,
                          });
                        }}
                      ></div>
                    </div>

                    <ColumnFilterList
                      type={column.type}
                      columnFilters={columnFilters[column.fieldName]}
                      onFilterApply={(newFilters) => onFilterChange({ [column.fieldName]: newFilters })}
                      needsFlip={index >= data.columns.length / 2}
                    />
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data.rows.reduce((rows, dataRow, rIdx) => {
            const { isVisible, uid, isExpanded, ...rest } = dataRow;
            let genRows = [dataRow].map((item, aIdx) => {
              let row = (
                <tr className={aIdx > 0 ? "inset" : ""} key={`row-${item.uid}`}>
                  {shouldHideChecks ? null : (
                    <td className="check">
                      <input
                        type="checkbox"
                        checked={item.isVisible}
                        onChange={() => {
                          onToggle({ uid: item.uid, isVisible: !item.isVisible });
                        }}
                      />
                    </td>
                  )}
                  {data.columns.map((column, cIdx) => {
                    let value, hover;
                    if (
                      column.link &&
                      item[column.fieldName].value &&
                      !item[column.fieldName].value.toString().includes("None") &&
                      item[column.fieldName].value[0] !== "0"
                    ) {
                      value = (
                        <Link className="table-link" to={item[column.fieldName].link}>{`${column.prefix || ""}${formatValue(
                          item[column.fieldName].value,
                          column.type
                        )}${column.suffix || ""}`}</Link>
                      );
                    } else {
                      value = (
                        <span className="">{`${column.prefix || ""}${formatValue(item[column.fieldName].value, column.type)}${column.suffix || ""}`}</span>
                      );
                    }
                    if (column.hover) hover = <div className="hover-container">{item[column.fieldName].hover.slice(0, 255)}</div>;

                    return (
                      <td
                        className={`${cIdx || aIdx || !rest.isExpandable ? "" : "expandable"} ${isExpanded ? "expanded" : ""} ${column.hover ? "hoverable" : ""
                          }`}
                        key={`cell-${rIdx + aIdx}-${cIdx}`}
                        onClick={cIdx || !rest.isExpandable ? () => { } : () => onExpand({ uid: item.uid, isExpanded })}
                      >
                        {value}
                        {hover}
                      </td>
                    );
                  })}
                </tr>
              );
              return row;
            });
            return [rows, ...genRows];
          }, [])}
          {(() => {
            if (hideTotals || !selectedTotals || !overallTotals) return null;
            return (
              <>
                <tr className="selectedRow">
                  <td className="selectedRowtd">{"Selected"}</td>
                  {selectedTotals.map((item, idx) => {
                    if (!idx && shouldHideChecks) return null;
                    return (
                      <td key={`checked${item.fieldName}`} className="selectedRowtd">
                        {formatNumber(item.value, false)}
                      </td>
                    );
                  })}
                </tr>
                <tr className="totalRow">
                  <td>{"Total"}</td>
                  {overallTotals.map((item, idx) => {
                    if (!idx && shouldHideChecks) return null;
                    return <td key={`total${item.fieldName}`}>{formatNumber(item.value, false)}</td>;
                  })}
                </tr>
              </>
            );
          })()}
        </tbody>
      </table>
      {(() => {
        let pageCount = Math.ceil(itemCount / itemsPerPage);
        let startOfRange = page * itemsPerPage + 1;
        let endOfRange = startOfRange + data.rows.length - 1;
        let shouldHidePagination = pageCount <= 1 || hidePagination;
        let shouldHideItemCount = itemCount < 1;
        return (
          <div className="pagination-section">
            {shouldHideItemCount ? null : (
              <p>
                {"Showing "}
                <span className="current-items-label">
                  {startOfRange} - {endOfRange}
                </span>
                {" of "}
                <span className="total-items-label">{itemCount}</span>
              </p>
            )}
            {shouldHidePagination ? null : (
              <div className="pagination-button-wrapper">
                <button className="pagination-button" onClick={() => onPageChange(page - 1)} disabled={page === 0}>
                  Previous
                </button>
                {(() => {
                  let pageButtons = [];
                  for (let i = 0; i < pageCount; i++) {
                    pageButtons.push(
                      <button
                        key={`pagination-button-${i}`}
                        className={`pagination-button ${page === i ? "current-page" : ""}`}
                        onClick={() => onPageChange(i)}
                      >
                        {i + 1}
                      </button>
                    );
                  }
                  if (pageButtons.length <= 11) return pageButtons;
                  if (page < 5) return [...pageButtons.slice(0, 5), <span key="page-button-spacer-1">...</span>, ...pageButtons.slice(-3)];
                  if (page >= pageCount - 5) return [...pageButtons.slice(0, 3), <span key="page-button-spacer-1">...</span>, ...pageButtons.slice(-5)];
                  let centerStart = Math.max(page - 2, 4);
                  let centerEnd = Math.min(centerStart + 5, pageCount - 3);

                  return [
                    ...pageButtons.slice(0, 3),
                    <span key="page-button-spacer-1">...</span>,
                    ...pageButtons.slice(centerStart, centerEnd),
                    <span key="page-button-spacer-2">...</span>,
                    ...pageButtons.slice(-3),
                  ];
                })()}
                <button className="pagination-button" onClick={() => onPageChange(page + 1)} disabled={page === pageCount - 1}>
                  Next
                </button>
              </div>
            )}
          </div>
        );
      })()}
    </div>
  );
}

function formatValue(value, type) {
  if (type === "string") return value;
  if (type === "date") return formatDate(value);
  return formatNumber(value);
}

export default Table;
