import PageNav from "../../Components/PageNav";
import Dashboard from "../../Components/Dashboard";
import useNewDataFetcher from "../../Hooks/newUseDataFetcher";
import "./style.scss";

function DirectorOfSales() {
  const { data: apiData, isReady } = useNewDataFetcher({ endPoint: "/api/v1/dashboard/sales" });
  let { json, data } = apiData || { json: [], data: [] };
  return (
    <>
      <PageNav title="Director Of Sales" />
      <Dashboard json={json} data={data} isReady={isReady} />
    </>
  );
}

export default DirectorOfSales;
