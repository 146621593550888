import { useState, useEffect } from "react";
import axios from "axios";
import "./style.scss";
const { DateTime } = require("luxon");

function TransactionHistory() {
  const [transactionList, setTransactionList] = useState([]);
  const [upcomingCharge, setUpcomingCharge] = useState();
  useEffect(() => {
    axios
      .get("/api/v1/admin/transactions")
      .then((res) => {
        setTransactionList(res.data.history);
        setUpcomingCharge(res.data.upcoming);
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }, []);

  return (
    <>
      <h1 className="upcoming-charges-header">Upcoming Charges</h1>
      <div className="transaction-history-disclaimer">Charges may not appear immediately.</div>
      {upcomingCharge ? (
        <div className="upcoming-charges-segment">
          <div className="upcoming-charge-date">{`Charge Date: ${DateTime.fromSeconds(upcomingCharge.next_payment_attempt).toFormat("LLL dd yyyy")}`}</div>
          <div className="upcoming-charge-amount">{`Amount Due: $${(upcomingCharge.amount_due / 100).toFixed(2)}`}</div>
        </div>
      ) : (
        <div className="upcoming-charges-segment">
          <div>No Current Upcoming Charges</div>
        </div>
      )}
      <h1 className="transaction-header">Transaction History</h1>
      <div className="transaction-history">
        {transactionList.length !== 0 ? (
          transactionList.map((transaction) => {
            return (
              <div key={transaction.id} className="transaction-segment">
                <div>{`Transaction Date: ${DateTime.fromSeconds(transaction.created).toFormat("LLL dd yyyy")}`}</div>
                <div>{`Transaction Type: ${transaction.description}`}</div>
                <div>{`Amount: $${(transaction.amount / 100).toFixed(2)}`}</div>
                <div>{`Transaction Status: ${transaction.status}`}</div>
              </div>
            );
          })
        ) : (
          <h2>No Current Transactions</h2>
        )}
      </div>
    </>
  );
}

export default TransactionHistory;
