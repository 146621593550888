import { useState, useCallback, useContext } from "react";
import axios from "axios";
import { ToastContext } from "../../Contexts/toastContext";
import ChangePassword from "../../Components/ChangePassword";
import { isValidPassword } from "../../Utilities/validateInput";
import "./style.scss";

function UpdatePassword() {
  const [error, setError] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [isSending, setIsSending] = useState(false);
  const { addToast } = useContext(ToastContext);

  const handleSubmit = useCallback(
    (newPassword) => {
      if (!isValidPassword(oldPassword)) return setError("Old password must be a valid password");
      setIsSending(true);
      axios
        .put(`/api/v1/auth/update-password`, { currentPassword: oldPassword, newPassword })
        .then((resp) => {
          console.log(resp);
          addToast({ type: "success", message: "Password Changed" });
        })
        .catch((err) => {
          console.log(err);
          addToast({ type: "error", message: "Password Not Changed" });
          setIsSending(false);
        });
    },
    [oldPassword, addToast]
  );

  return (
    <div className="update-password">
      <label>Current Password</label>
      <p className="error-message">{error}</p>
      <input type="password" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} disabled={isSending} />
      <ChangePassword onSubmit={handleSubmit} disabled={isSending} />
    </div>
  );
}

export default UpdatePassword;
