import { useState, useEffect, useCallback, useContext } from "react";
import axios from "axios";
import { DateTime } from "luxon";
import ReportForm from "../../Components/ReportForm";
import { ToastContext } from "../../Contexts/toastContext";
import "./style.scss";

function Reports() {
  const [reports, setReports] = useState();
  const { addToast } = useContext(ToastContext);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    let cancel;

    axios
      .get(`/api/v1/reports`, {
        cancelToken: new CancelToken((c) => (cancel = c)),
      })
      .then((resp) => {
        setReports(resp.data);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log("Request Canceled");
        } else {
          console.log(err);
        }
      });

    return () => cancel();
  }, []);

  const handleDelete = useCallback(
    (reportId) => {
      setReports((prevReports) => prevReports.map((report) => (report.id !== reportId ? report : { ...report, isDisabled: true })));
      axios
        .delete(`/api/v1/reports/${reportId}`)
        .then((resp) => {
          addToast({ type: "success", message: "Report deleted" });
        })
        .catch((err) => {
          addToast({ type: "error", message: "Report not deleted" });
          console.log(err);
        })
        .finally(() => {
          setReports((prevReports) => {
            return prevReports.filter((report) => report.id !== reportId);
          });
        });
    },
    [addToast]
  );

  const handleUpdate = useCallback(
    (report) => {
      setReports((prevReports) => prevReports.map((prevReport) => ({ ...prevReport, isDisabled: prevReport.isDisabled || prevReport.id === report.id })));
      let updatedReport = { ...report, reference_date: DateTime.fromISO(report.reference_date).toUTC().toISO() };
      axios
        .put(`/api/v1/reports/${report.id}`, updatedReport)
        .then((resp) => {
          addToast({ type: "success", message: "Report Updated" });
        })
        .catch((err) => {
          addToast({ type: "error", message: "Report not updated" });
          console.log(err);
        })
        .finally(() => {
          setReports((prevReports) => prevReports.map((prevReport) => ({ ...prevReport, isDisabled: prevReport.id !== report.id && prevReport.isDisabled })));
        });
    },
    [addToast]
  );

  const handleToggle = useCallback(
    ({ id, is_active }) => {
      setReports((prevReports) => prevReports.map((prevReport) => ({ ...prevReport, isDisabled: prevReport.isDisabled || prevReport.id === id })));
      axios
        .put(`/api/v1/reports/${id}/${is_active ? "activate" : "deactivate"}`)
        .then((resp) => {
          addToast({ type: "success", message: `Report ${is_active ? "Unpaused" : "Paused"}` });
          setReports((prevReports) =>
            prevReports.map((prevReport) => {
              if (prevReport.id !== id) return prevReport;
              return { ...prevReport, isDisabled: false, is_active: is_active };
            })
          );
        })
        .catch((err) => {
          addToast({ type: "error", message: `Report not ${is_active ? "Unpaused" : "Paused"}` });
          setReports((prevReports) =>
            prevReports.map((prevReport) => {
              if (prevReport.id !== id) return prevReport;
              return { ...prevReport, isDisabled: false };
            })
          );
          console.log(err);
        });
    },
    [addToast]
  );

  let reportComponents;
  if (!reports) {
    reportComponents = <h2>Loading reports...</h2>;
  } else if (!reports.length) {
    reportComponents = <h2>You have no automated reports</h2>;
  } else {
    reportComponents = (
      <div className="reports-wrapper">
        {reports.map((report) => (
          <ReportForm
            key={report.id}
            report={report}
            handleDelete={handleDelete}
            handleUpdate={handleUpdate}
            handleToggle={handleToggle}
            disabled={report.isDisabled}
          />
        ))}
      </div>
    );
  }

  return (
    <div className="reports-page">
      <h1>Reports</h1>
      {reportComponents}
    </div>
  );
}

export default Reports;
