import { useState, useCallback, useContext } from "react";
import { DateTime } from "luxon";
import { EmailContext } from "../../Contexts/emailContext";
import ChipInput from "../ChipInput";
import Modal from "../Modal";
import "./style.scss";

function ReportForm({ report, handleUpdate, handleDelete, handleToggle, disabled }) {
  const [reportState, setState] = useState({
    url: report.url,
    subject: report.subject,
    message: report.message,
    frequency: report.frequency,
    emails: report.emails.join(", ") + ", ",
    //Does toLocal fix the time display issue?
    reference_date: DateTime.fromISO(report.reference_date).toLocal().toFormat("yyyy-MM-dd HH:mm"),
  });
  const [isOpen, setIsOpen] = useState(false);
  const { emails: emailOptions } = useContext(EmailContext);

  const { url, subject, message, frequency, emails, reference_date } = reportState;

  const handleDeleteOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleDeleteClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleChange = useCallback((e) => {
    let key = e.target.name;
    let value = e.target.value;
    setState((prevState) => {
      let newState = { ...prevState, [key]: value };
      return newState;
    });
  }, []);

  const referenceDateTip =
    "This is the date the report frequency is relative to. For example:\n\n" +
    'A reference date of "January 18th 2022" with a frequency of \n"Yearly" will send out a report every January 18th.\n\n' +
    'A reference date of "January 18th 2022" with a frequency of \n"Quarterly" will send out a report every quarter on the 18th.\n\n' +
    'A reference date of "January 18th 2022" with a frequency of \n"Monthly" will send out a report every month on the 18th.\n\n' +
    'A reference date of "Wednesday, January 18th 2022" with a \nfrequency of "Weekly" will send out a report every Wednesday.';

  return (
    <>
      <div className="report-form-row">
        <div className="report-form-left">
          <label className="report-form-label">URL</label>
          <input className="report-form-url" type="text" name="url" value={url} onChange={handleChange} disabled={disabled} />
          <label className="report-form-label">SUBJECT</label>
          <input className="report-form-subject" type="text" name="subject" value={subject} onChange={handleChange} disabled={disabled} />
          <label className="report-form-label">FREQUENCY</label>
          <select className="report-form-frequency" name="frequency" value={frequency} onChange={handleChange} disabled={disabled}>
            <option value="yearly">Yearly</option>
            <option value="quarterly">Quarterly</option>
            <option value="monthly">Monthly</option>
            <option value="weekly">Weekly</option>
            <option value="daily">Daily</option>
          </select>
          <label className="report-form-label">RECIPIENT</label>
          <ChipInput className="report-form-emails" name="emails" options={emailOptions} value={emails} onChange={handleChange} disabled={disabled} />
          <label className="report-form-label">REFERENCE DATE</label>
          <div className=" report-form-tip-label">
            <div className="report-form-tooltip">{referenceDateTip}</div>
          </div>
          <input className="report-form-date" type="datetime-local" name="reference_date" value={reference_date} onChange={handleChange} disabled={disabled} />
        </div>
        <div className="report-form-middle">
          <label className="report-form-label">MESSAGE</label>
          <textarea className="report-form-message" name="message" value={message} onChange={handleChange} disabled={disabled} />
        </div>
        <div className="report-form-right">
          <button
            className={`report-form-button ${report.is_active ? "report-form-pause" : "report-form-play"}`}
            onClick={() => handleToggle({ id: report.id, is_active: !report.is_active })}
            disabled={disabled}
          >
            {report.is_active ? "Pause" : "Resume"}
          </button>
          <button className="report-form-button report-form-update" onClick={() => handleUpdate({ id: report.id, ...reportState })} disabled={disabled}>
            Update Report
          </button>
          <button className="report-form-button report-form-delete" onClick={handleDeleteOpen} disabled={disabled}>
            Delete Report
          </button>
        </div>
      </div>
      <Modal className="delete-report-modal" title="Delete report" type="danger" isOpen={isOpen} onExit={handleDeleteClose}>
        <p>Are you sure you want to delete "{subject}" report?</p>
        <div className="delete-report-modal-buttons">
          <button onClick={handleDeleteClose}>Cancel</button>
          <button className="delete-report-modal-button" onClick={() => handleDelete(report.id)}>Delete</button>
        </div>
      </Modal>
    </>
  );
}

export default ReportForm;
