import useNewDataFetcher from "../../../../Hooks/newUseDataFetcher";
import GraphTable from "../../../../Components/GraphTable";

function ByRep() {
  const dataURL = "/api/v1/activity/sales-ratios/employee"
  const { data, dateRange, filterTypes, filters, isReady } = useNewDataFetcher({
    endPoint: dataURL,
  });

  return (
    <GraphTable data={data} dateRange={dateRange} filterTypes={filterTypes} filters={filters} isReady={isReady} dataURL={dataURL}>
      <h1 className="page-heading">What is the efficiency of my employees' activity?</h1>
      <h2 className="page-subheading">Activity ratios using rep</h2>
    </GraphTable>
  );
}

export default ByRep;
