import { useCallback } from "react";

function PhoneInput({ value, onChange, ...rest }) {
  const handleChange = useCallback(
    (e) => {
      let rawNumber = e.target.value.replace(/\D/gm, "").slice(0, 10);
      let formatted;
      if (rawNumber.length < 4) {
        formatted = rawNumber;
      } else if (rawNumber.length < 7) {
        formatted = `(${rawNumber.slice(0, 3)}) ${rawNumber.slice(3)}`;
      } else {
        formatted = `(${rawNumber.slice(0, 3)}) ${rawNumber.slice(3, 6)}-${rawNumber.slice(6)}`;
      }

      onChange({ target: { ...rest, value: formatted } });
    },
    [onChange, rest]
  );

  return <input type="tel" value={value} onChange={handleChange} {...rest} />;
}

export default PhoneInput;
