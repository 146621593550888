const filterFunctions = {
  gt: {
    types: ["number", "date"],
    symbol: "\u003e",
    func: (value = 0, comparison) => value > comparison,
  },
  lt: {
    types: ["number", "date"],
    symbol: "\u003c",
    func: (value = 0, comparison) => value < comparison,
  },
  gte: {
    types: ["number"],
    symbol: "\u2265",
    func: (value = 0, comparison) => value >= comparison,
  },
  lte: {
    types: ["number"],
    symbol: "\u2264",
    func: (value = 0, comparison) => value <= comparison,
  },
  eq: {
    types: ["number", "string"],
    symbol: "\u003d",
    func: (value, comparison) => {
      if (typeof value === "string") {
        return value.toString().toLowerCase().trim() === comparison.toString().toLowerCase().trim();
      } else {
        return parseFloat(value) === parseFloat(comparison);
      }
    },
  },
  ne: {
    types: ["number", "string"],
    symbol: "\u2260",
    func: (value, comparison) => {
      if (typeof value === "string" || typeof value === "string") {
        return value.toString().toLowerCase().trim() !== comparison.toString().toLowerCase().trim();
      } else {
        return parseFloat(value) !== parseFloat(comparison);
      }
    },
  },
  in: {
    types: ["string"],
    symbol: "includes",
    func: (value, comparison) => (value || "").trim().toLowerCase().includes(comparison.trim().toLowerCase()),
  },
  ex: {
    types: ["string"],
    symbol: "excludes",
    func: (value, comparison) => !(value || "").trim().toLowerCase().includes(comparison.trim().toLowerCase()),
  },
};

const connectorFunctions = {
  or: {
    symbol: "OR",
    func: (first, second) => first || second,
  },
  and: {
    symbol: "AND",
    func: (first, second) => first && second,
  },
};

function evaluateFilters(value, filterArray) {
  let connector, returnValue;
  for (let filter of filterArray) {
    if (connectorFunctions[filter.key]) {
      connector = filter;
      continue;
    }
    if (connector) {
      let tempValue = filter.func(value, filter.compareValue);
      returnValue = connector.func(returnValue, tempValue);
      connector = null;
    } else {
      returnValue = filter.func(value, filter.compareValue);
    }
  }
  return returnValue;
}

function getFiltersFromParams(params) {
  if (!params) return {};
  if (!Array.isArray(params)) params = [params];
  let filterObj = {};
  for (let filterString of params) {
    let [fieldName, ...filterPieces] = filterString.split(":");
    let filters = [];
    for (let piece of filterPieces) {
      let func = filterFunctions[piece] || connectorFunctions[piece];
      if (func) filters.push({ ...func, key: piece });
      else filters[filters.length - 1].compareValue = piece;
    }
    filterObj[fieldName] = filters;
  }
  return filterObj;
}

function getParamsFromFilters(filters) {
  let columnFilters = [];
  for (let fieldName in filters) {
    let string = fieldName;
    for (let filter of filters[fieldName]) {
      string += `:${filter.key}`;
      if (filter.compareValue !== undefined) string += `:${filter.compareValue}`;
    }
    columnFilters.push(string);
  }
  return columnFilters;
}

export { filterFunctions, connectorFunctions, evaluateFilters, getFiltersFromParams, getParamsFromFilters };
