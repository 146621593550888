import { minimumPasswordLength } from "./constants";

function isValidPassword(password) {
  const LOWER_CASE_REQUIREMENT = "(?=.*[a-z])";
  const UPPER_CASE_REQUIREMENT = "(?=.*[A-Z])";
  const DIGIT_REQUIREMENT = "(?=.*\\d)";
  const SYMBOL_REQUIREMENT = "(?=.*([^\\w\\d\\s]|_))";
  let regex = new RegExp(
    `^${LOWER_CASE_REQUIREMENT}${UPPER_CASE_REQUIREMENT}${DIGIT_REQUIREMENT}${SYMBOL_REQUIREMENT}[A-Za-z\\d !"#$%&'()*+,-.\\/:;<=>?@\\[\\]^_{|}~]{${minimumPasswordLength},}$`,
    "gm"
  );
  return regex.test(password);
}

function isValidEmail(email) {
  return /.+@.+\..+/gm.test(email);
}

function isValidPhoneNumber(phoneNumber) {
  return /\d{10}/gm.test(phoneNumber);
}

function isValidBullhornId(bullhornId) {
  return !/\D/gm.test(bullhornId);
}

const validation = { isValidPassword, isValidEmail, isValidPhoneNumber, isValidBullhornId };

export { isValidPassword, isValidEmail, isValidPhoneNumber, isValidBullhornId };

export default validation;
