import { useMemo } from "react";
import { DateTime } from "luxon";

function Bars({
  data,
  scales,
  xScale = "x",
  yScale = "y",
  width,
  height,
  onEnter,
  onMove,
  onLeave,
  offset,
  stacked,
  layered,
  percentage,
  dataSource,
  ...rest
}) {
  const backupBarRatio = useMemo(() => 0.8, []);

  const { isHorizontal, isShifted } = useMemo(() => {
    if (!data || !data.length) return { isHorizontal: false, isShifted: false, bandwidth: 0 };
    let isHorizontal = false;
    let isShifted = false;
    data.forEach((row) => {
      row.items.forEach((item) => {
        isHorizontal = isHorizontal || typeof item[yScale] === "string" || (item[yScale] && item[yScale].getDate) || scales[yScale].bandwidth;
        isShifted = isShifted || (item[yScale] && item[yScale].getDate) || (item[xScale] && item[xScale].getDate);
      });
    });
    return { isHorizontal, isShifted };
  }, [data, yScale, xScale, scales]);

  const { fullBandWidth } = useMemo(() => {
    if (!data || !data.length || !scales[xScale] || !scales[yScale]) return 0;
    let divFactor;
    let maxCount = 0;
    if (isShifted) {
      let variable = isHorizontal ? yScale : xScale;
      let minDiff = Infinity;
      let min, max;
      data.forEach((row) => {
        let prev;
        if (row.items.length > maxCount) maxCount = row.items.length;
        row.items.forEach((item) => {
          if (!prev) {
            prev = DateTime.fromJSDate(item[variable]);
            min = prev;
            max = prev;
            return;
          }
          let current = DateTime.fromJSDate(item[variable]);
          let { milliseconds } = current.diff(prev).toObject();
          if (Math.abs(milliseconds) < minDiff) minDiff = Math.abs(milliseconds);
          if (current < min) min = current;
          if (current > max) max = current;
          prev = current;
        });
      });
      let { milliseconds } = max.diff(min);
      divFactor = Math.max(maxCount, milliseconds / minDiff);
    }
    let bandwidth, isFaked;
    if (isHorizontal) {
      if (scales[yScale].bandwidth) {
        bandwidth = scales[yScale].bandwidth();
      } else {
        bandwidth = (height / divFactor) * backupBarRatio;
        isFaked = true;
        if (bandwidth) bandwidth -= 2;
      }
    } else {
      if (scales[xScale].bandwidth) {
        bandwidth = scales[xScale].bandwidth();
      } else {
        bandwidth = (width / divFactor) * backupBarRatio;
        isFaked = true;
        if (bandwidth) bandwidth -= 2;
      }
    }
    return { fullBandWidth: isFaked ? Math.min(bandwidth, 100) : bandwidth };
  }, [data, scales, xScale, yScale, width, height, backupBarRatio, isHorizontal, isShifted]);

  layered = !!layered;
  let stackOffsets = {};

  return (
    <>
      <g className="bar-group" transform={`translate(${offset.x}, ${offset.y})`}>
        {data.map((category, idx) => {
          return category.items.map((item) => {
            if (!scales[xScale] || !scales[yScale]) return null;

            let x, y, bWidth, bHeight;

            if (isHorizontal) {
              bWidth = Math.abs(scales[xScale](item[xScale]) - scales[xScale](0));
              x = scales[xScale](item[xScale]) - scales[xScale](0) < 0 ? scales[xScale](0) - bWidth : scales[xScale](0);
              y = scales[yScale](item.uid) || scales[yScale](item[yScale]);
              if (layered) {
                bHeight = fullBandWidth / (idx * layered + 1);
                y += fullBandWidth / 2 - bHeight / 2;
              } else if (stacked || category.items.length === 1) {
                bHeight = fullBandWidth;
                if (!stackOffsets[item.uid]) stackOffsets[item.uid] = 0;
                x += stackOffsets[item.uid];
                stackOffsets[item.uid] += bWidth;
              } else if (Object.keys(scales).length > 2) {
                bHeight = fullBandWidth;
              } else {
                bHeight = fullBandWidth / data.length;
                if (!stackOffsets[item.uid]) stackOffsets[item.uid] = 0;
                y += stackOffsets[item.uid] + bHeight / 10;
                stackOffsets[item.uid] += bHeight;
                bHeight = (bHeight / 5) * 4;
              }
            } else {
              bHeight = Math.abs(scales[yScale](item[yScale]) - scales[yScale](0));
              x = scales[xScale](item.uid) || scales[xScale](item[xScale]);
              y = scales[yScale](item[yScale]) - scales[yScale](0) < 0 ? scales[yScale](0) - bHeight : scales[yScale](0);
              if (layered) {
                bWidth = fullBandWidth / (idx * layered + 1);
                x += fullBandWidth / 2 - bWidth / 2;
              } else if (stacked || category.items.length === 1) {
                bWidth = fullBandWidth;
                if (!stackOffsets[item.uid]) stackOffsets[item.uid] = 0;
                y -= stackOffsets[item.uid];
                stackOffsets[item.uid] += bHeight;
              } else if (Object.keys(scales).length > 2) {
                bWidth = fullBandWidth;
              } else {
                bWidth = fullBandWidth / data.length;
                if (!stackOffsets[item.uid]) stackOffsets[item.uid] = 0;
                x += stackOffsets[item.uid] + bWidth / 10;
                stackOffsets[item.uid] += bWidth;
                bWidth = (bWidth / 5) * 4;
              }
            }

            if ([x, y, bWidth, bHeight].includes(undefined) || isNaN(x) || isNaN(y) || isNaN(bWidth) || isNaN(bHeight)) return null;

            if (isShifted) {
              if (isHorizontal) {
                y -= fullBandWidth / 2;
              } else {
                x -= fullBandWidth / 2;
              }
            }

            if (percentage) {
              if (isHorizontal) {
                let maxWidth = scales[xScale](100) - x;
                if (bWidth > maxWidth) {
                  bWidth = maxWidth;
                }
              } else {
                let maxHeight = scales[yScale](100) - y;
                if (bHeight > maxHeight) {
                  bHeight = maxHeight;
                }
              }
            }

            return (
              <rect
                key={`${item.uid}-bar`}
                x={x}
                y={y}
                width={Math.max(bWidth, 0)}
                height={Math.max(bHeight, 0)}
                fill={category.color}
                onMouseOver={(e) => onEnter({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY, data: item.data, color: category.color })}
                onMouseMove={(e) => onMove({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY })}
                onMouseLeave={(e) => onLeave()}
              />
            );
          });
        })}
      </g>
    </>
  );
}

export default Bars;
