import { useEffect, useState } from "react";
import axios from "axios";
import { DateTime } from "luxon";
import "./style.scss";

function Timestamp({ isVisible = true, isCard }) {
    const [time, setTime] = useState();

    useEffect(() => {
      const CancelToken = axios.CancelToken;
      let cancel;

      if (isCard) return;

      axios
        .get("/api/v1/timestamp", { cancelToken: new CancelToken((c) => cancel = c)})
        .then((res) => {
          setTime(DateTime.fromMillis(Date.parse(res.data.modified)).toLocaleString(DateTime.DATETIME_SHORT))
        })
        .catch((err) => {
          console.log(err)
        });

      return cancel;
    }, [isCard]);

    return (
      <>
        {isCard || !isVisible ? null : <span className="last-updated-timestamp">Last Updated: {time}</span>}
      </>
    );
}

export default Timestamp;
