import axios from "axios";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { populateVariables } from "../../Utilities/formatting";
import GraphTable from "../GraphTable";
import "./style.scss";

function EntityPage() {
  const { pathname, search } = useLocation();
  const [{ json, data, isReady }, setState] = useState({ json: [] });

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    let cancel;

    axios
      .get(`/api/v1${pathname}${search}`, {
        cancelToken: new CancelToken((c) => (cancel = c)),
      })
      .then((resp) => {
        const {
          data: { json, data },
        } = resp;
        setState({ json, data, isReady: true });
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log("Request Canceled");
        } else {
          console.log(err);
        }
      });

    return () => cancel();
  }, [pathname, search]);

  let components = [];
  for (let obj of json) {
    let tempData = data;
    if (obj.variable) tempData = tempData[obj.variable];
    switch (obj.type) {
      case "Header":
        components.push(
          <h1 key={`entity-header-${obj.label}`} className={`entity-header width-${obj.width || 1}`}>
            {populateVariables(obj.label, tempData)}
          </h1>
        );
        break;
      case "SectionHeader":
        components.push(
          <h1 key={`entity-section-header-${obj.label}`} className={`entity-section-header width-${obj.width || 1}`}>
            {populateVariables(obj.label, tempData)}
          </h1>
        );
        break;
      case "SectionSubHeader":
        components.push(
          <span
            key={`entity-section-sub-header-${obj.label}`}
            className={`entity-section-subheader width-${obj.width || 1}`}
            style={{ color: populateVariables(obj.color, tempData) }}
          >
            {populateVariables(obj.label, tempData)}
          </span>
        );
        break;
      case "DataPoint":
        components.push(
          <div key={`entity-data-point-${obj.label}`} className={`entity-data-point width-${obj.width || 1}`}>
            <h1 className="entity-data-point-value">{populateVariables(obj.value, tempData)}</h1>
            <p className="entity-data-point-label">{populateVariables(obj.label, tempData)}</p>
          </div>
        );
        break;
      case "GraphTable":
        components.push(
          <div key={`entity-graph-table-${obj.label}`} className={`entity-graph-table-wrapper width-${obj.width || 1} height-${obj.height || 1}`}>
            <h4 className={`entity-graph-table-label`}>{populateVariables(obj.label || "", tempData)}</h4>
            <GraphTable data={{ ...obj, data: tempData }} onDateChange={() => {}} isReady={isReady} isCard />
          </div>
        );
        break;
      case "Info":
        components.push(
          <p key={`entity-info-${obj.label}`} className={`entity-info-label width-${obj.width || 1}`}>
            {populateVariables(obj.label, tempData)}
            <span className="entity-info-value">{populateVariables(obj.value, tempData)}</span>
          </p>
        );
        break;
      case "EntityWindow":
        components.push(
          <div key={`entity-window-${obj.label}`} className={`entity-window width-${obj.width || 1}`}>
            <EntityPage json={obj.components} data={tempData} />
          </div>
        );
        break;
      default:
    }
  }
  return <div className="entity-page">{components}</div>;
}

export default EntityPage;
