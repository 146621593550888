import { Routes, Route, Navigate } from "react-router-dom";
import PageNav from "../../Components/PageNav";
import ByJobOrder from "./SubPages/ByJobOrder";
import ByClient from "./SubPages/ByClient";
import ByJobOwner from "./SubPages/ByJobOwner";
import ByCandidateOwner from "./SubPages/ByCandidateOwner";
import "./style.scss";

function CandidateStatus() {
  return (
    <>
      <PageNav
        title="Candidate Status"
        links={[
          { label: "By Job Order", href: "by-job-order" },
          { label: "By Client", href: "by-client" },
          { label: "By Job Owner", href: "by-job-owner" },
          { label: "Status By Candidate Owner", href: "by-candidate-owner" },
        ]}
      />
      <div className="page">
        <Routes>
          <Route path={`by-job-order`} element={<ByJobOrder />} />
          <Route path={`by-client`} element={<ByClient />} />
          <Route path={`by-job-owner`} element={<ByJobOwner />} />
          <Route path={`by-candidate-owner`} element={<ByCandidateOwner />} />
          <Route index element={<Navigate to={`by-job-order`} />} />
        </Routes>
      </div>
    </>
  );
}

export default CandidateStatus;
