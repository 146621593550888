import { useState, useRef, useMemo, useCallback } from "react";
import Chip from "../Chip";
import "./style.scss";

function ChipDropDown({ chips, onDelete, onBlur, disabled }) {
  const [isOpen, setIsOpen] = useState(false);
  const divRef = useRef();

  const handleClick = useCallback((e) => {
    setIsOpen(true);
  }, []);

  const handleFocus = useCallback(
    (e) => {
      e.stopPropagation();
      onBlur({ relatedTarget: null });
    },
    [onBlur]
  );

  const handleBlur = useCallback((e) => {
    if (divRef.current.contains(e.relatedTarget)) return;
    setIsOpen(false);
  }, []);

  const display = useMemo(() => {
    if (chips.length < 2) {
      const chip = chips[0];
      if (!chip) return null;
      return <Chip key={chip.value} value={chip.label} onDelete={() => onDelete(chip)} disabled={disabled} />;
    }
    if (isOpen) return chips.map((chip) => <Chip key={chip.value} value={chip.label} onDelete={() => onDelete(chip)} disabled={disabled} />);
    return (
      <>
        <span className="chip-example-wrapper">
          <span className="chip-count">{`${chips.length - 1}+`}</span>
          <span className="chip-example">{chips[0].label}</span>
        </span>
      </>
    );
  }, [isOpen, chips, onDelete, disabled]);

  return (
    <div className="chip-drop-down-wrapper">
      <div
        className={`chip-drop-down ${chips.length > 1 && isOpen ? "open" : ""}`}
        onClick={handleClick}
        onFocus={handleFocus}
        onBlur={handleBlur}
        tabIndex="0"
        ref={divRef}
      >
        {display}
      </div>
    </div>
  );
}

export default ChipDropDown;
