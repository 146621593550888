import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import ActivitySales from "./Pages/ActivitySales";
import ActivityRecruiting from "./Pages/ActivityRecruiting";
import ActivityRatioSales from "./Pages/ActivityRatioSales";
import ActivityRatioRecruiting from "./Pages/ActivityRatioRecruiting";
import RecordCounts from "./Pages/RecordCounts";
import CandidateStatus from "./Pages/CandidateStatus";
import OpenJobs from "./Pages/OpenJobs";
import Placements from "./Pages/Placements";
import Bookings from "./Pages/Bookings";
import ExecutiveDailyView from "./Pages/ExecutiveDailyView";
import DirectorOfRecruiting from "./Pages/DirectorOfRecruiting";
import DirectorOfSales from "./Pages/DirectorOfSales";
import AltusTeamDashboard from "./Pages/AltusTeamDashboard";
import Licensing from "./Pages/Licensing";
import Goals from "./Pages/Goals";
import AuthForm from "./Pages/AuthForm";
import NoMatch from "./Pages/NoMatch";
import Filters from "./Pages/Filters";
import SideNav from "./Components/SideNav";
import GlobalNav from "./Components/GlobalNav";
import EmailModal from "./Components/EmailModal";
import GeneratedDashboard from "./Components/GeneratedDashboard";
import { ToastProvider } from "./Contexts/toastContext";
import { AuthProvider } from "./Contexts/authContext";
import { DashboardProvider } from "./Contexts/dashboardContext";
import { EmailProvider } from "./Contexts/emailContext";
import RequireAuthRoute from "./Components/RequireAuthRoute";
import ResetPassword from "./Pages/ResetPassword";
import UpdatePassword from "./Pages/UpdatePassword";
import VerifyEmail from "./Pages/VerifyEmail";
import EntityQuery from "./Components/EntityQuery";
import EntityPage from "./Components/EntityPage";
import Reports from "./Pages/Reports";
import LicenseNeeded from "./Pages/LicenseNeeded";
import "./style.scss";
import Corporations from "./Pages/Corporations";
const version = "1.0.11";

function App() {
  useEffect(() => {
    axios
      .get("/api/v1/version")
      .then((resp) => {
        console.log(`Server Version: ${resp.data}`);
        console.log(`Client Version: ${version}`);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Router>
      <ToastProvider>
        <AuthProvider>
          <div className="layout">
            <DashboardProvider>
              <EmailProvider>
                <GlobalNav />
                <EmailModal />
                <SideNav />
                <div className="main">
                  <Routes>
                    <Route
                      path="/activities/activity-sales/*"
                      element={
                        <RequireAuthRoute role="user" licensed>
                          <ActivitySales />
                        </RequireAuthRoute>
                      }
                    />
                    <Route
                      path="/activities/activity-recruiting/*"
                      element={
                        <RequireAuthRoute role="user" licensed>
                          <ActivityRecruiting />
                        </RequireAuthRoute>
                      }
                    />
                    <Route
                      path="/activities/activity-ratio-sales/*"
                      element={
                        <RequireAuthRoute role="user" licensed>
                          <ActivityRatioSales />
                        </RequireAuthRoute>
                      }
                    />
                    <Route
                      path="/activities/activity-ratio-recruiting/*"
                      element={
                        <RequireAuthRoute role="user" licensed>
                          <ActivityRatioRecruiting />
                        </RequireAuthRoute>
                      }
                    />
                    <Route
                      path="/activities/record-counts/*"
                      element={
                        <RequireAuthRoute role="user" licensed>
                          <RecordCounts />
                        </RequireAuthRoute>
                      }
                    />
                    <Route
                      path="/candidates/candidate-status/*"
                      element={
                        <RequireAuthRoute role="user" licensed>
                          <CandidateStatus />
                        </RequireAuthRoute>
                      }
                    />
                    <Route
                      path="/joborders/open-jobs/*"
                      element={
                        <RequireAuthRoute role="user" licensed>
                          <OpenJobs />
                        </RequireAuthRoute>
                      }
                    />
                    <Route
                      path="/results/placements/*"
                      element={
                        <RequireAuthRoute role="user" licensed>
                          <Placements />
                        </RequireAuthRoute>
                      }
                    />
                    <Route
                      path="/results/bookings/*"
                      element={
                        <RequireAuthRoute role="user" licensed>
                          <Bookings />
                        </RequireAuthRoute>
                      }
                    />

                    <Route
                      path="/companies/corporations/*"
                      element={
                        <RequireAuthRoute role="user" licensed>
                          <Corporations />
                        </RequireAuthRoute>
                      }
                    />

                    <Route
                      path="/dashboards/director-of-recruiting/*"
                      element={
                        <RequireAuthRoute role="user">
                          <DirectorOfRecruiting />
                        </RequireAuthRoute>
                      }
                    />
                    <Route
                      path="/dashboards/director-of-sales/*"
                      element={
                        <RequireAuthRoute role="user">
                          <DirectorOfSales />
                        </RequireAuthRoute>
                      }
                    />
                    <Route
                      path="/dashboards/executive-daily/*"
                      element={
                        <RequireAuthRoute role="user">
                          <ExecutiveDailyView />
                        </RequireAuthRoute>
                      }
                    />
                    <Route
                      path="/dashboards/altus-team/*"
                      element={
                        <RequireAuthRoute role="user">
                          <AltusTeamDashboard />
                        </RequireAuthRoute>
                      }
                    />
                    <Route
                      path="/dashboards/:dashboardId"
                      element={
                        <RequireAuthRoute role="user" licensed>
                          <GeneratedDashboard />
                        </RequireAuthRoute>
                      }
                    />
                    <Route
                      path="/admin/filters/*"
                      element={
                        <RequireAuthRoute role="admin">
                          <Filters />
                        </RequireAuthRoute>
                      }
                    />
                    <Route
                      path="/admin/goals/*"
                      element={
                        <RequireAuthRoute role="admin">
                          <Goals />
                        </RequireAuthRoute>
                      }
                    />
                    <Route
                      path="/admin/licensing/*"
                      element={
                        <RequireAuthRoute role="admin">
                          <Licensing />
                        </RequireAuthRoute>
                      }
                    />
                    <Route
                      path="/admin/reports/*"
                      element={
                        <RequireAuthRoute role="admin">
                          <Reports />
                        </RequireAuthRoute>
                      }
                    />
                    <Route
                      path="/reset-password/:userId/:resetToken"
                      element={
                        <RequireAuthRoute role="none">
                          <ResetPassword />
                        </RequireAuthRoute>
                      }
                    />
                    <Route
                      path="/verify-email/:userId/:emailToken"
                      element={
                        <RequireAuthRoute role="none">
                          <VerifyEmail />
                        </RequireAuthRoute>
                      }
                    />
                    <Route
                      path="/login"
                      element={
                        <RequireAuthRoute role="none">
                          <AuthForm />
                        </RequireAuthRoute>
                      }
                    />
                    <Route
                      path="/update-password"
                      element={
                        <RequireAuthRoute role="user">
                          <UpdatePassword />
                        </RequireAuthRoute>
                      }
                    />
                    <Route
                      path="/entities/:pageType/:basePage/:group/:entity"
                      element={
                        <RequireAuthRoute role="user" licensed>
                          <EntityQuery />
                        </RequireAuthRoute>
                      }
                    />
                    <Route
                      path="/entities/:entity/:entityId"
                      element={
                        <RequireAuthRoute license>
                          <EntityPage />
                        </RequireAuthRoute>
                      }
                    />
                    <Route index element={<Navigate to="/activities/activity-sales/" />} />
                    <Route path="/license-needed" element={<LicenseNeeded />} />
                    <Route path="*" element={<NoMatch />} />
                  </Routes>
                </div>
              </EmailProvider>
            </DashboardProvider>
          </div>
        </AuthProvider>
      </ToastProvider>
    </Router>
  );
}

export default App;
