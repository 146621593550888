import { useContext } from "react";
import Card from "../Card";
import GraphTable from "../GraphTable";
import { EmailContext } from "../../Contexts/emailContext";
import { formatNumber, populateVariables } from "../../Utilities/formatting";
import "./style.scss";

function Dashboard({ json, data, isReady }) {
  const { ref } = useContext(EmailContext);
  return (
    <div className="page">
      <div className="dashboard" ref={ref}>
        {json.map(({ variable, heading, subheading, text, table, graphs, width, height, link }, idx) => {
          let item = data[variable];
          if (item === undefined || item === null) return null;
          if (table || graphs) {
            let legend = graphs.reduce((acc, cur) => {
              if (cur.legend === false) return false;
              return acc;
            }, true);
            return (
              <Card
                key={`${heading}-${subheading}-${idx}`}
                className={`graph-card ${width ? `width-${width}` : ""} ${height ? `height-${height}` : ""}`}
                heading={heading}
                subheading={subheading}
                link={link}
              >
                <GraphTable
                  data={{ table: { ...table, hidePagination: true }, graphs, data: item }}
                  onDateChange={() => {}}
                  isReady={isReady}
                  legend={legend}
                  isCard
                />
              </Card>
            );
          } else {
            return (
              <Card
                key={`${heading}-${subheading}-${idx}`}
                className={`text-card ${width ? `width-${width}` : ""} ${height ? `height-${height}` : ""}`}
                heading={heading}
                subheading={subheading}
                link={link}
              >
                {text.map(({ type, value, color, hover }) => {
                  let formattedItem = Object.entries(item).reduce((acc, [key, value]) => {
                    acc[key] = typeof value === "number" ? formatNumber(value) : value;
                    return acc;
                  }, {});
                  formattedItem = Object.entries(formattedItem).length ? formattedItem : item;
                  let valueString = populateVariables(value, formattedItem);
                  let hoverString = hover ? populateVariables(hover, formattedItem) : "";
                  let colorString = color ? populateVariables(color, formattedItem) : "";
                  if (type === "header") {
                    return (
                      <div className="header-wrapper">
                        <h1 key={valueString} className="header" style={{ color: colorString }}>
                          {valueString}
                        </h1>
                        {hover ? <div className="hover-text">{hoverString}</div> : null}
                      </div>
                    );
                  } else if (type === "subheader") {
                    return (
                      <h2 key={valueString} className="subheader" style={{ color: colorString }}>
                        {valueString}
                        {hover ? <div className="hover-text">{hoverString}</div> : null}
                      </h2>
                    );
                  } else return null;
                })}
              </Card>
            );
          }
        })}
      </div>
    </div>
  );
}

export default Dashboard;
