import { useState, useEffect, useCallback, useRef, useContext } from "react";
import { useLocation } from "react-router-dom";
import { toBlob } from "html-to-image";
import axios from "axios";
import { ToastContext } from "../../Contexts/toastContext";
import { EmailContext } from "../../Contexts/emailContext";
import { AuthContext } from "../../Contexts/authContext";
import ChipInput from "../ChipInput";
import RequireAuthComponent from "../RequireAuthComponent";
import "./style.scss";

const EmailModal = () => {
  const [emailToError, setEmailToError] = useState(false);
  const [failedToSend, setFailedToSend] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [to, setTo] = useState("");
  const [frequency, setFrequency] = useState("once");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [sendingEmail, setSendingEmail] = useState(false);
  const { addToast } = useContext(ToastContext);
  const { isOpen, close, emails, ref } = useContext(EmailContext);
  const { user } = useContext(AuthContext);
  const location = useLocation();
  const prevSending = useRef(sendingEmail);

  const handleClose = useCallback(() => {
    setTo("");
    setSubject("");
    setMessage("");
    setSendingEmail(false);
    setEmailErrorMessage();
    close();
  }, [close]);

  const postImage = useCallback(
    async (blob) => {
      const formData = new FormData();
      const file = new File([blob], "whateverfilename.png", { type: "image/png" });
      const emails = to
        .split(", ")
        .filter((email) => email)
        .join(", ");
      formData.append("image", file);
      formData.append("to", emails);
      formData.append("subject", subject);
      formData.append("message", message);
      let searchParams = location.search;
      if (location.pathname.includes("over-time")) {
        //If you are on an over-time page filter out the checked parameters cause they would be bad for scheduled reports
        searchParams =
          "?" +
          searchParams
            .slice(1)
            .split("&")
            .filter((param) => !param.includes("checked"))
            .join("&");
      }
      formData.append("frequency", frequency);
      formData.append("url", location.pathname + searchParams);
      formData.append("company_id", user.companyId);
      formData.append("user_id", user.id);
      const result = await axios.post("/api/v1/email/report", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      return result.data;
    },
    [to, subject, message, frequency, location, user]
  );

  const takeScreenShot = useCallback(() => {
    toBlob(ref.current, { cacheBust: true })
      .then((dataUrl) => {
        return postImage(dataUrl);
      })
      .then(() => {
        handleClose();
        addToast({ type: "success", message: "Email sent successfully!" });
      })
      .catch((err) => {
        setSendingEmail(false);
        addToast({ type: "error", message: "Email not sent!" });
      });
  }, [addToast, handleClose, postImage, ref]);

  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      if (to === "") {
        setEmailErrorMessage("*Please add a recipient*");
        return setEmailToError(true);
      }
      const containsValidEmails = to.split(", ").reduce((acc, cur) => acc && (cur === "" || cur.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)), true);
      if (!containsValidEmails) {
        setEmailErrorMessage("*Invalid Email*");
        return setEmailToError(true);
      } else {
        setSendingEmail(true);
      }
    },
    [to]
  );

  useEffect(() => {
    if (!ref || !ref.current) return;
    if (!sendingEmail || sendingEmail === prevSending.current) return (prevSending.current = sendingEmail);

    takeScreenShot();
    prevSending.current = sendingEmail;
  }, [sendingEmail, ref, takeScreenShot]);

  useEffect(() => {
    setTo("");
    setFrequency("once");
    setSubject("");
    setMessage("");
    setEmailToError(false);
    setFailedToSend(false);
    setEmailErrorMessage("");
  }, [isOpen]);

  return (
    // <RequireAuthComponent role="user">
    <div className={`email-modal ${!isOpen ? "email-modal-closed" : ""}`}>
      <div className="header-section">
        <h1 className="selector-header">Email Report</h1>
        <button className="selector-exit" onClick={handleClose} disabled={sendingEmail}></button>
      </div>
      <div className="modal-body">
        <form className="email-form">
          <div className={sendingEmail ? "email-form-item email-sending" : "email-form-item"}>
            <label>TO</label>
            <span className={emailToError ? "email-warning-on" : "email-warning-off"}>{emailErrorMessage}</span>
            <br />
            <ChipInput
              className={`email-input emails-input ${sendingEmail ? "email-sending" : ""}`}
              options={emails}
              value={to}
              onChange={(e) => setTo(e.target.value)}
              disabled={sendingEmail}
            />
          </div>
          <RequireAuthComponent role="admin">
            <br />
            <div className={sendingEmail ? "email-form-dropdown email-sending" : "email-form-dropdown"}>
              <label>FREQUENCY</label>
              <br />
              <select
                className={sendingEmail ? "email-dropdown-options email-sending" : "email-dropdown-options"}
                value={frequency}
                onChange={(e) => setFrequency(e.target.value)}
                disabled={sendingEmail}
              >
                <option value="once">Once</option>
                <option value="yearly">Yearly</option>
                <option value="quarterly">Quarterly</option>
                <option value="monthly">Monthly</option>
                <option value="weekly">Weekly</option>
                <option value="daily">Daily</option>
              </select>
            </div>
          </RequireAuthComponent>
          <br />
          <div className={sendingEmail ? "email-form-item email-sending" : "email-form-item"}>
            <label>SUBJECT</label>
            <br />
            <input
              className={`email-input subject-input ${sendingEmail ? "email-sending" : ""}`}
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              disabled={sendingEmail}
            ></input>
          </div>
          <br />
          <div className={sendingEmail ? "email-form-item email-sending" : "email-form-item"}>
            <label>MESSAGE</label>
            <br />
            <textarea
              className={`email-textarea message-input ${sendingEmail ? "email-sending" : ""}`}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              disabled={sendingEmail}
            ></textarea>
          </div>
        </form>
      </div>
      <div className="modal-footer">
        <span className={failedToSend ? "email-warning-on" : "email-warning-off"}>{emailErrorMessage}</span>
        <button className={sendingEmail ? "send-report-button email-sending-send-button" : "send-report-button"} onClick={handleClick} disabled={sendingEmail}>
          Send Report
        </button>
      </div>
    </div>
    // </RequireAuthComponent>
  );
};

export default EmailModal;
