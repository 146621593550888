import useNewDataFetcher from "../../../../Hooks/newUseDataFetcher";
import GraphTable from "../../../../Components/GraphTable";
import SubheaderTimePeriod from "../../../../Components/SubheaderTimePeriod";

function ByCreationDate() {
  const dataURL = "/api/v1/joborder/open-jobs/by-creation-date"
  const { data, dateRange, filterTypes, filters, isReady } = useNewDataFetcher({
    endPoint: dataURL,
  });

  return (
    <GraphTable data={data} dateRange={dateRange} filterTypes={filterTypes} filters={filters} isReady={isReady} interval={dateRange.interval} dataURL={dataURL}>
      <h1 className="page-heading">What are our open job orders?</h1>
      <h2 className="page-subheading">
        <span className="page-data">{data ? data.data.length : 0}</span> job orders created <SubheaderTimePeriod data={dateRange} />
      </h2>
    </GraphTable>
  );
}

export default ByCreationDate;
