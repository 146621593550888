import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { EmailContext } from "../../Contexts/emailContext";
import "./style.scss";

function PageNav({ title, links = [], children }) {
  const { open } = useContext(EmailContext);
  const location = useLocation();

  return (
    <div className="page-nav">
      <h1 className="page-title">{title}</h1>
      {links.map((link, idx) => {
        return (
          <Link key={`link-${link.href}-${idx}`} className={`page-link ${location.pathname.includes(link.href) ? "active" : ""}`} to={`${link.href}`}>
            {link.label}
          </Link>
        );
      })}
      <div className="page-nav-utility-btns">
        {children}
        <button className="email-report-link" onClick={open}>
          Email Report
        </button>
      </div>
    </div>
  );
}

export default PageNav;
