import "./style.scss";

function Modal({ className, title, type, children, isOpen, onExit }) {
  if (!isOpen) return null;
  let typeClass = "";
  switch (type) {
    case 'danger':
      typeClass = "danger";
      break;
    case 'warning':
      typeClass = "warning";
      break;
    case 'success':
      typeClass = "success";
      break;
    default:
      break;
  }

  return (
    <>
      <div className={`modal-background ${className}`}></div>
      <div className={`modal ${typeClass} ${className}`}>
        <div className="modal-title-bar">
          <p className="modal-title">{title}</p>
          <div className="modal-exit" onClick={onExit}></div>
        </div>
        <div className="modal-content">{children}</div>
      </div>
    </>
  );
}

export default Modal;
