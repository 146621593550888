import { useCallback, useMemo, useRef, useEffect } from "react";

function TextForm({ finalColumnsGraph, text, functions, handleTextChange, handleFunctionChange, disabled }) {
  const mainInputRef = useRef();
  const subInputRef = useRef();
  const hoverInputRef = useRef();

  const handleFunctionSelect = useCallback(
    (field, e) => {
      let value = e.target.value;
      let prevText = text[field];
      if (["/", "*"].includes(value) && prevText.slice(-1) === "%") {
        prevText = prevText.slice(0, -1);
        handleFunctionChange(field, "");
      } else {
        if (["/", "*"].includes(prevText.slice(-1))) value = value.slice(1);
        handleFunctionChange(field, value);
      }
      handleTextChange(field, prevText + value);
      let id = field.replace(/[A-Z]/gm, (val) => `-${val.toLowerCase()}`);
      let input = document.querySelector(`#${id}`);
      input.focus();
    },
    [handleTextChange, handleFunctionChange, text]
  );

  const handleFieldSelect = useCallback(
    (field, e) => {
      let value = e.target.value;
      let prevText = text[field];
      if (["/", "*"].includes(prevText.slice(-1)) && value[0] === "%") value = value.slice(1);
      handleTextChange(field, text[field] + value);
      if (functions[field]) handleFunctionChange(field, "");
      let id = field.replace(/[A-Z]/gm, (val) => `-${val.toLowerCase()}`);
      let input = document.querySelector(`#${id}`);
      input.focus();
    },
    [handleTextChange, handleFunctionChange, text, functions]
  );

  useEffect(() => {
    if (!mainInputRef.current) return;
    let length = mainInputRef.current.value.length;
    mainInputRef.current.setSelectionRange(length, length);
    mainInputRef.current.scrollLeft = mainInputRef.current.scrollWidth;
  }, [text.mainText]);

  useEffect(() => {
    if (!subInputRef.current) return;
    let length = subInputRef.current.value.length;
    subInputRef.current.setSelectionRange(length, length);
    subInputRef.current.scrollLeft = subInputRef.current.scrollWidth;
  }, [text.subText]);

  useEffect(() => {
    if (!hoverInputRef.current) return;
    let length = hoverInputRef.current.value.length;
    hoverInputRef.current.setSelectionRange(length, length);
    hoverInputRef.current.scrollLeft = hoverInputRef.current.scrollWidth;
  }, [text.hoverText]);

  const functionOptions = useMemo(() => {
    let options = {};
    for (let field in text) {
      options[field] = [
        <option key={`option-${field}-function-blank`} value="">
          -- Function --
        </option>,
        ...["sum", "average", "count"].map((textCardFunction) => (
          <option key={`option-${field}-${textCardFunction}`} value={`%${textCardFunction}(`}>
            {textCardFunction}
          </option>
        )),
      ];
      let string = text[field];
      if (!string) continue;
      options[field] = options[field].concat([
        <option key={`option-${field}-/`} value="/">
          /
        </option>,
        <option key={`option-${field}-*`} value="*">
          *
        </option>,
      ]);
    }
    return options;
  }, [text]);

  const fieldOptions = useMemo(() => {
    const numberDateOptions = [
      { dateString: "Day of Month (number)", fieldName: "%get(day)%" },
      { dateString: "Number of Days in Month", fieldName: "%get(daysInMonth)%" },
      { dateString: "Week (of the Year)", fieldName: "%get(weekNumber)%" },
      { dateString: "Day of Year (number)", fieldName: "%get(ordinal)%" },
      { dateString: "Number of Days in Year", fieldName: "%get(daysInYear)%" },
      { dateString: "Quarter", fieldName: "%get(quarter)%" },
      { dateString: "Year", fieldName: "%get(year)%" },
    ];
    const dateOptions = [
      { dateString: "Day of Month (number)", dateCode: "%get(day)%" },
      { dateString: "Number of Days in Month", dateCode: "%get(daysInMonth)%" },
      { dateString: "Day of the Week", dateCode: "%get(weekdayLong)%" },
      { dateString: "Week (of the Year)", dateCode: "%get(weekNumber)%" },
      { dateString: "Day of Year (number)", dateCode: "%get(ordinal)%" },
      { dateString: "Number of Days in Year", dateCode: "%get(daysInYear)%" },
      { dateString: "Month", dateCode: "%get(monthLong)%" },
      { dateString: "Quarter", dateCode: "%get(quarter)%" },
      { dateString: "Year", dateCode: "%get(year)%" },
    ];
    let options = {};
    for (let field in functions) {
      options[field] = [
        <option key={`option-${field}-field-blank`} value="">
          -- Field --
        </option>,
      ];
      if (functions[field])
        options[field] = options[field].concat(
          [...finalColumnsGraph, ...numberDateOptions].map((column) => (
            <option key={`option-${field}-${column.fieldName}`} value={`${column.fieldName})%`}>
              {column.label}
            </option>
          ))
        );
      else
        options[field] = options[field].concat(
          dateOptions.map((dateOption) => (
            <option key={`option-${field}-${dateOption.dateCode}`} value={dateOption.dateCode}>
              {dateOption.dateString}
            </option>
          ))
        );
    }
    return options;
  }, [finalColumnsGraph, functions]);

  return (
    <div className="text-card-options">
      {/* <div> */}
      <label>Main Text</label>
      <input id="main-text" value={text.mainText} onChange={(e) => handleTextChange("mainText", e.target.value)} ref={mainInputRef} disabled={disabled} />
      <select value={functions.mainText} onChange={(e) => handleFunctionSelect("mainText", e)} disabled={disabled}>
        {functionOptions["mainText"]}
      </select>
      <select value="" onChange={(e) => handleFieldSelect("mainText", e)} disabled={disabled}>
        {fieldOptions["mainText"]}
      </select>
      {/* </div> */}
      {/* <div> */}
      <label>Sub Text</label>
      <input id="sub-text" value={text.subText} onChange={(e) => handleTextChange("subText", e.target.value)} ref={subInputRef} disabled={disabled} />
      <select value={functions.subText} onChange={(e) => handleFunctionSelect("subText", e)} disabled={disabled}>
        {functionOptions["subText"]}
      </select>
      <select value="" onChange={(e) => handleFieldSelect("subText", e)} disabled={disabled}>
        {fieldOptions["subText"]}
      </select>
      {/* </div> */}
      {/* <div> */}
      <label>Hover Text</label>
      <input id="hover-text" value={text.hoverText} onChange={(e) => handleTextChange("hoverText", e.target.value)} ref={hoverInputRef} disabled={disabled} />
      <select value={functions.hoverText} onChange={(e) => handleFunctionSelect("hoverText", e)} disabled={disabled}>
        {functionOptions["hoverText"]}
      </select>
      <select value="" onChange={(e) => handleFieldSelect("hoverText", e)} disabled={disabled}>
        {fieldOptions["hoverText"]}
      </select>
      {/* </div> */}
    </div>
  );

  // return (
  //   <div className={"text-card-options"}>
  //     <div>
  //       <label>Main Text</label>
  //       <input value={mainText} onChange={handleMainText}></input>
  //       <select value={mainTextFunction} onChange={handleMainTextFunction}>
  //         <option value={""} disabled>
  //           --Function--
  //         </option>
  //         <option value={""}>Reset</option>
  //         {textCardFunctions.map((textCardFunction) => {
  //           return (
  //             <option key={`maintext${DateTime.now()}${textCardFunction}`} value={`%${textCardFunction}(`}>
  //               {textCardFunction}
  //             </option>
  //           );
  //         })}
  //         {mainText ? <option value={"/"}>/</option> : null}
  //         {mainText ? <option value={"*%"}>*</option> : null}
  //       </select>
  //       <select value={mainTextFieldName} onChange={handleMainTextFieldName}>
  //         {mainTextFunction ? (
  //           <option value={""} disabled>
  //             --Fieldname--
  //           </option>
  //         ) : (
  //           <option value={""} disabled>
  //             --Time Period--
  //           </option>
  //         )}
  //         <option value={""}>Reset</option>
  //         <option value={"%get(day)%"}>Day (Number)</option>
  //         <option value={"%get(daysInMonth)%"}>Number of Days in Month</option>
  //         {mainTextFunction
  //           ? finalColumnsGraph.map((column) => {
  //               return (
  //                 <option key={`maintext${DateTime.now()}${column.fieldName}`} value={`${column.fieldName})%`}>
  //                   {column.label}
  //                 </option>
  //               );
  //             })
  //           : dateOptions.map((dateOption) => {
  //               return (
  //                 <option key={`maintext${DateTime.now()}${dateOption.dateString}`} value={dateOption.dateCode}>
  //                   {dateOption.dateString}
  //                 </option>
  //               );
  //             })}
  //       </select>
  //     </div>
  //     <div>
  //       <label>Sub Text</label>
  //       <input value={subText} onChange={handleSubText}></input>
  //       <select value={subTextFunction} onChange={handleSubTextFunction}>
  //         <option value={""} disabled>
  //           --Function--
  //         </option>
  //         <option value={""}>Reset</option>
  //         {textCardFunctions.map((textCardFunction) => {
  //           return (
  //             <option key={`subtext${DateTime.now()}${textCardFunction}`} value={`%${textCardFunction}(`}>
  //               {textCardFunction}
  //             </option>
  //           );
  //         })}
  //         {subText ? <option value={"/"}>/</option> : null}
  //         {subText ? <option value={"*%"}>*</option> : null}
  //       </select>
  //       <select value={subTextFieldName} onChange={handleSubTextFieldName}>
  //         {subTextFunction ? (
  //           <option value={""} disabled>
  //             --Fieldname--
  //           </option>
  //         ) : (
  //           <option value={""} disabled>
  //             --Time Period--
  //           </option>
  //         )}
  //         <option value={""}>Reset</option>
  //         <option value={"%get(day)%"}>Day (Number)</option>
  //         <option value={"%get(daysInMonth)%"}>Number of Days in Month</option>
  //         {subTextFunction
  //           ? finalColumnsGraph.map((column) => {
  //               return (
  //                 <option key={`subtext${DateTime.now()}${column.label}`} value={`${column.fieldName})%`}>
  //                   {column.label}
  //                 </option>
  //               );
  //             })
  //           : dateOptions.map((dateOption) => {
  //               return (
  //                 <option key={`subtext${DateTime.now()}${dateOption.dateString}`} value={dateOption.dateCode}>
  //                   {dateOption.dateString}
  //                 </option>
  //               );
  //             })}
  //       </select>
  //     </div>
  //     <div>
  //       <label>Hover Text</label>
  //       <input value={hoverText} onChange={handleHoverText}></input>
  //       <select value={hoverTextFunction} onChange={handleHoverTextFunction}>
  //         <option value={""} disabled>
  //           --Function--
  //         </option>
  //         <option value={""}>Reset</option>
  //         {textCardFunctions.map((textCardFunction) => {
  //           return (
  //             <option key={`hovertext${DateTime.now()}${textCardFunction}`} value={`%${textCardFunction}(`}>
  //               {textCardFunction}
  //             </option>
  //           );
  //         })}
  //         {hoverText ? <option value={"/"}>/</option> : null}
  //         {hoverText ? <option value={"*%"}>*</option> : null}
  //       </select>
  //       <select value={hoverTextFieldName} onChange={handleHoverTextFieldName}>
  //         {hoverTextFunction ? (
  //           <option value={""} disabled>
  //             --Fieldname--
  //           </option>
  //         ) : (
  //           <option value={""} disabled>
  //             --Time Period--
  //           </option>
  //         )}
  //         <option value={""}>Reset</option>
  //         <option value={"%get(day)%"}>Day (Number)</option>
  //         <option value={"%get(daysInMonth)%"}>Number of Days in Month</option>
  //         {hoverTextFunction
  //           ? finalColumnsGraph.map((column) => {
  //               return (
  //                 <option key={`hovertext${DateTime.now()}${column.label}`} value={`${column.fieldName})%`}>
  //                   {column.label}
  //                 </option>
  //               );
  //             })
  //           : dateOptions.map((dateOption) => {
  //               return (
  //                 <option key={`hovertext${DateTime.now()}${dateOption.dateString}`} value={dateOption.dateCode}>
  //                   {dateOption.dateString}
  //                 </option>
  //               );
  //             })}
  //       </select>
  //     </div>
  //   </div>
  // );
}

export default TextForm;
