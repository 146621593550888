import { populateVariables } from "../../Utilities/formatting";

function TextCard({ mainText, subText, hoverText, /*thresholdCalculation,*/ data, dateRange }) {
  let header, subheader, hoverString /*, meetsThreshold*/;
  if (data.length) {
    header = mainText && populateVariables(mainText, { data, startDate: dateRange.startDate(), endDate: dateRange.endDate(), interval: dateRange.interval });
    subheader = subText && populateVariables(subText, { data, startDate: dateRange.startDate(), endDate: dateRange.endDate(), interval: dateRange.interval });
    hoverString =
      hoverText && populateVariables(hoverText, { data, startDate: dateRange.startDate(), endDate: dateRange.endDate(), interval: dateRange.interval });
    /*meetsThreshold =
      thresholdCalculation &&
      populateVariables(thresholdCalculation, { data, startDate: dateRange.startDate(), endDate: dateRange.endDate(), interval: dateRange.interval });*/
  }
  let tooltip = hoverString ? <div className="hover-text">{hoverString}</div> : null;

  return (
    <div className="text-card-wrapper">
      <div className="header-wrapper">
        <h1 className={`header`}>{header}</h1>
        {tooltip}
      </div>
      <h2 className="subheader">{subheader}</h2>
    </div>
  );
}

export default TextCard;
