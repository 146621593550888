import "./style.scss";

function LicenseNeeded() {
  return (
    <div className="wrapper">
      <h1>License needed</h1>
      <p>You need a license to use this feature.</p>
      <p>Please contact your admin to have them purchase a license for you.</p>
    </div>
  );
}

export default LicenseNeeded;
