import { useState } from "react";

function Circles({ data, scales, xScale = "x", yScale = "y", rMin = 0, rMax, onEnter, onLeave, offset }) {
  let maxVal = Math.max(...[].concat(...data.map((item) => item.items.map((inner) => inner.r))));
  rMax = rMax || maxVal;

  const [hovered, setHovered] = useState(null);

  let flattened = [];
  let index = 0;
  for (let item of data) {
    for (let point of item.items) {
      let x = (scales[xScale] ? scales[xScale](point[xScale]) : 0) + offset.x;
      let y = (scales[yScale] ? scales[yScale](point[yScale]) : 0) + offset.y;
      let r = ((point.r || 0) / (maxVal || 1)) * (rMax - rMin) + rMin;
      if (isNaN(x) || isNaN(y) || isNaN(r) /*|| (hovered && hovered.x === x && hovered.y === y && hovered.r === r && item.color === hovered.color)*/) continue;
      let key = `circle-${item.uid}-${point.uid}-${x}-${y}-${index++}`;
      let color = item.color;
      let uid = `${item.uid}-${point.uid}`;
      let data = point.data;
      flattened.push({ x, y, r, key, color, uid, data });
    }
  }

  let sorted = flattened.sort((a, b) => b.r - a.r);

  return (
    <g className="circle-group">
      {sorted.map(({ uid, key, x, y, r, color, data }) => {
        let isHovered = hovered && hovered.uid === uid;
        let radius = isHovered ? r + 2 : r;
        return (
          <circle
            key={key}
            cx={x}
            cy={y}
            r={radius}
            stroke={color}
            fill={`${color}55`}
            onMouseOver={(e) => {
              setHovered({ x, y, r, color, uid });
              onEnter({ x, y, data, color });
            }}
            onMouseLeave={(e) => {
              if (uid !== hovered.uid) return;
              setHovered(null);
              onLeave();
            }}
          />
        );
      })}
    </g>
  );
}

export default Circles;
