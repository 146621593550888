import { useState, useCallback } from "react";
import Login from "../../Components/Login";
import Register from "../../Components/Register";
import ForgotPassword from "../../Components/ForgotPassword";
import "./style.scss";

function AuthForm() {
  let [formState, setFormState] = useState("login");

  const handleSubmit = useCallback(() => {
    setFormState("login");
  }, []);

  let currentForm;
  switch (formState) {
    case "login":
      currentForm = (
        <>
          <Login onSubmit={handleSubmit} />
          <p className="change-link">
            Not a user?
            <button className="link-button" onClick={() => setFormState("register")}>
              Sign Up
            </button>
            <button className="link-button right-link" onClick={() => setFormState("reset-password")}>
              Forgot password?
            </button>
          </p>
        </>
      );
      break;
    case "register":
      currentForm = (
        <>
          <Register onSubmit={handleSubmit} />
          <p className="change-link">
            Already have an account?
            <button className="link-button" onClick={() => setFormState("login")}>
              Log in
            </button>
          </p>
        </>
      );
      break;
    case "reset-password":
      currentForm = (
        <>
          <ForgotPassword onSubmit={handleSubmit} />
          <button className="link-button right-link" onClick={() => setFormState("login")}>
            Back to Sign in
          </button>
        </>
      );
      break;
    default:
  }

  return (
    <>
      <h1 className="metrix-logo">Metrix</h1>
      <div className="pre-auth-form">{currentForm}</div>
    </>
  );
}

export default AuthForm;
