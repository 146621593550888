import { useRef, useEffect, useMemo } from "react";
import * as d3 from "d3";
// import { DateTime } from "luxon";
import { formatNumber } from "../../../Utilities/formatting";
import "./style.scss";

function Axis(props) {
  const {
    data,
    className,
    name,
    position,
    ticks,
    width,
    height,
    margin,
    scales,
    padding,
    isVisible = true,
    offset,
    onAxisDraw,
    show,
    type,
    additionalData,
  } = props;
  const gRef = useRef(null);
  const labelRef = useRef(null);
  const tickRef = useRef(null);

  const interval =
    props.interval || additionalData.graphs.find((graph) => graph.axes.find((axis) => axis.name === name)).axes.find((axis) => axis.name === name).interval;

  const { mainField, subField, idField } = !additionalData
    ? {}
    : additionalData.table.columns.reduce((acc, cur) => {
        if (cur.isId) acc.idField = cur.fieldName;
        if (cur.isMain) acc.mainField = cur.fieldName;
        if (cur.isSub) acc.subField = cur.fieldName;
        return acc;
      }, {});

  const titles = useMemo(
    () =>
      additionalData &&
      additionalData.data &&
      additionalData.data.reduce((acc, cur) => {
        let uid = `${cur[idField] || cur[mainField]}${cur[subField] || ""}` || "(";
        let title = cur[mainField];
        acc.push({ uid, title });
        return acc;
      }, []),
    [additionalData, idField, mainField, subField]
  );

  let dataString = JSON.stringify(data);

  useEffect(() => {
    let data = JSON.parse(dataString);
    let iWidth = width + padding * 2;
    let iHeight = height + padding * 2;
    let x = position === "right" && !ticks ? iWidth : 0;
    let y = position === "bottom" && !ticks ? iHeight : 0;
    let tickSize = (ticks ? (["left", "top"].includes(position) ? -1 : 1) : 0) * (["left", "right"].includes(position) ? iWidth : iHeight);
    let offsetX = ["left", "right"].includes(position) ? padding / 2 : 0;
    let offsetY = ["top", "bottom"].includes(position) ? padding / 2 : 0;

    if (!scales[name]) return;
    let scale = scales[name];
    const textAxis = d3[`axis${position[0].toUpperCase() + position.slice(1)}`](scale).tickSize(tickSize);
    if (["time", "percentage"].includes(type)) {
      calculateTicks(textAxis, type, interval, data, name);
    } else {
      textAxis.tickFormat((d) => {
        if (typeof d === "string" && data.length) {
          let obj;
          for (let row of data) {
            obj = row.items.find((item) => {
              return item.uid === d;
            });
            if (obj) break;
          }

          if (!obj) {
            let item = titles.find((item) => item.uid === d);
            if (item) return item.title;
            return d;
          }
          return obj.label;
        } else if (typeof d === "number") {
          return formatNumber(d);
        }
        return d;
      });
    }
    d3.select(labelRef.current).attr("transform", `translate(${x}, ${y})`).call(textAxis);
    d3.select(labelRef.current).selectAll("line").remove();

    if (tickSize) {
      const tickAxis = d3[`axis${position[0].toUpperCase() + position.slice(1)}`](scale).tickSize(tickSize - padding * Math.sign(tickSize));
      if (["time", "percentage"].includes(type)) {
        calculateTicks(tickAxis, type, interval, data, name);
      }
      d3.select(tickRef.current)
        .attr("transform", `translate(${x + offsetX}, ${y + offsetY})`)
        .call(tickAxis);
      d3.select(tickRef.current).selectAll("text").remove();
    }

    let axisText = Array.from(labelRef.current.querySelectorAll(`text`));
    let textSize = { width: 0, height: 0 };
    if (["left", "right"].includes(position)) {
      textSize.width = Math.max(...axisText.map((item) => item.getBoundingClientRect().width), 0);
    }

    if (["top", "bottom"].includes(position)) {
      textSize.height = Math.max(...axisText.map((item) => item.getBoundingClientRect().height), 0);
    }

    let offset = { x: position === "left" ? textSize.width : 0, y: position === "top" ? textSize.height : 0 };

    if (textSize.width || textSize.height) {
      onAxisDraw({ textSize, offset, name, position });
    }
  }, [
    dataString,
    position,
    width,
    height,
    margin.right,
    margin.bottom,
    margin.left,
    margin.top,
    scales,
    name,
    padding,
    ticks,
    onAxisDraw,
    interval,
    type,
    titles,
  ]);

  return isVisible ? (
    <g className={`axis ${className} ${show ? "" : "hidden"}`} ref={gRef} transform={`translate(${offset.x}, ${offset.y})`}>
      <g className="axis-labels" ref={labelRef}></g>
      <g className="axis-ticks" ref={tickRef}></g>
    </g>
  ) : null;
}

function calculateTicks(axis, type, interval, data, name) {
  if (type === "percentage") {
    axis.ticks(2);
  }
  //@TODO: This makes many dates in a small space overlap each other, but when properly spaced dates are more readable.

  // } else if (type === "time") {
  //   if (!interval) {
  //     for (let piece of data) {
  //       if (piece.items.length < 2) continue;
  //       let a = piece.items[0][name];
  //       let b = piece.items[1][name];
  //       let aDate = DateTime.fromISO(a);
  //       let bDate = DateTime.fromISO(b);
  //       let { years, months, weeks, days } = aDate.diff(bDate, ["years", "months", "weeks", "days"]).toObject();
  //       if (years || Math.abs(months) > 3) interval = "Year";
  //       else if (Math.abs(months) > 1) interval = "Quarter";
  //       else if (months || Math.abs(weeks) > 1) interval = "Month";
  //       else if (weeks || Math.abs(days) > 1) interval = "Week";
  //       else interval = "Day";
  //       break;
  //     }
  //   }
  //   if (!interval) return axis;
  //   let luxonInterval = interval.toLowerCase() + "s";
  //   let ticks = [];
  //   let existing = [];
  //   for (let piece of data) {
  //     let last;
  //     for (let item of piece.items) {
  //       if (!item[name]) continue;
  //       let date = DateTime.fromISO(item[name]);
  //       if (last && date.diff(last, luxonInterval).toObject()[luxonInterval] > 1) {
  //         while (last < date) {
  //           last = last.plus({ [luxonInterval]: 1 });
  //           if (existing.includes(`${last.month}${last.day}${last.year}`)) continue;
  //           existing.push(`${last.month}${last.day}${last.year}`);
  //           ticks.push(last.toJSDate());
  //         }
  //       }
  //       last = date;
  //       if (existing.includes(`${date.month}${date.day}${date.year}`)) continue;
  //       existing.push(`${date.month}${date.day}${date.year}`);
  //       ticks.push(date.toJSDate());
  //     }
  //   }
  //   axis.tickValues(ticks);
  //   axis.tickFormat((d) => {
  //     if (!interval) return d;
  //     let date = DateTime.fromJSDate(d);
  //     let isJanFirst = date.month === 1 && date.day === 1;
  //     let label;
  //     switch (interval) {
  //       case "Year":
  //         label = d3.timeFormat("%Y")(d);
  //         break;
  //       case "Quarter":
  //         label = `Q${Math.ceil(date.month / 3)}`;
  //         break;
  //       case "Month":
  //         label = d3.timeFormat("%b")(d);
  //         break;
  //       default:
  //         label = d3.timeFormat("%b %e")(d);
  //         break;
  //     }
  //     if (ticks.includes(`${label}${date.year}`)) return null;
  //     ticks.push(`${label}${date.year}`);
  //     if (isJanFirst) label = d3.timeFormat("%Y")(d);
  //     return label;
  //   });
  // }
  return axis;
}

export default Axis;
