import { useState, useRef, useEffect, useCallback, useContext } from "react";
import axios from "axios";
import { ToastContext } from "../../../../Contexts/toastContext";
// import PageNav from "../../../../Components/PageNav";
import BuyLicenseModal from "../../../../Components/BuyLicenseModal";
import CreateUserModal from "../../../../Components/CreateUserModal";
import "./style.scss";

const ownersInitial = {
  columns: [
    { label: "Staffing Analytics", fieldName: "enabled", type: "boolean", className: "enabled-cell" },
    { label: "Name", fieldName: "name", className: "name-cell" },
    { label: "Email", fieldName: "email", className: "email-cell" },
    { label: "# Entities", fieldName: "numOfEntities", className: "entities-cell" },
    { label: "ID", fieldName: "bullhornId", className: "id-cell" },
  ],
  rows: [],
};

function Licensing() {
  const [licenses, setLicenses] = useState({ used: 0, purchased: 0 });
  const { used, purchased } = licenses;
  const [activeOwners, setActiveOwners] = useState(ownersInitial);
  const [inactiveOwners, setInactiveOwners] = useState(ownersInitial);
  const [licenseIsOpen, setLicenseIsOpen] = useState(false);
  const [createUserIsOpen, setCreateUserIsOpen] = useState(false);
  const [licensesBeingSaved, setLicensesBeingSaved] = useState(false);
  // const [rowsBeingDeleted, setRowsBeingDeleted] = useState({});
  const initialLicenses = useRef(null);
  const { addToast } = useContext(ToastContext);

  const getData = useCallback(() => {
    const CancelToken = axios.CancelToken;
    let cancel;
    axios
      .get(`/api/v1/admin/licenses`, {
        cancelToken: new CancelToken((c) => (cancel = c)),
      })
      .then(({ data: { users: userLicenses, licenses: purchased } }) => {
        let { activeUsers, inactiveUsers, used } = userLicenses.reduce(
          (acc, cur) => {
            if (cur.user_id) {
              let user = {
                id: cur.user_id,
                bullhornId: cur.bullhorn_id,
                name: cur.name,
                email: cur.email,
                numOfEntities: cur.entities,
                enabled: cur.has_license ? true : false,
              };
              if (!cur.is_terminated) {
                acc.activeUsers.push(user);
              } else {
                acc.inactiveUsers.push(user);
              }
            }

            if (cur.has_license && cur.user_id) acc.used++;
            return acc;
          },
          { activeUsers: [], inactiveUsers: [], used: 0 }
        );
        initialLicenses.current = activeUsers;
        setActiveOwners((prevOwners) => {
          return { ...prevOwners, rows: activeUsers };
        });
        setInactiveOwners((prevOwners) => {
          return { ...prevOwners, rows: inactiveUsers };
        });
        setLicenses({ purchased, used });
      })
      .catch((err) => {
        console.log(err);
        addToast({ type: "error", message: "Failed to get data" });
      });
    return cancel;
  }, [addToast]);

  useEffect(() => {
    let cancel = getData();
    return () => cancel();
  }, [getData]);

  const handleSave = useCallback(() => {
    async function saveLicenses() {
      setLicensesBeingSaved(true);
      let assigned = [];
      let unassigned = [];

      try {
        for (let newItem of activeOwners.rows) {
          let oldItem = initialLicenses.current.find((item) => item.id === newItem.id);
          if (newItem.enabled === oldItem.enabled) continue;
          if (newItem.enabled) assigned.push(newItem);
          else unassigned.push(newItem);
        }

        if (!assigned.length && !unassigned.length) return addToast({ type: "error", message: "No licenses have been changed" });

        await axios.put(`/api/v1/admin/licenses`, { assigned, unassigned });
        const {
          data: { users: userLicenses },
        } = await axios.get(`/api/v1/admin/licenses`);
        let users = userLicenses.reduce((acc, cur) => {
          if (cur.user_id) {
            let user = {
              id: cur.user_id,
              bullhornId: cur.bullhorn_id,
              name: cur.name,
              email: cur.email,
              numOfEntities: cur.entities,
              enabled: cur.has_license ? true : false,
            };
            acc.push(user);
          }
          return acc;
        }, []);
        initialLicenses.current = users;
        setActiveOwners((prevOwners) => {
          return { ...prevOwners, rows: users };
        });
        addToast({ type: "success", message: "Licenses updated" });
      } catch (err) {
        console.log(err);
        addToast({ type: "error", message: "Licenses not updated" });
      } finally {
        setLicensesBeingSaved(false);
      }
    }

    saveLicenses();
  }, [activeOwners, addToast]);

  const handleAdd = useCallback(() => {
    setCreateUserIsOpen(true);
  }, []);

  // const handleDelete = useCallback(
  //   (row) => {
  //     setRowsBeingDeleted((prev) => ({ ...prev, [row.id]: true }));
  //     axios
  //       .delete(`/api/v1/admin/accounts/user/${row.id}`)
  //       .then(() => {
  //         addToast({ type: "success", message: "Successfully deleted user" });
  //         getData();
  //       })
  //       .catch((err) => {
  //         addToast({ type: "error", message: "Failed to delete user" });
  //       })
  //       .finally(() =>
  //         setRowsBeingDeleted((prev) => {
  //           let { [row.id]: id, ...rest } = prev;
  //           return rest;
  //         })
  //       );
  //   },
  //   [addToast, getData, setRowsBeingDeleted]
  // );

  const toggleActiveEnabled = useCallback(
    (changedIdx) => {
      let isValid = true;
      let usedLicenses = licenses.used;
      let newRows = activeOwners.rows.map((item, idx) => {
        if (idx !== changedIdx) return item;
        if (!item.enabled) {
          usedLicenses++;
        } else {
          usedLicenses--;
        }
        if (usedLicenses < 0 || usedLicenses > licenses.purchased) {
          isValid = false;
        }
        return { ...item, enabled: !item.enabled };
      });

      if (isValid) {
        setActiveOwners({ ...activeOwners, rows: newRows });
        setLicenses({ ...licenses, used: usedLicenses });
      }
    },
    [licenses, activeOwners]
  );
  const toggleInactiveEnabled = useCallback((changedIdx) => {
    setInactiveOwners((prev) => {
      return {
        ...prev,
        rows: prev.rows.map((item, idx) => {
          if (idx !== changedIdx) return item;
          return { ...item, enabled: !item.enabled };
        }),
      };
    });
  }, []);

  const terminateUser = useCallback(
    (userId) => {
      axios
        .put(`/api/v1/admin/terminate-user/${userId}`)
        .then(() => {
          addToast({ type: "success", message: "User Terminated" });
          getData();
        })
        .catch((err) => {
          console.log(err);
          addToast({ type: "error", message: "Failed to Terminate User" });
        });
    },
    [addToast, getData]
  );

  const reinstateUser = useCallback(
    (userId) => {
      axios
        .put(`/api/v1/admin/reinstate-user/${userId}`)
        .then(() => {
          addToast({ type: "success", message: "User Reinstated" });
          getData();
        })
        .catch((err) => {
          console.log(err);
          addToast({ type: "error", message: "Failed to Reinstate User" });
        });
    },
    [addToast, getData]
  );

  return (
    <>
      {/* <PageNav title="Licensing" links={[{ label: "Staffing Licenses", href: "" }]} /> */}
      <div className="page">
        <div className="license-wrapper">
          <div className="access-licenses">
            <p>Staffing Analytics Licenses</p>
            <p>
              Licenses Used <span className="used-license-count">{used}</span> vs. Licenses Purchased{" "}
              <span className="purchased-license-count">{purchased}</span>
              {/* <button className="buy-more-license-button" onClick={() => setLicenseIsOpen(true)}>
                Buy More Licenses
              </button> */}
            </p>
          </div>
          <div className="license-header">
            <button className="save-licensing-button" onClick={handleSave} disabled={licensesBeingSaved}>
              Save Licensing
            </button>
            <button className="add-user-button" onClick={handleAdd}>
              Add User
            </button>
          </div>
          <h4 className="table-label">Active Data Owners</h4>
          <table className="license-table">
            <thead>
              <tr>
                {activeOwners.columns.map((column) => (
                  <th key={`active-th-${column.label}`} className={column.className}>
                    {column.label}
                  </th>
                ))}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {activeOwners.rows.map((row, rIdx) => {
                return (
                  <tr key={`active-row-${rIdx}`}>
                    {activeOwners.columns.map((column) => {
                      return (
                        <td key={`active-td-${rIdx}-${column.label}`} className={column.className}>
                          {column.type === "boolean" ? (
                            <input type="checkbox" checked={row[column.fieldName]} onChange={() => toggleActiveEnabled(rIdx)} />
                          ) : (
                            row[column.fieldName]
                          )}
                        </td>
                      );
                    })}
                    <td>
                      <button onClick={() => terminateUser(row.id)}>Terminate User</button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <h4 className="table-label">Inactive Data Owners</h4>
          <table className="license-table">
            <thead>
              <tr>
                {inactiveOwners.columns.map((column) => (
                  <th key={`inactive-th-${column.label}`} className={column.className}>
                    {column.label}
                  </th>
                ))}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {inactiveOwners.rows.map((row, rIdx) => {
                return (
                  <tr key={`inactive-row-${rIdx}`}>
                    {inactiveOwners.columns.map((column) => {
                      return (
                        <td key={`active-td-${rIdx}-${column.label}`} className={column.className}>
                          {column.type === "boolean" ? (
                            <input type="checkbox" checked={row[column.fieldName]} onChange={() => toggleInactiveEnabled(rIdx)} />
                          ) : (
                            row[column.fieldName]
                          )}
                        </td>
                      );
                    })}
                    <td>
                      <button onClick={() => reinstateUser(row.id)}>Reinstate User</button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <BuyLicenseModal isOpen={licenseIsOpen} onExit={() => setLicenseIsOpen(false)} />
      <CreateUserModal isOpen={createUserIsOpen} onExit={() => setCreateUserIsOpen(false)} getData={getData} />
    </>
  );
}

export default Licensing;
