import { useState } from "react";
import { Link } from "react-router-dom";
import CardSettings from "../CardSettings";
import "./style.scss";

function Card({ children, className = "", heading = "", subheading = "", link, width, height, position, numOfCards, id, dashboard }) {
  const linkComponent = link ? <Link className="card-button card-link" to={link} state={{ card: dashboard.cards.find(card => card.id === id), dashboard }}></Link> : null;
  const [settingsOpen, setSettingsOpen] = useState(false);

  return (
    <div className={`card ${className}`}>
      <div className="inner-wrapper">
        <h3 className="heading">
          <span>{heading}</span>
          {dashboard?.canEdit && (
            <CardSettings
              settingsOpen={settingsOpen}
              setSettingsOpen={setSettingsOpen}
              width={width}
              height={height}
              position={position}
              numOfCards={numOfCards}
              dashboardCardId={id}
              dashboard={dashboard}
            />
          )}
          {linkComponent}
        </h3>
        <h4 className="subheading">{subheading}</h4>
        {children}
      </div>
    </div>
  );
}

export default Card;
