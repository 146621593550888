import { Routes, Route, Navigate } from "react-router-dom";
import PageNav from "../../Components/PageNav";
import ByDate from "./SubPages/ByDate";
import ByEmployee from "./SubPages/ByEmployee";
import ByClient from "./SubPages/ByClient";
import "./style.scss";

function ActivityRatioSales() {
  return (
    <>
      <PageNav
        title="Activity Ratios (Sales)"
        links={[
          { label: "By Activity Date", href: "by-date" },
          { label: "By Employee", href: "by-employee" },
          { label: "By Client", href: "by-client" },
        ]}
      />
      <div className="page">
        <Routes>
          <Route path={`by-date`} element={<ByDate />} />
          <Route path={`by-employee`} element={<ByEmployee />} />
          <Route path={`by-client`} element={<ByClient />} />
          <Route index element={<Navigate to={`by-date`} />} />
        </Routes>
      </div>
    </>
  );
}

export default ActivityRatioSales;
