import EditTable from "../../../../Components/EditTable";
import useNewDataFetcher from "../../../../Hooks/newUseDataFetcher";

function EmployeeRevenueGoals() {
  const { data, dateRange } = useNewDataFetcher({ endPoint: "/api/v1/admin/goals/employee-revenue" });
  const { employeeRevenue, columns } = data || {};

  return <EditTable initialValues={employeeRevenue} columns={columns} endPoint="/api/v1/admin/goals/employee-revenue" dateRange={dateRange} byMonth />;
}

export default EmployeeRevenueGoals;
