import EditTable from "../../../../Components/EditTable";
import useNewDataFetcher from "../../../../Hooks/newUseDataFetcher";

function CompanyGoals() {
  const { data, dateRange } = useNewDataFetcher({ endPoint: "/api/v1/admin/goals/company-goals" });
  const { companyGoals, columns } = data || {};

  return <EditTable initialValues={companyGoals} columns={columns} endPoint="/api/v1/admin/goals/company-goals" dateRange={dateRange} byMonth />;
}

export default CompanyGoals;
