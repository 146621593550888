import { useState, useEffect, useCallback, useMemo } from "react";
import ColumnFilter from "../ColumnFilter";
import ColumnFilterConnector from "../ColumnFilterConnector";
import { filterFunctions, connectorFunctions } from "../../Utilities/columnFilterFunctions";
import "./style.scss";

function ColumnFilterList({ type, columnFilters, onFilterApply, needsFlip }) {
  const defaultFilter = useMemo(() => {
    return type === "string"
      ? { ...filterFunctions.in, key: "in" }
      : Object.values(filterFunctions).filter((filter) => filter.types.includes(type || "number"))[0];
  }, [type]);
  const [isOpen, setIsOpen] = useState(false);
  const [filters, setFilters] = useState(columnFilters && columnFilters.length ? columnFilters : [{ ...defaultFilter, compareValue: "" }]);

  const addFilter = useCallback(() => {
    setFilters((prevFilters) => [...prevFilters, { ...connectorFunctions.or, key: "or" }, { ...defaultFilter, compareValue: "" }]);
  }, [defaultFilter]);

  const deleteFilter = useCallback((index) => {
    setFilters((prevFilters) => {
      if (prevFilters.length === 1) return [{ ...prevFilters[0], compareValue: "" }];
      if (index === prevFilters.length - 1) index--;
      let newFilters = [...prevFilters];
      newFilters.splice(index, 2);
      return newFilters;
    });
  }, []);

  const onSymbolChange = useCallback((index, e) => {
    let filterObj = filterFunctions[e.target.value];
    if (!filterObj) filterObj = connectorFunctions[e.target.value];
    filterObj = { ...filterObj, key: e.target.value };
    setFilters((prevFilters) => {
      let newFilters = prevFilters.slice(0, index);
      newFilters.push({ ...prevFilters[index], ...filterObj });
      newFilters = newFilters.concat(prevFilters.slice(index + 1));
      return newFilters;
    });
  }, []);

  const onValueChange = useCallback((index, e) => {
    setFilters((prevFilters) => {
      let newFilters = prevFilters.slice(0, index);
      newFilters.push({ ...prevFilters[index], compareValue: e.target.value });
      newFilters = newFilters.concat(prevFilters.slice(index + 1));
      return newFilters;
    });
  }, []);

  useEffect(() => {
    let defaultFilter =
      type === "string"
        ? { ...filterFunctions.in, key: "in" }
        : Object.entries(filterFunctions)
            .map(([key, filter]) => ({ ...filter, key }))
            .filter((filter) => filter.types.includes(type || "number"))[0];
    setFilters(columnFilters || [{ ...defaultFilter, compareValue: "" }]);
  }, [isOpen, columnFilters, type]);

  if (type === "date") return null;

  return (
    <div className="column-filter-wrapper">
      <div className={`filter-symbol ${isOpen || columnFilters ? "active" : ""}`} onClick={() => setIsOpen((prev) => !prev)}></div>
      {(() => {
        if (!isOpen) return null;
        return (
          <div className={`column-filter-list ${needsFlip ? "flipped" : ""}`}>
            <div className="column-filter-list-header">
              <button className="column-filter-list-close" onClick={() => setIsOpen(false)}></button>
            </div>
            <div className="column-filter-list-body">
              {filters.map((filter, index) => {
                if (connectorFunctions[filter.key]) {
                  return <ColumnFilterConnector key={`column-connector-${index}`} filter={filter} onSymbolChange={(e) => onSymbolChange(index, e)} />;
                } else {
                  return (
                    <ColumnFilter
                      key={`column-filter-${index}`}
                      type={type}
                      filter={filter}
                      onSymbolChange={(e) => onSymbolChange(index, e)}
                      onValueChange={(e) => onValueChange(index, e)}
                      onDelete={() => deleteFilter(index)}
                    />
                  );
                }
              })}
              <button className="column-filter-list-add" onClick={addFilter}>
                Add
              </button>
              <button
                className="column-filter-list-apply"
                onClick={() => {
                  if (filters.length === 1 && filters[0].compareValue === "") {
                    onFilterApply();
                  } else {
                    onFilterApply(filters);
                  }
                }}
              >
                Apply
              </button>
            </div>
          </div>
        );
      })()}
    </div>
  );
}

export default ColumnFilterList;
