import "./style.scss";

function Chip({ value, onDelete, disabled }) {
  return (
    <div className={disabled ? "chip email-sending-chip" : "chip"}>
      {value}
      <div className="chip-delete" onClick={disabled ? null : onDelete}></div>
    </div>
  );
}

export default Chip;
