import { useState, useEffect, useMemo, useCallback, useContext } from "react";
import { useParams, Navigate, useLocation } from "react-router-dom";
import axios from "axios";
import Card from "../Card";
import GraphTable from "../GraphTable";
import TextCard from "../TextCard";
import Spinner from "../Pieces/Spinner";
import PageNav from "../PageNav";
import Modal from "../Modal";
import Timestamp from "../Timestamp";
import { EmailContext } from "../../Contexts/emailContext";
import { DashboardContext } from "../../Contexts/dashboardContext";
import { ToastContext } from "../../Contexts/toastContext";
// import { getDateRangeFromCurrent , getDateRangeFromParams } from "../DateRange";
import { getFiltersFromParams, evaluateFilters } from "../../Utilities/columnFilterFunctions";
import calculateDateRange from "../../Utilities/calculateDateRange";
import "./style.scss";

function GeneratedDashboard() {
  const location = useLocation();
  const { ref } = useContext(EmailContext);
  const { deleteDashboard, dashboards } = useContext(DashboardContext);
  const { addToast } = useContext(ToastContext);
  const { dashboardId } = useParams();
  const [data, setData] = useState([]);
  const [deleteIsOpen, setDeleteIsOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const dashboardTitle = dashboards.find((dashboard) => dashboard.id === parseInt(dashboardId))?.name || "";

  const dashboard = useMemo(() => {
    return dashboards.find((dash) => dash.id.toString() === dashboardId);
  }, [dashboards, dashboardId]);

  useEffect(() => {
    if (!dashboard) return;
    const CancelToken = axios.CancelToken;
    let cancel;

    function processData(data = [], params = {}) {
      let filters = getFiltersFromParams(params.columnFilters);

      let filtered = data.filter((row) => {
        for (let key in filters) {
          let shouldKeep = evaluateFilters(row[key], filters[key]);
          if (!shouldKeep) return false;
        }
        return true;
      });

      return filtered;
    }

    async function getCardData() {
      setData([]);
      let response = await axios.get(`/api/v1/dashboard/${dashboardId}`, {
        cancelToken: new CancelToken((c) => cancel = c)
      });

      let newData = response.data.map((response, idx) => {
        let dateRange = calculateDateRange(response.dateRange);
        let processed = processData(response.data, response.query ? response.query : response.params);
        let categories = response?.params?.categories;
        let dashboardCard = dashboard.cards.find((card) => card.id.toString() === response.id.toString());
        if (!dashboardCard) return null;
        if (categories && !Array.isArray(categories)) categories = [categories];
        let filteredColumns = response.table.columns.filter((column) => {
          if (dashboardCard.type !== "table" && (column.isMain || column.isId || column.isSub || column.axis === "r")) return true;
          if (!response?.params?.categories && !dashboardCard.columns) return true;
          //If the categories should be included in the legend keep them
          if (response?.params?.categories?.includes(column.fieldName)) return true;
          if (dashboardCard.columns && dashboardCard.columns.includes(column.fieldName)) return true;
          return false;
        });

        //The card setting overrides the original page data json
        let hideTotals = !(dashboard.cards[idx].showTotals ?? !response.table.hideTotals);

        return {
          ...response,
          table: { ...response.table, columns: filteredColumns, hideTotals },
          data: processed,
          categories,
          dateRange,
        };
      });
      newData = newData.filter(data => data);
      setData(newData);
    }

    getCardData();
    return cancel;
  }, [dashboard, dashboardId]);

  const handleConfirmationOpen = useCallback(() => {
    setDeleteIsOpen(true);
  }, []);

  const handleConfirmationClose = useCallback(() => {
    setDeleteIsOpen(false);
  }, []);

  const handleDelete = useCallback(() => {
    setIsDisabled(true);
    axios
      .delete(`/api/v1/dashboard/${dashboardId}`)
      .then((resp) => {
        deleteDashboard(dashboardId);
        addToast({ type: "success", message: "Dashboard Deleted" });
      })
      .catch((err) => {
        console.log(err);
        addToast({ type: "error", message: "Failed to delete dashboard" });
        setIsDisabled(false);
      });
  }, [deleteDashboard, dashboardId, addToast]);

  const cards = useMemo(() => {
    let dashboard = dashboards.find((dash) => dash.id.toString() === dashboardId);

    if (!dashboard) return [];

    let dataMap = data.reduce((acc, cur) => {
      acc[cur.id] = cur;
      return acc;
    }, {});

    return dashboard.cards.map((card, idx) => {
      let children;
      if (card.mainText || card.subText) {
        let cardData = [];
        let dateRange = {};
        if (dataMap[card.id]) {
          cardData = dataMap[card.id].data;
          dateRange = dataMap[card.id].dateRange;
        }
        children = <TextCard {...card} data={cardData} dateRange={dateRange} />;
      } else {
        let dataObj = {};
        let categories, dateRange;

        if (dataMap[card.id]) {
          dataObj = {
            table: {
              ...dataMap[card.id].table,
              columns: dataMap[card.id].table.columns.map((column) => ({ ...column, link: "" })),
              hidePagination: true,
              show: card.type === "table",
            },
            graphs: card.type === "graph" ? dataMap[card.id].graphs.map((graph) => ({ ...graph, legend: card.showLegend })) : [],
            data: dataMap[card.id].data,
          };
          categories = dataMap[card.id].categories;
          dateRange = dataMap[card.id].dateRange;
          if (card.params?.sort)
            dataObj.table.sortBy = { fieldName: card.params.sort.replace(/-/gm, (_) => ""), direction: card.params.sort.includes("-") ? "down" : "up" };
        }

        children = (
          <GraphTable
            data={dataObj}
            dateRange={dateRange}
            onDateChange={() => {}}
            categories={card.categories || categories}
            showLegend={card.showLegend}
            showTotals={card.showTotals}
            isReady={!!dataMap[card.id]}
            isCard
          />
        );
      }

      return (
        <Card
          key={`card-${card.name}-${card.positionIndex}`}
          className={`width-${card.width} height-${card.height}`}
          heading={card.name}
          subheading={dataMap[card.id] && dataMap[card.id].dateRange ? dataMap[card.id].dateRange.label : ""}
          link={card.pageUrl}
          width={card.width}
          height={card.height}
          position={card.positionIndex}
          numOfCards={dashboard.cards.length}
          id={card.id}
          dashboard={dashboard}
        >
          <Spinner isCard={true} isVisible={!dataMap[card.id]} />
          {children}
        </Card>
      );
    });
  }, [dashboards, dashboardId, data]);

  if (dashboards.length && !dashboard) {
    let from = location.state?.from?.pathname || "/";
    return <Navigate to={from} replace />;
  }

  return (
    <>
      <PageNav title={dashboardTitle}>
        {dashboard?.canEdit ? (
          <button className="delete-dashboard-btn" onClick={handleConfirmationOpen}>
            Delete Dashboard
          </button>
        ) : null}
      </PageNav>
      <div className="page">
        <div className="dashboard" ref={ref}>
          {!cards || !cards.length ? <h1 className="no-cards">This dashboard has no cards</h1> : cards}
        </div>
        <Timestamp />
      </div>
      <Modal className="delete-dashboard-confirmation" title="Delete Dashboard" type="danger" isOpen={deleteIsOpen} onExit={handleConfirmationClose}>
        <div className="delete-dashboard-wrapper">
          <p className="delete-dashboard-message">Are you sure you want to delete the {`'${dashboardTitle}'`} dashboard?</p>
          <div className="delete-dashboard-buttons">
            <button onClick={handleConfirmationClose}>Cancel</button>
            <button className="delete-dashboard-confirm" onClick={handleDelete} disabled={isDisabled}>Delete</button>
          </div>
        </div>
      </Modal>
      
    </>
  );
}

export default GeneratedDashboard;
