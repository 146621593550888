import axios from "axios";
import { useState, useEffect } from "react";
import FilterCard from "../FilterCard";
import "./style.scss";

function FilterSelector({ filterTypes, initialFilters, onFilterChange, onExit }) {
  const [filterTags, setFilterTags] = useState([]);
  const [filters, setFilters] = useState(initialFilters.length ? initialFilters : [{ filterType: "", inclusion: true, filter: "" }]);

  useEffect(() => {
    if (!initialFilters || !initialFilters.length) return;
    setFilters(initialFilters);
  }, [initialFilters]);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    let cancel;

    axios
      .get(`/api/v1/filters`, {
        cancelToken: new CancelToken((c) => (cancel = c)),
      })
      .then((resp) => {
        if (resp.data) {
          let reducedFilterTags = resp.data.reduce((acc, cur) => {
            acc[cur.id] = cur.tag_name;
            return acc;
          }, []);
          setFilterTags(reducedFilterTags);
        }
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });

    return () => cancel();
  }, []);

  useEffect(() => {
    if (!initialFilters || !initialFilters.length) return;
    setFilters(initialFilters);
  }, [initialFilters]);

  function handleTypeChange(filterType, idx) {
    setFilters((prevFilters) => {
      let before = prevFilters.slice(0, idx);
      let item = prevFilters[idx];
      let after = prevFilters.slice(idx + 1);
      return [...before, { ...item, filterType }, ...after];
    });
  }

  function handleTagChange(filter, idx) {
    setFilters((prevFilters) => {
      let before = prevFilters.slice(0, idx);
      let item = prevFilters[idx];
      let after = prevFilters.slice(idx + 1);
      return [...before, { ...item, filter }, ...after];
    });
  }

  function handleInclusionChange(inclusion, idx) {
    setFilters((prevFilters) => {
      let before = prevFilters.slice(0, idx);
      let item = prevFilters[idx];
      let after = prevFilters.slice(idx + 1);
      return [...before, { ...item, inclusion }, ...after];
    });
  }

  function addFilter() {
    setFilters((prevFilters) => {
      return [...prevFilters, { filterType: "", inclusion: true, filter: "" }];
    });
  }

  function onDelete(idx) {
    setFilters((prevFilters) => {
      let newFilters = prevFilters.slice(0, idx).concat(prevFilters.slice(idx + 1));
      if (newFilters.length < 1) newFilters = [{ filterType: "", inclusion: true, filter: "" }];
      return newFilters;
    });
  }

  return (
    <div className="filter-selector">
      <div className="header-section">
        <h1 className="selector-header">Filters</h1>
        <button className="selector-exit" onClick={onExit}></button>
      </div>
      <div className="filter-default-buttons">
        <button className="set-default-button" /* onClick={} */ disabled>
          Apply and Set as Default
        </button>
        <button className="reset-default-button" /* onClick={} */ disabled>
          Reset Default
        </button>
      </div>
      <div className="filter-section">
        {filters.map((item, idx) => {
          return (
            <FilterCard
              key={idx}
              filterId={idx}
              onDelete={onDelete}
              handleTypeChange={handleTypeChange}
              handleTagChange={handleTagChange}
              handleInclusionChange={handleInclusionChange}
              filterTypes={filterTypes}
              filters={filters}
              filterTags={filterTags}
            />
          );
        })}
        <button
          className="add-filter-button"
          onClick={() => {
            addFilter();
          }}
        >
          + Add Filter
        </button>
      </div>
      <div className="filter-selector-footer">
        <button className="configure-filters-button" /* Does Nothing */>Configure Filters</button>
        <button
          className="apply-filters-button"
          onClick={() => {
            onFilterChange(filters);
          }}
        >
          Apply Filters
        </button>
      </div>
    </div>
  );
}

export default FilterSelector;
