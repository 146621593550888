import { useState, useCallback } from "react";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import "./style.scss";

function AddPaymentMethod() {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);

  const confirmSetupIntent = useCallback(() => {
    if (!stripe || !elements) return;
    setIsProcessing(true);
    stripe
      .confirmSetup({
        elements,
        confirmParams: {
          return_url: "http://localhost:3000/admin/licensing/payment-info",
        },
      })
      .catch((err) => {
        console.log(err);
        setIsProcessing(false);
      });
  }, [stripe, elements]);

  return (
    <div className="payment-element">
      <PaymentElement />
      <button className="add-payment-card-button" onClick={confirmSetupIntent} disabled={isProcessing}>
        Add Card
      </button>
    </div>
  );
}

export default AddPaymentMethod;
