import { useMemo } from "react";
import useNewDataFetcher from "../../../../Hooks/newUseDataFetcher";
import GraphTable from "../../../../Components/GraphTable";
import SubheaderTimePeriod from "../../../../Components/SubheaderTimePeriod";

function ByEmployeeStage() {
  const dataURL = "/api/v1/joborder/open-jobs/by-employee-momentum"
  const { data, dateRange, filterTypes, filters, isReady } = useNewDataFetcher({
    endPoint: dataURL,
  });

  const employee = useMemo(() => {
    if (!data) return 0;
    let total = 0;
    data.data.forEach((row) => {
      total++;
    });
    return total;
  }, [data]);

  return (
    <GraphTable data={data} dateRange={dateRange} filterTypes={filterTypes} filters={filters} isReady={isReady} dataURL={dataURL}>
      <h1 className="page-heading">Sales / JO Momentum</h1>
      <h2 className="page-subheading">
        <span className="page-data">{employee}</span> employees with open job orders created <SubheaderTimePeriod data={dateRange} />
      </h2>
    </GraphTable>
  );
}

export default ByEmployeeStage;
